import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@shared/servicesCommon/utils.service';

@Pipe({
    name: 'myCurrency',
    // pure: true
})
export class MyCurrencyPipe implements PipeTransform {
    private utilsService: UtilsService = new UtilsService();

    transform(value: unknown, ...args: unknown[]): string {
        const dollars = value as number;

        const str = this.utilsService.formatAsCurrency(dollars);
        return str;
    }

}

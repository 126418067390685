import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, IonDatetime } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

import { DateService } from '@shared/servicesCommon/date.service';

@Component({
    'selector': 'app-calendarModal',
    'templateUrl': './calendarModal.page.html',
    'styleUrls': ['./calendarModal.page.scss'],
})
export class CalendarModalPage implements OnInit {
    @ViewChild('myDatetime') myDateTime: IonDatetime;
    private dateService: DateService = new DateService();
    public title: string;
    public viewTitle: string = '';
    public dateRangeStart;
    public dateRangeEnd;
    public dateRangeStartISO;
    public dateRangeEndISO;
    public selectedDate: string;
    public selectedDateISO: string;
    public todayISO: string;
    public presentation = 'date';

    constructor(public modalController: ModalController, private navParams: NavParams) {
    }

    ngOnInit(): void {
        // Get the values that were passed in.
        this.title = this.navParams.get('title');
        this.dateRangeStart = this.navParams.get('dateRangeStart');
        this.dateRangeStartISO = this.dateService.formatTimestamp(this.dateRangeStart, 'iso');
        
        this.dateRangeEnd = this.navParams.get('dateRangeEnd');
        this.dateRangeEndISO = this.dateService.formatTimestamp(this.dateRangeEnd, 'iso');
        
        this.selectedDate = this.navParams.get('selectedDate');
        this.selectedDateISO = this.dateService.formatTimestamp(this.selectedDate, 'iso');
        
        this.todayISO = this.dateService.formatTimestamp('now', 'iso');
    }
    
    ionViewWillEnter(): void {
    }

    // public next() {
    //     this.myCalendar.slideNext();
    //     this.myCalendar.update();
    // }

    // public back() {
    //     this.myCalendar.slidePrev();
    //     this.myCalendar.update();
    // }

    public getMaximumDate(): string {
        return this.dateRangeEndISO;
    }

    public getMinimumDateISO(): string {
        return this.dateRangeStartISO;
    }

    public getMaximumDateISO(): string {
        return this.dateRangeEndISO;
    }

    public getSelectedDateISO(): string {
        return this.selectedDateISO;
    }

    public onSignupDateClick() {
        this.selectedDateISO = this.dateRangeStartISO;
        this.myDateTime.reset(this.dateRangeStartISO);
    }

    public onTodayClick() {
        this.selectedDateISO = this.todayISO;
        this.myDateTime.reset(this.todayISO);
    }

    public onChange(event: any) {
        this.selectedDateISO = event.detail.value;
    }

    public onOkayClick() {
        const selectedTimestamp = this.dateService.parse(this.selectedDateISO, 'iso');
        const response = { 'okay': true, 'selectedDate': selectedTimestamp };
        this.modalController.dismiss(response);
    }

    public onCancelClick() {
        const response = { 'okay': false, 'selectedDate': null };
        this.modalController.dismiss(response);
    }
}

import { Injectable } from '@angular/core';

import { AccountProfileService } from '@shared/servicesCommon/accountProfile.service';
import { DealershipService } from '@shared/servicesCommon/dealership.service';
import { DateService } from '@shared/servicesCommon/date.service';
import { KeyValuePairService } from '@shared/servicesCommon/keyValuePair.service';
import { SessionService } from '@shared/servicesCommon/session.service';
import { SettingsService } from '@shared/servicesCommon/settings.service';
import { UtilsService } from '@shared/servicesCommon/utils.service';
import { TSetting, TCustomer, TCoordinate, TAccountProfile, TDealership, TTimestamp } from '@shared/models/index';
import { DateTimeFormats } from '@shared/models/index';

@Injectable({
  'providedIn': 'root'
})
export class VariableReplacementService {
    private settings: TSetting = null;
    private accountProfile: TAccountProfile = null;
    private dealership: TDealership = null;

    constructor(private sessionService: SessionService, private settingsService: SettingsService, 
                private utilsService: UtilsService, private accountProfileService: AccountProfileService,
                private keyValuePairService: KeyValuePairService, private dateService: DateService,
                private dealershipService: DealershipService) {
    }

    // accountId
    get accountId(): string {
        return this.accountProfile.accountId;
    }

    // accountNumber
    get accountNumber(): string {
        return this.accountProfile.accountNumber;
    }

    // campaignLink
    get campaignLink(): string {
        let link = '';

        if (this.hostingEnabled) {
            // queryParams = `an=${this.accountNumber}&cu=${this.customerUid}`;
            // const queryParams = `ai=${this.accountId}&ci=${this.customerId}`;
            // const link = this.settings?.hosting.campaignHostingLink;
            link  = this.hostingLink + this.queryParams;
        } else {
            link = this.hostingLink;
        }

        return link;
    }

    // countryCode
    get countryCode(): string {
        return this.dealership.address.countryCode;
    }

    // incentiveSetId
    get incentiveSetId(): number {
        return this.keyValuePairService.getValue( '[IncentiveSetId]' );
    }
    set incentiveSetId(value: number) {
        this.keyValuePairService.setValue( '[IncentiveSetId]', value );
    }

    // currentCoordinate
    get currentCoordinate(): TCoordinate {
        return this.keyValuePairService.getValue( '[CurrentCoordinate]' );
    }
    set currentCoordinate(value: TCoordinate) {
        this.keyValuePairService.setValue( '[CurrentCoordinate]', value );
    }

    // currentDate
    get currentDate(): string {
        const str = this.dateService.formatTimestamp('now', 'PPP');
        return str;
    }

    // CurrentTime
    get currentTime(): string {
        const str = this.dateService.formatTimestamp('now', 'h:mm a');
        return str;
    }

    // customer
    get customer(): TCustomer {
        return this.keyValuePairService.getValue( '[Customer]' );
    }
    set customer(value: TCustomer) {
        this.keyValuePairService.setValue( '[Customer]', value );
    }

    get customerCellPhoneWithCountryCode(): string {
        const customer = this.customer;
        const cellPhone = customer?.cellPhone;
        const cellPhoneWithCountryCode = this.countryCode + cellPhone;
        return cellPhoneWithCountryCode;
    }

    // customerId
    get customerId(): string {
        const customer = this.customer;
        return customer?.customerId;
    }

    // customerName
    get customerName(): string {
        const customer: TCustomer = this.keyValuePairService.getValue( '[Customer]');
        const customerName = customer?.customerName;
        return customerName;
    }
    set customerName(value: string) {
        this.keyValuePairService.setValue( '[CustomerName]', value );
    }

    // customerUid
    get customerUid(): string {
        const customer = this.customer;
        return customer?.uid;
    }

    // dealershipName
    get dealershipName(): any {
        return this.dealership.dealershipName;
    }

    // debugData
    get debugData(): any {
        return this.keyValuePairService.getValue( '[DebugData]' );
    }
    set debugData(value: any) {
        this.keyValuePairService.setValue( '[DebugData]', value );
    }

    // departureTime
    get departureTime(): string {
        const departureTime = this.numMinutesFromNow;
        return departureTime;
        // return this.keyValuePairService.getValue( '[DepartureTime]' );
    }

    // distance
    get distance(): number {
        return this.keyValuePairService.getValue( '[Distance]' );
    }
    set distance(value: number) {
        this.keyValuePairService.setValue( '[Distsance]', value );
    }

    // distanceFormatted
    get distanceFormatted(): string {
        const value = this.keyValuePairService.getValue( '[Distance]' );
        return value?.toString();
            // let distance: TDistance = null;
            // if (this.variables.currentCoordinate !== null && this.variables.toCoordinate !== null) {
            //     distance = this.utilsService.calculateDistance(this.variables.currentCoordinate, this.variables.toCoordinate);
            // }
    }

    // domain
    get domain(): any {
        return this.utilsService.getDomainInfo().domain;
    }

    // fakeCustomer
    get fakeCustomer(): TCustomer {
        return this.sessionService.getFakeCustomer();
    }

    get fakeDepartureTime(): string {
        return this.keyValuePairService.getValue( '[FakeDepartureTime]' );
    }
    set fakeDepartureTime(value: string) {
        this.keyValuePairService.setValue( '[FakeDepartureTime]', value );
    }

    // hostingEnabled
    get hostingEnabled(): boolean {
        const enabled = this.settings?.hosting.enabled;
        return enabled;
    }

    // hostingLink
    get hostingLink(): string {
        let link = '';
        
        if (this.hostingEnabled) {
            const domainInfo = this.utilsService.getDomainInfo();
            const path = 'showCampaign?';
            // link = 'https://app.ShuttleNomix.com/showCampaign?';
            link = domainInfo.properOrigin + '/' + path;
        } else {
            link = this.campaignLink;
        }
        // const link = (this.hostingEnabled) ? 'https://app.ShuttleNomix.com/showCampaign?' : this.campaignLink;
        return link;
    }
    set hostingLink(value: string) {
        if (value) {
            if (!value.endsWith('?')) {
                value += '?';
            }
        }
        this.keyValuePairService.setValue( '[HostingLink]', value );
    }

    // liveTrackingLink
    // http://localhost:8100/trackerLive?ai=0&sbb=true
    get liveTrackingLink(): string {
        const domainInfo = this.utilsService.getDomainInfo();
        const path = 'trackerLive?';
        const query = `ai=${this.accountId}&sbb=false`;     // sbb=show back button
        const link = domainInfo.properOrigin + '/' + path + query;

        return link;
    }
    set liveTrackingLink(value: string) {
        this.keyValuePairService.setValue( '[LiveTrackingLink]', value );
    }

    // numMilesToGo
    get numMilesToGo(): number {
        // const text1 = (this.variables.numMilesToGo === '1') ? ' Mile' : ' Miles';
        // const text2 = this.variables.numMilesToGo + text1 + ' To Go';
        // return text2;
        return this.keyValuePairService.getValue( '[NumMilesToGo]' );
    }
    set numMilesToGo(value: number) {
        this.keyValuePairService.setValue( '[NumMilesToGo]', value );
    }

    // numMinutes
    get numMinutes(): number {
        // const allAboardSetting = this.settings?.allAboard;
        // const numMinutes = allAboardSetting.numMinutes;
        const numMinutes = this.keyValuePairService.getValue( '[NumMinutes]' );
        return numMinutes;
    }
    set numMinutes(value: number) {
        this.keyValuePairService.setValue( '[NumMinutes]', value );
    }

    // numMinutesFromNow
    get numMinutesFromNow(): string {
        const now = this.dateService.now();
        const numMinutes =  Number(this.numMinutes);
        const numMinutesFromNow = this.dateService.add(now, numMinutes, 'minutes', false);
        const str = this.dateService.formatTimestamp(numMinutesFromNow, DateTimeFormats.ShortTime2);
        return str;
    }

    // numMiles
    get numMiles(): number {
        return this.keyValuePairService.getValue( '[NumMiles]' );
    }
    set numMiles(value: number) {
        this.keyValuePairService.setValue( '[NumMiles]', value );
    }

    // optoutLink
    get optoutLink(): string {
        const link = 'https://app.ShuttleNomix.com/campaignOptout?' + this.queryParams;
        return link;
    }

    get queryParams(): string {
        return `ai=${this.accountId}&ci=${this.customerId}&cpni=${this.incentiveSetId}`;
    }
   
    get shuttleDateFormatted(): string {
        const customer = this.keyValuePairService.getValue( '[Customer]');
        if (!customer) {
            return '';
        }
        if (!customer.shuttleDates || customer.shuttleDates.length === 0) {
            return '';
        }
        const shuttleDates   = customer?.shuttleDates as TTimestamp[];
        const sortedShuttleDates = this.sortShuttleDates(shuttleDates);
        // const sortedShuttleDates = shuttleDates;

        const mostRecentShuttleDate = (sortedShuttleDates) ? sortedShuttleDates[0] : this.dateService.now();
        const str = this.dateService.formatTimestamp(mostRecentShuttleDate, DateTimeFormats.BigDate);
        return str;
    }

    private sortShuttleDates(shuttleDates: TTimestamp[]): TTimestamp[] {
        const sortedShuttleDates = shuttleDates.sort((x: TTimestamp, y: TTimestamp) => {
            const a = this.dateService.toDate(x);   // x.toDate();
            const b = this.dateService.toDate(y);   // y.toDate();
            return (a.valueOf() - b.valueOf());
        });
        return sortedShuttleDates;
    }
    // toCoordinate
    get toCoordinate(): TCoordinate {
        return this.keyValuePairService.getValue( '[ToCoordinate]' );
    }
    set toCoordinate(value: TCoordinate) {
        this.keyValuePairService.setValue( '[ToCoordinate]', value );
    }

    public async reset(accountId?: string): Promise<void> {
        try {
            // if (accountId) {
            //     this.accountId = accountId;
            // }
            this.setAllValuesToNull();
            const { accountProfile, dealership, settings } = await this.getObjects(accountId);
            this.accountProfile = accountProfile;
            this.dealership = dealership;
            this.settings = settings;
        } catch (err) {
            throw err;
        }

        // await this.loadDefaultValues();
    }
    
    private setAllValuesToNull() {
        // Not providing a value in setValue defaults to ''
        this.keyValuePairService.setValue( '[AccountId]'             );
        this.keyValuePairService.setValue( '[AccountNumber]'         );
        this.keyValuePairService.setValue( '[CampaignLink]'          );
        this.keyValuePairService.setValue( '[CountryCode]'           );
        this.keyValuePairService.setValue( '[CurrentCoordinate]'     );
        this.keyValuePairService.setValue( '[CurrentDate]'           );
        this.keyValuePairService.setValue( '[CurrentTime]'           );
        this.keyValuePairService.setValue( '[Customer]'              );
        this.keyValuePairService.setValue( '[CustomerName]'          );
        this.keyValuePairService.setValue( '[DealershipName]'        );
        this.keyValuePairService.setValue( '[DebugData]'             );
        this.keyValuePairService.setValue( '[DepartureTime]'         );
        this.keyValuePairService.setValue( '[Domain]'                );
        this.keyValuePairService.setValue( '[FakeCustomer]'          );
        this.keyValuePairService.setValue( '[FakeDepartureTime]'     );
        this.keyValuePairService.setValue( '[HostingLink]'           );
        this.keyValuePairService.setValue( '[LiveTrackingLink]'      );
        this.keyValuePairService.setValue( '[NumMilesToGo]'          );
        this.keyValuePairService.setValue( '[NumMinutes]'            );
        this.keyValuePairService.setValue( '[NumMiles]'              );
        this.keyValuePairService.setValue( '[OptOutLink]'            );
        this.keyValuePairService.setValue( '[ShuttleDate]'           );
        this.keyValuePairService.setValue( '[ShuttleDateFormatted]'  );
        this.keyValuePairService.setValue( '[ToCoordinate]'          );
    }

    // private async loadDefaultValues() {
    //     try {
    //         const { accountProfile, settings } = await this.getObjects();
    //         this.accountProfile = accountProfile;
    //         this.settings = settings;

    //         // const settings: TSetting = await this.settingsService.get();
    //         // this.hostingEnabled = settings?.hosting.enabled;

    //         // const campaignLink = settings?.hosting.campaignHostingLink;
    //         // this.hostingLink = (this.hostingEnabled) ? 'https://app.ShuttleNomix.com/showCampaign?' : this.campaignLink;
            
    //         // const profile        = this.sessionService.getAccountProfile();
    //         // this.accountNumber   = accountProfile.accountNumber;
    //         // this.accountId       = accountProfile.accountId;
    //         // this.countryCode     = accountProfile.countryCode;
    //         // this.dealershipName  = accountProfile.dealershipName;        

    //         // this.domain          = this.utilsService.getDomainInfo().domain;
    //         // this.fakeCustomer    = this.sessionService.getFakeCustomer();
            
    //         // this.dealershipName  = this.sessionService.getDealershipName();        
    //         // this.domain          = this.sessionService.getDomain();
    //     } catch (err) {
    //         throw err;
    //     }
    // }

    private async getObjects(accountId?: string): Promise<any> {
        let isLoggedIn: boolean = this.sessionService?.getIsLoggedIn();
        let accountProfile: TAccountProfile = null;
        let dealership: TDealership = null;
        let settings: TSetting = null;

        if (isLoggedIn) {
            accountProfile = this.sessionService.getAccountProfile(); 
            dealership = this.sessionService.getDealership(); 
            settings = this.sessionService.getSetting();
        } else {
            // We are not logged in.  This was called via showCampaign.  So we need to get our objects
            // via the passed in query parameter accountId.
            accountProfile = await this.accountProfileService.getAccountProfileByAccountId(accountId);
            dealership = await this.dealershipService.getDealershipByAccountNumber(accountProfile.accountNumber);
            const accountNumber = accountProfile.accountNumber;
            settings = await this.settingsService.getByAccountNumber(accountNumber);
        }
        return {accountProfile, dealership, settings };
    }

    // private createCustomerLinks() {
    //     try {
    //         // let queryParams = '';
    //         // if (this.hostingEnabled) {
    //         //     // queryParams = `an=${this.accountNumber}&cu=${this.customerUid}`;
    //         //     queryParams = `ai=${this.accountId}&ci=${this.customerId}`;
    //         //     this.campaignLink  = this.hostingLink + queryParams;
    //         // } else {
    //         //     this.campaignLink  = this.hostingLink;
    //         // }

    //         // this.optoutLink = 'https://app.ShuttleNomix.com/campaignOptout?' + queryParams;
    //     } catch (err) {
    //         throw err;
    //     }
    // }

    public doVariableReplacements(msg: string): string {
        try {
            // this.createCustomerLinks();

            // Only replace the variable with a value if we have a good value.
            msg = this.utilsService.replaceAll(msg, '[DealershipName]', this.dealershipName);
            msg = this.utilsService.replaceAll(msg, '[CampaignLink]', this.campaignLink);
            msg = this.utilsService.replaceAll(msg, '[CustomerName]', this.customerName);
            msg = this.utilsService.replaceAll(msg, '[CurrentDate]', this.currentDate);
            msg = this.utilsService.replaceAll(msg, '[CurrentTime]', this.currentTime);
            msg = this.utilsService.replaceAll(msg, '[CountryCode]', this.countryCode);
            msg = this.utilsService.replaceAll(msg, '[Distance]', this.distanceFormatted);
            msg = this.utilsService.replaceAll(msg, '[DepartureTime]', this.departureTime.toString());
            msg = this.utilsService.replaceAll(msg, '[Domain]', this.domain);
            msg = this.utilsService.replaceAll(msg, '[Newline]', '\n');
            msg = this.utilsService.replaceAll(msg, '[NumMiles]', this.numMiles.toString());
            msg = this.utilsService.replaceAll(msg, '[NumMilesToGo]', this.numMilesToGo.toString());
            msg = this.utilsService.replaceAll(msg, '[NumMinutes]', this.numMinutes.toString());
            msg = this.utilsService.replaceAll(msg, '[NumMinutesFromNow]', this.numMinutesFromNow);
            msg = this.utilsService.replaceAll(msg, '[OptoutLink]', this.optoutLink);
            msg = this.utilsService.replaceAll(msg, '[ShuttleDate]', this.shuttleDateFormatted);
            msg = this.utilsService.replaceAll(msg, '[LiveTrackingLink]', this.liveTrackingLink);
                        
            // If we still have missing variables supply fake values
            msg = this.utilsService.replaceAll(msg, '[CustomerName]', this.fakeCustomer.customerName);
            msg = this.utilsService.replaceAll(msg, '[NumMiles]', '3');
            // msg = this.utilsService.replaceAll(msg, '[DepartureTime]', this.fakeDepartureTime);

            if (this.debugData) {
                msg += '\n[DebugData]=' + JSON.stringify(this.debugData);
            }
            
            return msg;
        } catch (err) {
            throw err;
        }
    }
}

import { TStateInfo, TCoordinate, TDistinctColorInfo, TRoleInfo } from './interfaces';

export let Constants = {
    // APPLICATION_VERSION must match the version in client/docs/ReleaseNotes.json
    // !!! WHEN UPDATING THE APPLICATION_VERSION ALSO UPDATE THE APPLICATION_RELEASE_DATE 
    // !!! APPLICATION_RELEASE_DATE must match the release date in pages/releaseNotes/ReleaseNotesContents.html
    // !!! Also update package.json version (on line 3)
    APPLICATION_VERSION:                       '0.98.17',
    APPLICATION_RELEASE_DATE:                  'August 05, 2023',
    
    APPLICATION_IONIC_APP_ID:                  '1feab53f',
    BACKEND_IONIC_APP_ID:                      '1b15a27c',
    BACKEND_VERSION:                           '1.4.1',
    COMPANY_PHONE_NUMBER:                      '(661) 877-8631',
    COMPANY_HOURS:                             '9:00am to 5:00pm Monday - Friday PST',
    COMPANY_WEBSITE:                           'https://www.ShuttleNomix.com',
    COMPANY_SUPPORT_EMAIL_ADDRESS:             'support@ShuttleNomix.com',
    COMPANY_HELPDESK:                          'http://helpdesk.ShuttleNomix.com',
    COMPANY_YOUTUBE_PLAYLIST_URL:              'https://www.youtube.com/@shuttlenomix1951/playlists',
    DOCUMENT_LOGO:                             'Logo',
    DOCUMENT_FAQ:                              'Faq',
    DOCUMENT_PRIVACY_POLICY:                   'privacyPolicy.html',
    DOCUMENT_RELEASE_NOTES:                    'releaseNotes.json',
    DOCUMENT_TERMS_OF_SERVICE:                 'termsOfService.html',
    DOCUMENT_ROADMAP:                          'roadmap.json',
    DOCUMENT_INTRODUCTION:                     'introduction.html',
    FILENAME_DUMMY_DATA:                       '../assets/data/dummyData.json',
    FILENAME_TUTORIAL_DATA:                    '../assets/data/tutorialData.json',
    ERROR_AUTHORIZATION:                       'The function must be called while authenticated.',
    EVENT_ALL_ABOARD:                          'AllAboard',
    EVENT_APPROACHING_DROPOFF:                 'ApproachingDropoff',
    EVENT_APPROACHING_PICKUP:                  'ApproachingPickup',
    EVENT_ARCHIVE_SESSION_MESSAGES:            'ArchiveSessionMessages',
    EVENT_CAMPAIGN_MESSAGE:                    'CampaignMessage',
    EVENT_DEBUG_MESSAGE:                       'DebugMessage',
    EVENT_DROPOFF_COMPLETED:                   'DropoffCompleted',
    EVENT_INTERNET_CONNECTION_CHANGE:          'InternetConnectionChangeEvent',
    EVENT_LOGIN:                               'LoginEvent',
    EVENT_LOGOUT:                              'LogoutEvent',
    EVENT_LOGGED_IN_USER_CHANGED:              'LoggedInUserChangedEvent',
    // EVENT_NAV_MORE_MENU_CHANGE:                'NavMoreMenuChange',
    EVENT_PAGE_LOADED:                         'PageLoadedEvent',
    EVENT_PICKUP_ARRIVED:                      'PickupShuttleArrived',
    EVENT_PICKUP_COMPLETED:                    'PickupCompleted',
    EVENT_TICKET_CREATED:                      'TicketCreated',

    EVENT_TRACK_USAGE:                         'TrackUsage',
    // ASSIGNED_TICKET_IMAGE:                     'img/AssignedTicket.jpg',
    // COMPLETED_TICKET_IMAGE:                    'img/CompletedTicket.jpg',
    // CURRENT_TICKET_IMAGE:                      'img/CurrentTicket.jpg',
    // ENROUTE_TICKET_IMAGE:                      'img/EnrouteTicket.jpg',
    // PENDING_TICKET_IMAGE:                      'img/PendingTicket.jpg',
    IF_SAVING:                                 'Saving Data.',     // IF = IonicFactory
    IF_LOADING:                                'Loading.',
    IF_FETCHING:                               'Fetching Data.',
    IF_DELETING:                               'Deleting Data.',
    IF_BUSY:                                   'Busy.',
    IF_LOGIN:                                  'Login.',
    LOGROCKET_APPID_LOCALHOST:                 '5b5j6a/localhost-rljtv',
    LOGROCKET_APPID_APP:                       '5b5j6a/app-ef407',
    LOGROCKET_APPID_STAGING:                   '5b5j6a/staging-3xzvg',
    LOGROCKET_APPID_BACKEND:                   '5b5j6a/backend-vayce',
    MAINTENANCE_HOURS:                         '11:00pm to 3:00am PST.',
    // PIRATES_CREATE_SIGNUP_EVENT:               'piratesCreateSignupEvent',
    // PIRATES_CREATE_EVENT_LOGIN:                'piratesLoginEvent',
    // PIRATES_CREATE_EDUCATION_EVENT:            'piratesEducationEvent',
    // PIRATES_CREATE_ACCOUNT_EVENT:              'piratesCreateAccountEvent',
    // PIRATES_CREATE_ACCOUNT_PROFILE_EVENT:      'piratesAccountProfileEvent',
    // PIRATES_CREATE_USER_EVENT:                 'piratesUserEvent',
    // PIRATES_CREATE_SHUTTLE_EVENT:              'piratesShuttleEvent',
    // PIRATES_CREATE_SERVICE_AREA_EVENT:         'piratesServiceAreaEvent',
    // PIRATES_CREATE_SETTINGS_EVENT:             'piratesSettingsEvent',
    // PIRATES_CREATE_TICKET_CREATED_EVENT:       'piratesTicketCreatedEvent',
    // PIRATES_CREATE_TRIP_COMPLETED_EVENT:       'piratesTripCompletedEvent',
    // PIRATES_CREATE_NAVIGATION_EVENT:           'piratesNavigationEvent',
    // PIRATES_CREATE_TICKET_COMPLETED_EVENT:     'piratesTicketCompletedEvent',
    // PIRATES_CREATE_EXPIRATION_EXTENSION_EVENT: 'piratesExpirationExtensionEvent',
    // PIRATES_CREATE_WAYPOINT_EVENT:             'piratesWaypointEvent',
    // PIRATES_CREATE_ZIPCODE_EVENT:              'piratesZipcodeEvent',

    // super.setCurrentView(this.Constants.VIEW_);
    // In order for the sidemenu active menu item to work, these must match the menu items casing.
    VIEW_ABOUT:                                'about',
    VIEW_ACCESS_DENIED:                        'accessDenied',
    VIEW_ACCOUNT:                              'account',
    VIEW_ACCOUNT_PROFILE:                      'accountProfile',
    VIEW_CAMPAIGNS:                            'campaigns',
    VIEW_CAMPAIGN:                             'campaign',
    VIEW_CAMPAIGN_FORMAT_SELECTOR:             'campaignFormatSelector',
    VIEW_CONTACT_US:                           'contactUs',
    VIEW_CUSTOMERS:                            'customers',
    VIEW_DASHBOARD:                            'dashboard',
    VIEW_DEALERSHIP:                           'dealership',
    VIEW_FAQ:                                  'faq',
    VIEW_FEEDBACK:                             'feedback',
    VIEW_HELP:                                 'help',
    VIEW_INTRODUCTION:                         'introduction',
    VIEW_LOGIN:                                'login',
    VIEW_LOGOUT:                               'logout',
    VIEW_MARKETING:                            'marketing',
    VIEW_MARKETING_CAMPAIGN_MESSAGE:           'marketingCampaignMessage',
    VIEW_NAVIGATION:                           'navigation',
    VIEW_NAVIGATION_PAGE1:                     'navigationPage1',
    VIEW_NAVIGATION_PAGE2:                     'navigationPage2',
    VIEW_NAVIGATION_PAGE3:                     'navigationPage3',
    VIEW_NAVIGATION_PAGE4:                     'navigationPage4',
    VIEW_NAVIGATION_PAGE5:                     'navigationPage5',
    VIEW_ORDER:                                'order',
    VIEW_ORDERS:                               'orders',
    VIEW_PAYMENT:                              'payment',
    VIEW_POST:                                 'post',
    VIEW_PRIVACY_POLICY:                       'privacyPolicy',
    VIEW_RELEASE_NOTES:                        'releaseNotes',
    VIEW_REPORTS:                              'reports',
    VIEW_REPORTS_PAGE1:                        'reportsPage1',
    VIEW_REPORTS_PAGE2:                        'reportsPage2',
    VIEW_REPORTS_PAGE3:                        'reportsPage3',
    VIEW_ROADMAP:                              'roadmap',
    VIEW_ROUTES:                               'routes',
    VIEW_ROUTE_PAGE1:                          'routePage1',
    VIEW_ROUTE_PAGE2:                          'routePage2',
    VIEW_ROUTE_PAGE3:                          'routePage3',
    VIEW_ROUTE_PAGE4:                          'routePage4',
    VIEW_ROUTE_PAGE5:                          'routePage5',
    VIEW_ROUTE_PAGE6:                          'routePage6',
    VIEW_ROUTE_PAGE7:                          'routePage7',
    VIEW_ROUTE_PAGE8:                          'routePage8',
    VIEW_ROUTE_VIEW:                           'routeView',
    VIEW_SATISFACTION_PAGE1:                   'satisfactionPage1',
    VIEW_SATISFACTION_PAGE2:                   'satisfactionPage2',
    VIEW_SERVICE_AREAS:                        'serviceAreas',
    VIEW_SERVICE_AREAS_SINGLE:                 'serviceAreasSingle',
    VIEW_SERVICE_AREAS_MULTIPLE:               'serviceAreasMultiple',
    VIEW_SETTINGS:                             'settings',
    VIEW_ALL_ABOARD:                           'allAboard',
    VIEW_SETTINGS_APPROACHING_DROPOFF:         'settingsApproachingDropoff',
    VIEW_SETTINGS_APPROACHING_PICKUP:          'settingsApproachingPickup',
    VIEW_SETTINGS_CACHE_LOGIN_CREDS:           'settingsCacheLoginCreds',
    VIEW_SETTINGS_CAMPAIGN_INCENTIVE:          'settingsCampaignIncentive',
    VIEW_SETTINGS_CAMPAIGN_INCENTIVES:         'settingsCampaignIncentives',
    VIEW_SETTINGS_CAMPAIGN_HOSTING:            'settingsCampaignHosting',
    VIEW_CAMPAIGN_INCENTIVE_SET:               'campaignIncentiveSet',    
    VIEW_CAMPAIGN_INCENTIVE_SETS:              'campaignIncentiveSets',
    VIEW_SETTINGS_CAMPAIGN_TEST_CUSTOMER:      'settingsCampaignTestCustomer',
    VIEW_SETTINGS_DROPOFF_COMPLETED:           'settingsDropoffCompleted',
    VIEW_SETTINGS_MISC:                        'settingsMisc',
    VIEW_SETTINGS_PICKUP_ARRIVED:              'settingsPickupArrived',
    VIEW_SETTINGS_PICKUP_COMPLETED:            'settingsPickupCompleted',
    VIEW_SHUTTLES:                             'shuttles',
    VIEW_SHUTTLE:                              'shuttle',
    VIEW_SHOW_CAMPAIGN:                        'showCampaign',
    VIEW_TERMS_OF_SERVICE:                     'termsOfService',
    VIEW_TICKET_CREATED:                       'ticketCreated',
    VIEW_TICKETS:                              'tickets',
    VIEW_TICKETS_PAGE1:                        'ticketsPage1',
    VIEW_TICKETS_PAGE2:                        'ticketsPage2',
    VIEW_TICKETS_PAGE3:                        'ticketsPage3',
    VIEW_TRACKER:                              'tracker',
    VIEW_TRACKER_HISTORICAL:                   'trackerHistorical',
    VIEW_TRACKER_LIVE:                         'trackerLive',
    VIEW_TUTORIALS:                            'tutorials',
    VIEW_USERS:                                'users',
    VIEW_USER:                                 'user',
    VIEW_USER_SETTINGS:                        'userSettings',
    VIEW_VERSION:                              'version',
    VIEW_WELCOME:                              'welcome',

    MARKETING_CAMPAIGN_MSG:                      'Hello [CustomerName],' + '\n' + '\n' +
                                                'You are receiving this text because on [ShuttleDate] you were at [DealershipName] ' +
                                                'and used our courtesy shuttle.  As a gift, here is a link to our latest set of incentives.  ' +
                                                'Please do not distribute this link to others.  These discounts are only intended for our ' +
                                                'most loyal customers.' + '\n' +
                                                '[CampaignLink].',
                                                // 'If you no longer wish to receive these discounts, please click this link.' + '\n' +
                                                // '[OptoutLink]' + '\n' + '\n' +
                                                // 'Thank you for your business and be well,' + '\n' +
                                                // '[DealershipName]',

    SETTINGS_DROPOFF_APPROACHING_NUMMILESTOGO:  2,
    SETTINGS_DROPOFF_APPROACHING_MSG:           'Hello [CustomerName],' + '\n' + '\n' +
                                                'We are [NumMilesToGo] miles from your destination. When you leave the shuttle, ' +
                                                'please take all your belongings with you. Thank you again for choosing ' +
                                                '[DealershipName].',

    SETTINGS_PICKUP_APPROACHING_MSG:            'Hello [CustomerName],' + '\n' + '\n' +
                                                'Your [DealershipName] courtesy shuttle is on its way to pick you up.  ' +
                                                'It is now [CurrentTime] and we are [NumMiles] miles from you.  We will see ' +
                                                'you shortly.',

    SETTINGS_DROPOFF_ALLABOARD_NUMMINUTES:      5,
    SETTINGS_DROPOFF_ALLABOARD_MSG:             'Hello [CustomerName],' + '\n' + '\n' +
                                                'It is now [CurrentTime].  Your [DealershipName] courtesy shuttle is leaving ' +
                                                'in [NumMinutes] minutes from now at [DepartureTime].  Please come to the shuttle station.',

    SETTINGS_PICKUP_ARRIVED_MSG:                'Hello [CustomerName],' + '\n' + '\n' +
                                                'Your [DealershipName] courtesy shuttle has arrived.',

    SETTINGS_PICKUP_COMPLETED_MSG:              'Hello [CustomerName],' + '\n' + '\n' +
                                                'We hope you enjoyed your ride. If there is anything you feel we could improve, ' +
                                                'we would appreciate your feedback.  Thank you for choosing [DealershipName].',

    SETTINGS_DROPOFF_COMPLETED_MSG:             'Hello [CustomerName],' + '\n' + '\n' +
                                                'We hope you enjoyed your ride. If there is anything you feel we could improve, ' +
                                                'we would appreciate your feedback.  Thank you for choosing [DealershipName].',

    SETTINGS_TICKET_CREATED_MSG:                'Hello [CustomerName],' + '\n' + '\n' +
                                                'Welcome to our new shuttle communication system.  ' +
                                                'You will receive a message similar to this one, letting you ' +
                                                'know of any status change to your shuttle.  Click this link ' +
                                                'to see in real time where your shuttles are:' + '\n' + '\n' +
                                                '[LiveTrackingLink]',

                                               // SETTINGS_CAMPAIGN_MSG_PART1:               'This is a two part message. Part 1 of 2.' + '\n' + '\n' +
    //                                            'Hello [CustomerName],' + '\n' +
    //                                            'You are receiving this text because on [ShuttleDate] you were at [DealershipName] ' +
    //                                            'and used our courtesy shuttle.  As a gift for being a loyal customer, here is a ' +
    //                                            'link to our latest set of incentives.',

    // SETTINGS_CAMPAIGN_MSG_PART2:               'Part 2 of 2.' + '\n' +
    //                                            '[CampaignLink]' + '\n' +
    //                                            'If you no longer wish to receive these discounts, please click this link.' +
    //                                            '[OptoutLink]' + '\n' +
    //                                            'Thank you for your business and be well,' + '\n' +
    //                                            '[DealershipName]',

    FEATURE_UPGRADE_TO_MANAGER_PACKAGE:         'Both the Tracker feature the Reports feature are currently disabled; ' +
                                                'however, these features are available in the Manager package.  ' +
                                                'Please contact support to upgrade.',

    FEATURE_UPGRADE_TO_CUSTOMER_PACKAGE:        'The Campaign feature and the ability to send SMS messages are ' +
                                                'currently disabled; however, they are available in the Customer ' +
                                                'package.  Please contact support to upgrade.',

    ACCESS_DENIED_CANCELLED_BY_SUPPORT:         'Support cancelled your account. <br /><br /> ',

    ACCESS_DENIED_CANCELLED_BY_USER:            'You cancelled you account. <br /><br /> ' +
                                                'If you have changed your mind and would like to create a new ' +
                                                'account, then please contact support.',

    ACCESS_DENIED_NSF:                          'Your last payment was not successful. <br /><br /> ' +
                                                'Please go to https://ShuttleNomix.com, login and make a payment ' +
                                                'to reactivate your account.',

    ACCESS_DENIED_PACKAGE_EXPIRED:              'Your package expired.  Please go to https://ShuttleNomix.com, ' +
                                                'login and make a payment to reactivate your account.',

    ACCESS_DENIED_TRIAL_PERIOD_EXPIRED:         'Your trial period has expired. <br /><br />' +
                                                'In order to continue to evaluate ShuttleNomix and determine ' +
                                                'if it is right for your dealership, choose one from the ' +
                                                'following three options: <br />' +
                                                '<ol>' +
                                                '    <li>Call ShuttleNomix and ask for a FREE 2 Day Trial Period Extension.</li>' +
                                                '    <li>Go to the <a href="https://shuttlenomix.com" target="_blank">ShuttleNomix website</a> then login and purchase the package you need.</li>' +
                                                '    <li>Do nothing.  We are sorry to see you go but we know you have your reasons.' +
                                                '    If you don\'t mind, please call us and let us know what those reasons are.' +
                                                '    This way we can improve our product and service.  Thank you and be well.</li>' +
                                                '</ol>',
                           
    // Travelling at 69 miles per hour you would cover 1 degree of latitude.  Therefore in 1 second
    // you would travel 1/3600th of that.  These constants are used in the tracker simulation mode.
    LATITUDE_DELTA_PER_SEC:                    0.00027778,  // Travelling north/south at 60mph, you would move this far in 1 second.
    LONGITUDE_DELTA_PER_SEC:                   0.00027778,	// Travelling east/west at 60mph, you would move this far in 1 second.

    NULL_COORDINATE: <TCoordinate> {
        'latitude': 0,
        'longitude': 0
    },
    // DOCUMENTS: <TDocument[]> [
    //     { 'displayFilename': 'FAQ',                 'ext': 'json',  'viewName': Constants.VIEW_FAQ                 },
    //     { 'displayFilename': 'Introduction',        'ext': 'html',  'viewName': this.VIEW_INTRODUCTION        },
    //     { 'displayFilename': 'Privacy Policy',      'ext': 'html',  'viewName': this.VIEW_PRIVACY_POLICY      },
    //     { 'displayFilename': 'Release Notes',       'ext': 'json',  'viewName': this.VIEW_RELEASE_NOTES       },
    //     { 'displayFilename': 'Roadmap',             'ext': 'json',  'viewName': this.VIEW_ROADMAP             },
    //     { 'displayFilename': 'Terms Of Service',    'ext': 'html',  'viewName': this.VIEW_TERMS_OF_SERVICE    },
    //     { 'displayFilename': 'Settings All Aboard', 'ext': 'html',  'viewName': this.VIEW_SETTINGS_ALL_ABOARD },

    // ],
    US_STATES: <TStateInfo[]> [
        {'state': 'Alabama',         'statecode':  'AL'},
        {'state': 'Alaska',          'statecode':  'AK'},
        {'state': 'Arizona',         'statecode':  'AZ'},
        {'state': 'Arkansas',        'statecode':  'AR'},
        {'state': 'California',      'statecode':  'CA'},
        {'state': 'Colorado',        'statecode':  'CO'},
        {'state': 'Connecticut',     'statecode':  'CT'},
        {'state': 'Delaware',        'statecode':  'DE'},
        {'state': 'Florida',         'statecode':  'FL'},
        {'state': 'Georgia',         'statecode':  'GA'},
        {'state': 'Hawaii',          'statecode':  'HI'},
        {'state': 'Idaho',           'statecode':  'ID'},
        {'state': 'Illinois',        'statecode':  'IL'},
        {'state': 'Indiana',         'statecode':  'IN'},
        {'state': 'Iowa',            'statecode':  'IA'},
        {'state': 'Kansas',          'statecode':  'KS'},
        {'state': 'Kentucky',        'statecode':  'KY'},
        {'state': 'Louisiana',       'statecode':  'LA'},
        {'state': 'Maine',           'statecode':  'ME'},
        {'state': 'Maryland',        'statecode':  'MD'},
        {'state': 'Massachusetts',   'statecode':  'MA'},
        {'state': 'Michigan',        'statecode':  'MI'},
        {'state': 'Minnesota',       'statecode':  'MN'},
        {'state': 'Mississippi',     'statecode':  'MS'},
        {'state': 'Missouri',        'statecode':  'MO'},
        {'state': 'Montana',         'statecode':  'MT'},
        {'state': 'Nebraska',        'statecode':  'NE'},
        {'state': 'Nevada',          'statecode':  'NV'},
        {'state': 'New Hampshire',   'statecode':  'NH'},
        {'state': 'New Jersey',      'statecode':  'NJ'},
        {'state': 'New Mexico',      'statecode':  'NM'},
        {'state': 'New York',        'statecode':  'NY'},
        {'state': 'North Carolina',  'statecode':  'NC'},
        {'state': 'North Dakota',    'statecode':  'ND'},
        {'state': 'Ohio',            'statecode':  'OH'},
        {'state': 'Oklahoma',        'statecode':  'OK'},
        {'state': 'Oregon',          'statecode':  'OR'},
        {'state': 'Pennsylvania',    'statecode':  'PA'},
        {'state': 'Rhode Island',    'statecode':  'RI'},
        {'state': 'South Carolina',  'statecode':  'SC'},
        {'state': 'South Dakota',    'statecode':  'SD'},
        {'state': 'Tennessee',       'statecode':  'TN'},
        {'state': 'Texas',           'statecode':  'TX'},
        {'state': 'Utah',            'statecode':  'UT'},
        {'state': 'Vermont',         'statecode':  'VT'},
        {'state': 'Virginia',        'statecode':  'VA'},
        {'state': 'Washington',      'statecode':  'WA'},
        {'state': 'West Virginia',   'statecode':  'WV'},
        {'state': 'Wisconsin',       'statecode':  'WI'},
        {'state': 'Wyoming',         'statecode':  'WY'}
    ],

    // https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
    DISTINCT_COLORS: <TDistinctColorInfo[]> [
        { 'name': 'White',      'hexColor': '#ffffff' },
        { 'name': 'Blue',       'hexColor': '#000075' },
        { 'name': 'Red',        'hexColor': '#ff0000' },
        { 'name': 'Green',      'hexColor': '#00ff00' },
        { 'name': 'Yellow',     'hexColor': '#ff3119' },
        { 'name': 'Silver',     'hexColor': '#a9a9a9' },
        { 'name': 'Pink',       'hexColor': '#fabebe' },
        { 'name': 'Black',      'hexColor': '#000000' },
        { 'name': 'Brown',      'hexColor': '#9a6324' },
        { 'name': 'Olive',      'hexColor': '#808000' },
        { 'name': 'Teal',       'hexColor': '#469990' },
        { 'name': 'Lightblue',  'hexColor': '#4363d8' },
        { 'name': 'Orange',     'hexColor': '#f58231' },
        { 'name': 'Lime',       'hexColor': '#bfef45' },
        { 'name': 'Cyan',       'hexColor': '#42d4f4' },
        { 'name': 'Purple',     'hexColor': '#911eb4' },
        { 'name': 'Magenta',    'hexColor': '#f032e6' },
        { 'name': 'Apricot',    'hexColor': '#ffd8b1' },
        { 'name': 'Mint',       'hexColor': '#aaffc3' },
        { 'name': 'Lavender',   'hexColor': '#e6beff' }
    ],

    Skus: {
        ONE_TIME_ACTIVATION_FEE_PACKAGE:    '101',
        PAY_AS_YOU_GO_MONTHLY_PACKAGE:      '102',
        PAY_AS_YOU_GO_CREDITS_PACKAGE:      '103',
        UNLIMITED_MONTHLY_USAGE_PACKAGE:    '104',
        UNLIMITED_YEARLY_USAGE_PACKAGE:     '105',
        AMBASSADOR_PACKAGE:                 '106',
        PREMIUM_SUPPORT_MONTHLY:            '201',
        PREMIUM_SUPPORT_YEARLY:             '202',
        WEB_HOSTING_MONTHLY:                '203',
        WEB_HOSTING_YEARLY:                 '204',
        ZOOM_TRAINING:                      '205'
    }
    
};

export const DateTimeFormats = {
    'All':          'EEEE, MMMM do yyyy, hh:mm:ss a',
    'Full':         'MMMM do yyyy, hh:mm:ss a',
    'ReverseFull':  'h:mm:ssa, MMM-DD-YY',
    'BigDate':      'EEEE, MMMM do yyyy',
    'MediumDate':   'EEE, MMM d yyyy',
    'LongDate':     'MMM dd, yyyy',
    'YearMonth':    'yyyy-MM',
    'Reports':      'EEE, MMM d yyyy, hh:mm:ss a',
    'ShortDate':    'yyyy-MM-dd',
    'SortDate':     'yyMMdd',
    'SortDate2':    'MMM-dd',
    'SortDateTime': 'yyMMdd_HHmmss',
    'MilliTime':    'h:mm:ss.SSS a',
    'ShortTime':    'hh:mm:ss a',
    'ShortTime1':   'HH:mm:ss',
    'ShortTime2':   'h:mm a',
    'SmallTime':    'h:mm',
    'UTC':          'yyyy-MM-ddTHH:mm:ss'
};

export const Documents = [
    { 'documentName': 'All Aboard',          'viewName': Constants.VIEW_ALL_ABOARD           },
    { 'documentName': 'Approaching Pickup',  'viewName': Constants.VIEW_SETTINGS_APPROACHING_PICKUP   },
    { 'documentName': 'Approaching Dropoff', 'viewName': Constants.VIEW_SETTINGS_APPROACHING_DROPOFF  },
    { 'documentName': 'Campaign Message',    'viewName': Constants.VIEW_MARKETING_CAMPAIGN_MESSAGE     },
    { 'documentName': 'Dropoff Completed',   'viewName': Constants.VIEW_SETTINGS_DROPOFF_COMPLETED    },
    { 'documentName': 'FAQ',                 'viewName': Constants.VIEW_FAQ                           },
    { 'documentName': 'Introduction',        'viewName': Constants.VIEW_INTRODUCTION                  },
    { 'documentName': 'Pickup Arrived',      'viewName': Constants.VIEW_SETTINGS_PICKUP_ARRIVED       },
    { 'documentName': 'Pickup Completed',    'viewName': Constants.VIEW_SETTINGS_PICKUP_COMPLETED     },
    { 'documentName': 'Privacy Policy',      'viewName': Constants.VIEW_PRIVACY_POLICY                },
    { 'documentName': 'Release Notes',       'viewName': Constants.VIEW_RELEASE_NOTES                 },
    { 'documentName': 'Roadmap',             'viewName': Constants.VIEW_ROADMAP                       },
    { 'documentName': 'Settings',            'viewName': Constants.VIEW_SETTINGS                      },
    { 'documentName': 'Terms Of Service',    'viewName': Constants.VIEW_TERMS_OF_SERVICE              },
    { 'documentName': 'Ticket Created',      'viewName': Constants.VIEW_TICKET_CREATED       },
    { 'documentName': 'Dashboard',           'viewName': Constants.VIEW_DASHBOARD, 'tourId': 13352    },

];

export const Roles: TRoleInfo[] = [
    { 'title': 'Driver',          'role': 'driver' },
    { 'title': 'Manager',         'role': 'manager' },
    { 'title': 'Service Advisor', 'role': 'serviceAdvisor' }
];

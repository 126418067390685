import { Injectable } from '@angular/core';

import { CollectionService } from '@shared/servicesCommon/collection.service';
import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { DateService } from '@shared/servicesCommon/date.service';
import { TSummaryCampaignDoc, TSummaryCustomerDoc, TSummaryTicketDoc, TSummaryTripDoc, TSummarySmsDoc, TTrip } from '@shared/models/index';;
import { TDailyValue, TDailyValueDoc, TSummary, TSummaryType, TTimestamp } from '@shared/models/index';
import { TSummaryCampaignDailyValues, TSummaryCustomerDailyValues, TSummaryTicketDailyValues, TSummaryTripDailyValues, TSummarySmsDailyValues } from '@shared/models/index';;

@Injectable({
    'providedIn': 'root'
})
export class SummaryService {

    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService,
                private dateService: DateService) {
    }

    private getSummaryReference() {
        const collection = this.collectionService.getSummariesCol();
        const summaryDoc = collection.doc('summary');
        const ref = summaryDoc.ref;
        return ref;
    }

    public async get(): Promise<TSummary> {
        const ref = this.getSummaryReference();
        return this.firestoreService.get(ref);
    }

    // public async getByUid(uid: string): Promise<TDailyValueDoc> {
    public async getByUid(uid: string): Promise<any> {
        const collection = this.collectionService.getSummariesCol();
        return this.firestoreService.getByUid(collection, uid);
    }

    public getObservable() {
        const collection = this.collectionService.getSummariesCol();
        const observable = collection.valueChanges();
        return observable;
    }

    public getByAccountNumber(accountNumber: string) {
        const ref = this.collectionService.getSummaryByAccountNumberRef(accountNumber);
        return this.firestoreService.get(ref);
    }

    public async setDocument(obj: any, uid: string): Promise<any>  {
        obj.uid = uid;
        return this.set(obj);
    }

    public set(summary: TSummary) {
        // summary.uid = 'overallSummary';
        const collection = this.collectionService.getSummariesCol();
        return this.firestoreService.set(collection, summary);
    }

    public update(summary: TSummary): any {
        const collection = this.collectionService.getSummariesCol();
        return this.firestoreService.update(collection, summary);
    }

    public getSummaryDocument(documentName: string) {
        const collection = this.collectionService.getSummariesCol();
        const summaryDoc = collection.doc(documentName);
        const ref = summaryDoc.ref;
        return ref;
    }

    public async getDailyCountsByDocument(documentName: TSummaryType): Promise<any> {
        const ref = this.getSummaryDocument(documentName);
        return this.firestoreService.get(ref);
    }
    
    public async getTicketsByDateRange(startTimestamp: TTimestamp, endTimestamp: TTimestamp)
                                    : Promise<any> {

        // We are only interested in those trips that were completed in the selected time frame.
        const doc = await this.getByUid('ticketsSummary') as TSummaryTicketDoc;       
        if (doc) {
            const dailyValues = this.filterDailyValuesByTimePeriod(doc.dailyValues, startTimestamp, endTimestamp);

            const uids: string[] = []; 
            dailyValues.forEach(daily => {
                daily.uids.forEach(uid => {
                    uids.push(uid);
                });
            });
            return {'uids': uids, 'dailyValues': dailyValues };
        }

        return null;
    }
    
    public async getTripsByDateRange(startTimestamp: TTimestamp, endTimestamp: TTimestamp)
                                    : Promise<any> {

        // We are only interested in those trips that were completed in the selected time frame.
        const doc = await this.getByUid('tripsSummary') as TSummaryTripDoc;       
        if (doc) {
            const dailyValues = this.filterDailyValuesByTimePeriod(doc.dailyValues, startTimestamp, endTimestamp);

            const uids: string[] = []; 
            dailyValues.forEach(daily => {
                daily.uids.forEach(uid => {
                    uids.push(uid);
                });
            });
            return {'uids': uids, 'dailyValues': dailyValues };
        }

        return null;
    }
    
    public async getCampaignUidsByDateRange(startTimestamp: TTimestamp, endTimestamp: TTimestamp)
                                        : Promise<any> {

        // We are only interested in those campaigns that were completed in the selected time frame.
        const doc = await this.getByUid('campaignsSummary') as TSummaryCampaignDoc;       
        if (doc) {
            const dailyValues = this.filterDailyValuesByTimePeriod(doc.dailyValues, startTimestamp, endTimestamp);

            const uids: string[] = []; 
            dailyValues.forEach(daily => {
                daily.campaignUids.forEach(uid => {
                    uids.push(uid);
                });
            });
            return {'uids': uids, 'dailyValues': dailyValues };
        }

        return null;
    }

    private filterDailyValuesByTimePeriod(dailyValues: any, startTimestamp: TTimestamp, endTimestamp: TTimestamp) {
        dailyValues = dailyValues || [];

        const selectedDailyValues = dailyValues.filter(daily => {
            const timestamp = this.dateService.toTimestamp(daily.date);
            const withinTimePeriod = ((timestamp.seconds >= startTimestamp.seconds) && 
                                      (timestamp.seconds <= endTimestamp.seconds));
            return withinTimePeriod;
        });

        return selectedDailyValues;
    }

    public async getSmsByDateRange(startTimestamp: TTimestamp, endTimestamp: TTimestamp)
                                                                                : Promise<any> {
        // We are only interested in those trips that were completed in the selected time frame.
        const doc = await this.getByUid('smsSummary') as TSummaryTicketDoc;       
        if (doc) {
            const dailyValues = this.filterDailyValuesByTimePeriod(doc.dailyValues, startTimestamp, endTimestamp);

            const uids: string[] = []; 
            dailyValues.forEach(daily => {
                daily.uids.forEach(uid => {
                    uids.push(uid);
                });
            });
            return {'uids': uids, 'dailyValues': dailyValues };
        }

        return null;

        // let smsDailyValues: TSummarySmsDailyValues[] = [];

        // // We are only interested in those trips that were completed in the selected time frame.
        // const doc = await this.getByUid('smsSummary') as TSummarySmsDoc;       
        // if (doc) {
        //     smsDailyValues = doc.dailyValues;
        //     // let dailyValues = <TDailyValueDoc>doc;  // .dailyValues;

        //     const selectedSms = smsDailyValues.filter(daily => {
        //         const timestamp = this.dateService.toTimestamp(daily.date);
        //         const withinTimePeriod = ((timestamp.seconds >= startTimestamp.seconds) && 
        //                                   (timestamp.seconds <= endTimestamp.seconds));
        //         return withinTimePeriod;
        //     });
        //     return selectedSms;
        // }

        // return null;
    }
    
    // public extractTicketUidsFromTrips(tripsDailyValues: TSummaryTripDailyValues[]) {
    //     const uids: string[] = [];

    //     tripsDailyValues.forEach(daily => {
    //         const date = daily.date;
    //         const count = daily.count;
    //         const trips = daily.trips;
    //         console.log(date, count, JSON.stringify(trips));
    //         const trip = trips[0];
    //         console.log(typeof trip);

    //         console.log(trip);
    //         // const uid = trip.tripUid;
    //         // const ticketUids  = trip.ticketUids;

    //         trips.forEach(trip => {
    //             trip.ticketUids.forEach(uid => {
    //                 uids.push(uid);
    //             });
    //         });


    //         daily.trips.forEach(trip => {
    //             trip.ticketUids.forEach(uid => {
    //                 uids.push(uid);
    //             });
    //         });
    //     });

    //     return uids;
    // }

    public extractUidsFromSms(smsDailyValues: TSummarySmsDailyValues[]) {
        const allUids: string[] = [];

        smsDailyValues.forEach(daily => {
            allUids.push(...daily.smsUids);
        });

        return allUids;
    }

}

import { Injectable } from '@angular/core';
import { Geolocation, PositionOptions } from '@capacitor/geolocation';
import { Constants, TCoordinate } from '@shared/models/index';

// https://enappd.com/blog/use-geolocation-geocoding-and-reverse-geocoding-in-ionic-capacitor/131/

///////////////////
// import { Geolocation } from '@capacitor/geolocation';
// const printCurrentPosition = async () => {
//   const coordinates = await Geolocation.getCurrentPosition();
//   console.log('Current position:', coordinates);
// };
///////////////////

@Injectable({
    'providedIn': 'root'
})
export class GeolocationService {
    private options: PositionOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    // private geolocation: Geolocation
    constructor() {
    }

    // For Capacitor
    // public async requestPermissions(): Promise<void> { //} Promise<PermissionStatus> {
    //     const permStatus = await Geolocation.requestPermissions();
    //     console.log('Permission status: ', permStatus);
    //     // return permStatus;
    // }
    // const printCurrentPosition = async () => {
    //   const coordinates = await Geolocation.getCurrentPosition();

    //   console.log('Current position:', coordinates);
    // };

    public async getCurrentLocation() {
        try {
            let coords: TCoordinate = Constants.NULL_COORDINATE;

            const coordinates = await Geolocation.getCurrentPosition(this.options);
            if (coordinates && coordinates.coords) {
                coords = {
                    'latitude': coordinates.coords.latitude,
                    'longitude': coordinates.coords.longitude
                };
            }
            return coords;
        } catch (err) {
            return Constants.NULL_COORDINATE;
        }
    }

    // public getPositionObservable_Original() {
    //     const options = {
    //         enableHighAccuracy: true,
    //         timeout: 5000,
    //         maximumAge: 0
    //     };
    //     //   id = navigator.geolocation.watchPosition(success, error, options);
    //     const watchObservable = this.geolocation.watchPosition(options);
    //     // const watchObservable = await Geolocation.watchPosition(this.options);
    //     // const watchObservable = this.geolocation.watchPosition(successCallback, errorCallback, options);
    //     return watchObservable;
    // }


    public async getPositionObservable(callback: any) {
        //   id = navigator.geolocation.watchPosition(success, error, options);
        // const watchObservable = this.geolocation.watchPosition(options);
        // const watchObservable = await Geolocation.watchPosition(this.options);
        // const watchObservable = this.geolocation.watchPosition(successCallback, errorCallback, options);
        // return watchObservable;

        const watchObservable = await Geolocation.watchPosition(this.options, (data) => {
            callback(data);
        });

        return watchObservable;
    }



    // private example() {
    //     const watchObservable = this.getPositionObservable();
    //     const subscription = watchObservable.subscribe((data) => {
    //         // data can be a set of coordinate, or an error (if an error occurred).
    //         // data.coords.latitude
    //         // data.coords.longitude
    //     });
    //     subscription.unsubscribe();
    // }

    /*
    startTracking() {
      this.isTracking = true;
      this.watch = Geolocation.watchPosition({}, (position, err) => {
        if (position) {
          this.addNewLocation(
            position.coords.latitude,
            position.coords.longitude,
            position.timestamp
          );
        }
      });
    }
     
    // Unsubscribe from the geolocation watch using the initial ID
    stopTracking() {
      Geolocation.clearWatch({ id: this.watch }).then(() => {
        this.isTracking = false;
      });
    }
     
    // Save a new location to Firebase and center the map
    addNewLocation(lat, lng, timestamp) {
      this.locationsCollection.add({
        lat,
        lng,
        timestamp
    */
}

/*
var id, target, options;

function success(pos) {
  var crd = pos.coords;

  if (target.latitude === crd.latitude && target.longitude === crd.longitude) {
    console.log('Congratulations, you reached the target');
    navigator.geolocation.clearWatch(id);
  }
}

function error(err) {
  console.warn('ERROR(' + err.code + '): ' + err.message);
}

target = {
  latitude : 0,
  longitude: 0
};

options = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 0
};

id = navigator.geolocation.watchPosition(success, error, options);
*/

            // Only show markers for shuttles that are not at the dealership.
            // if (vector.distanceFromDealership.miles > 0.10) {
            //     if (this.markers.has(shuttleName)) {
            //         let marker = this.markers.get(shuttleName);
            //         let pos = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
            //         marker.setPosition(pos);

            //         // let data = {'shuttle': shuttle, 'coordinate': coordinate, 'nearAddress': '' };
            //         // I am getting an OVER_QUERY_LIMIT error.  I can only call geocodeLatLng once every second.
            //         // A google post said 200ms is sufficient.
            //         // $timeout(function() {
            //         //     this.googleMapsService.geoLocate(data).then(function(data) {
            //         //         shuttle = data.shuttle;
            //         //         shuttle.nearAddress = data.nearAddress;
            //         //     });
            //         // }, 200);
            //     }
            // }
            // // else {
            // //     let marker = this.markers.get(shuttleName);
            // //     marker.setVisible(false);
            // //     // Don't display the address of the dealership.
            // //     vector.nearAddress = 'At Dealership';
            // // }

    // private getNearbyAddress(vector) {
    //     const promise = new Promise(async (resolve, reject) => {
    //         if (vector.distanceFromDealership.miles < 0.10) {
    //             resolve('At Dealership');
    //         } else {
    //             const obj: any = await this.geocoderService.convertLatLngToStreetAddress(vector.currentLocation);
    //             resolve(obj.formattedAddress);
    //         }
    //     });
    //     return promise;
    // }

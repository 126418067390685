import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
    'selector': 'app-variablesModal',
    'templateUrl': './variablesModal.page.html',
    'styleUrls': ['./variablesModal.page.scss'],
})
export class VariablesModalPage implements OnInit {
    public vm: any = {};
    private selectedItem = null;
    public variables = [
        // { 'name': '[CurrentCoordinate]',     'desc': 'The latitude and longitudec' },
        // { 'name': '[Customer]',              'desc': 'f' },
        // { 'name': '[DebugData]',             'desc': 'i' },
        // { 'name': '[Domain]',                'desc': 'k' },
        // { 'name': '[FakeCustomer]',          'desc': 'l' },
        // { 'name': '[FakeDepartureTime]',     'desc': 'm' },
        // { 'name': '[NumMilesToGo]',          'desc': 'p' },
        // { 'name': '[ShuttleDateFormatted]',  'desc': 'u' },
        // { 'name': '[ToCoordinate]',          'desc': 'v' }
        { 'name': '[AccountId]',             'desc': 'The first of two ways to identify your account.' },
        { 'name': '[AccountNumber]',         'desc': 'The second of two ways to identify your account.' },
        { 'name': '[CampaignLink]',          'desc': 'The link the customer uses to see the incentives page.' },
        { 'name': '[CountryCode]',           'desc': 'The country code of the dealership, typically +1.' },
        { 'name': '[CurrentDate]',           'desc': 'The date the message was sent.' },
        { 'name': '[CurrentTime]',           'desc': 'The time the message was sent.' },
        { 'name': '[CustomerName]',          'desc': 'The name of the customer.' },
        { 'name': '[DealershipName]',        'desc': 'The name of the dealership.' },
        { 'name': '[DepartureTime]',         'desc': 'The time the shuttle will leave.' },
        { 'name': '[HostingLink]',           'desc': 'The link to your incentive page on your webserver.' },
        { 'name': '[LiveTrackingLink]',      'desc': 'The link to the page that shows the real time location of the dealership\'s shuttles.' },
        { 'name': '[NumMinutes]',            'desc': 'The number of minutes after the message was sent to shuttle departure.' },
        { 'name': '[NumMiles]',              'desc': 'The distance between the shuttle and the pickup point.' },
        { 'name': '[OptOutLink]',            'desc': 'The link that the customer clicks on to optout of receiving future messages.' },
        { 'name': '[ShuttleDate]',           'desc': 'The last date the customer used your shuttle service.' },
    ];

    constructor(public modalController: ModalController, public navParams: NavParams) {
    }

    ngOnInit(): void {
        this.vm = this.navParams.get('vm');
    }

    public onItemClick(item) {
        this.selectedItem = item;
    }

    public onInsertClick() {
        const response = { 'okay': true, 'selectedItem': this.selectedItem };
        this.modalController.dismiss(response);
    }

    public onCancelClick() {
        const response = { 'okay': false, 'selectedItem': null };
        this.modalController.dismiss(response);
    }
}

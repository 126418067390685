import { Injectable, ErrorHandler } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { Pro } from '@ionic/pro';
import * as logRocket from 'logrocket';

import { CollectionService } from '@shared/servicesCommon/collection.service';
import { ConnectivityService } from '@shared/servicesCommon/connectivity.service';
import { DateService } from '@shared/servicesCommon/date.service';
import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { SessionService } from '@shared/servicesCommon/session.service';
import { UxHelperService } from '@shared/servicesCommon/uxHelper.service';
import { TError } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
    private errorsCollection: AngularFirestoreCollection<TError>;
    private lastCustomError: TError;

    constructor(private collectionService: CollectionService, private firestoreService: FirestoreService,
                private sessionService: SessionService, private uxHelperService: UxHelperService,
                private connectivityService: ConnectivityService, private dateService: DateService) {
        this.errorsCollection = collectionService.getErrorsCol();
    }

    // To end up here throw a new error message that isn't caught:
    // throw Error('Bombs in Dashboard.');
    handleError(error) {
        console.log(error);
        let errMsg1    = error.rejection?.hasOwnProperty('message') ? error.rejection.message : null;
        let stack1     = error.rejection?.hasOwnProperty('stack') ? error.rejection.stack : null;
        let errMsg2    = error.hasOwnProperty('message') ? error.message : null;
        let stack2     = error.hasOwnProperty('stack')   ? error.stack   : null;
        let fileName   = error.hasOwnProperty('fileName') ? error.fileName : null;
        let lineNumber = error.hasOwnProperty('lineNumber') ? error.lineNumber : null;
        let errorDate  = this.dateService.formatTimestamp('now');
        // Extract the pieces we need to build our custom error object
        const errorMessage = (errMsg1) ? errMsg1 : errMsg2;
        const stack = (stack1) ? stack1 : stack2;

        // 
        const user = this.sessionService?.getCurrentUser();
        let customError: TError = {
            'errDate': errorDate,
            'accountNumber': this.sessionService?.getAccountNumber() || 'Unknown',
            'usersFullName': this.sessionService?.getUsersFullName || 'Unknown',
            'usersEmail': user?.email || 'Unknown',
            'internetConnectivityStatus': this.connectivityService.isOnlineStr(),
        };

        if (errorMessage) {
            customError['errorMessage'] = errorMessage;
        }
        if (stack) {
            customError['stack'] = stack;
        }
        if (fileName) {
            customError['fileName'] = fileName;
        }
        if (lineNumber) {
            customError['lineNumber'] = lineNumber;
        }
        
        // Log this error to Ionic, LogRocket and Firestore
        this.logError(error, customError);

        // Log the error to the console so the developer can see it.
        console.error(customError);

        const msg = 'An unknown error occurred. The developers have been alerted.';
        this.uxHelperService.showToastWithCloseButton(msg);    
    }

    private logError(error, customError: TError) {
        // Do not repeatedly log the same error more than once.
        if (this.lastCustomError && customError.errorMessage === this.lastCustomError.errorMessage) {
            return;
        }
        this.lastCustomError = customError;

        // Log this error to Ionic
        // Pro.monitoring.exception(error);

        // Log this error to Firestore
        this.firestoreService.add(this.errorsCollection, customError);

        // Log this error to LogRocket
        logRocket.captureException(error, {
            tags: {
                // additional data to be grouped as "tags"
                subscription: 'Pro',
            },
            extra: {
                // additional arbitrary data associated with the event
                customError: JSON.stringify(customError)
            }
        });
    }
}

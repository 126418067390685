<ion-app>
    <!-- Splash screen stuff -->
<!-- ptb    
    <div id="custom-overlay" [style.display]="showSplash ? 'flex' : 'none'">
        <div class="flb">
            <div class="Aligner-item Aligner-item--top"></div>
            <img src="assets/img/splash.svg?v=2">
            <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
    </div>
     -->

    <!-- Sidemenu stuff -->
    <ion-split-pane contentId="my-content">
        <!-- Display sidemenu if logged in -->
        <ion-menu contentId="my-content" color="light" *ngIf="authService.isAuthenticated()">
            <div>
                <ion-header>
                    <ion-header>
                        <ion-toolbar>
                            <ion-grid>
                                <ion-row>
                                    <ion-col class="dealership">
                                        {{vm.dealershipName}}
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="welcome">
                                        Welcome Back {{vm.user.firstName}}
                                    </ion-col>
                                </ion-row>
                                <!-- <ion-row>
                                    <ion-col class="role">
                                        ({{vm.user.role}})
                                    </ion-col>
                                </ion-row> -->
                            </ion-grid>
                        </ion-toolbar>
                    </ion-header>
                </ion-header>
            </div>
            <ion-content>
                <div scrollY="true" style="height: 708px">
                    <ion-list data-test="sidemenuMenuitems">
                        <!-- <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'" (click)="onMenuItemClick(p)"
                                      [class.activeMenuitemHighlight]="checkActivePage(p)" [id]="p.title">
                                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    {{p.title}}
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle> -->

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'" 
                                    (click)="onMenuItemClick('Dashboard')" data-test="smDashboard"
                                    [class.activeMenuitemHighlight]="checkActivePage('Dashboard')"
                                    [hidden]="hideMenuitem('Dashboard')">
                                <ion-icon slot="start" name="stats-chart-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Dashboard
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Tickets')" data-test="smTickets"
                                    [class.activeMenuitemHighlight]="checkActivePage('Tickets')"
                                    [hidden]="hideMenuitem('Tickets')">
                                <ion-icon slot="start" name="pricetags-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Tickets
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Routes')" data-test="smRoutes"
                                    [class.activeMenuitemHighlight]="checkActivePage('Routes')"
                                    [hidden]="hideMenuitem('Routes')">
                                <ion-icon slot="start" name="color-wand-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Routes
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Navigation')" data-test="smNavigation"
                                    [class.activeMenuitemHighlight]="checkActivePage('Navigation')"
                                    [hidden]="hideMenuitem('Navigation')">
                                <ion-icon slot="start" name="map-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Navigate Route
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Tracker')" data-test="smTracker"
                                    [class.activeMenuitemHighlight]="checkActivePage('Tracker')"
                                    [hidden]="hideMenuitem('Tracker')">
                                <ion-icon slot="start" name="locate-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Tracker
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Marketing')" data-test="smMarketing"
                                    [class.activeMenuitemHighlight]="checkActivePage('Marketing')"
                                    [hidden]="hideMenuitem('Marketing')">
                                <ion-icon slot="start" name="cash-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Marketing
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Reports')" data-test="smReports"
                                    [class.activeMenuitemHighlight]="checkActivePage('Reports')"
                                    [hidden]="hideMenuitem('Reports')">
                                <ion-icon slot="start" name="documents-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Reports
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Settings')" data-test="smSettings"
                                    [class.activeMenuitemHighlight]="checkActivePage('Settings')"
                                    [hidden]="hideMenuitem('Settings')">
                                <ion-icon slot="start" name="construct-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Settings
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Account')" data-test="smAccount"
                                    [class.activeMenuitemHighlight]="checkActivePage('Account')"
                                    [hidden]="hideMenuitem('Account')">
                                <ion-icon slot="start" name="card"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Account
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('About')" data-test="smAbout"
                                    [class.activeMenuitemHighlight]="checkActivePage('About')"
                                    [hidden]="hideMenuitem('About')">
                                <ion-icon slot="start" name="information-circle-outline"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    About
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Help')" data-test="smHelp"
                                    [class.activeMenuitemHighlight]="checkActivePage('Help')"
                                    [hidden]="hideMenuitem('Help')">
                                <ion-icon slot="start" name="help-circle-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Help
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [disabled]=vb.disabled [routerDirection]="'root'"
                                    (click)="onMenuItemClick('Logout')" data-test="smLogout"
                                    [class.activeMenuitemHighlight]="checkActivePage('Logout')"
                                    [hidden]="hideMenuitem('Logout')">
                                <ion-icon slot="start" name="close-sharp"></ion-icon>
                                <ion-label class="ion-text-wrap">
                                    Logout
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
<!--                         
                        <!-- Used by Cypress to close the sidemenu
                        <ion-menu-toggle auto-hide="false">
                            <ion-item style="height:10px; width:10px;" data-test="smCloseSidemenu" href="javascript:;" >
                                <!-- <ion-button data-test="smCloseSidemenu">Close sidemenu</ion-button>
                            </ion-item>
                        </ion-menu-toggle> -->
                    </ion-list>
                </div> <!-- scrolly -->
            </ion-content>

            <ion-footer color="tertiary">
                <ion-grid class="tagline">
                    <ion-row>
                        <ion-col>
                            An Automated, Revenue Generating Shuttle Department With Improved CSI Scores In 30 Days.
                            <hr>
                            Version: {{vm.version}}
                        </ion-col>
                    </ion-row>
                </ion-grid>
                                        
                <!-- Used by Cypress to go back to the previous page -->
                <!-- border: none -->
                <!-- <ion-item  style="height:10px; width:10px; "
                        data-test="smGoBack" (click)="onGoBack()" >
                </ion-item> -->

                <!-- Used by Cypress to close the sidemenu -->
                <ion-menu-toggle auto-hide="false"> 
                    <ion-item color="tertiary" style="height:10px; width:10px; border: none; "
                            data-test="smCloseSidemenu" href="javascript:;" >
                    </ion-item>
                </ion-menu-toggle>
                
                <!-- <ion-input type="text" data-test="forCypress" 
                    style="height: 1px; width: 1px; border: none; font-size: 1px;">
                </ion-input>    -->
            </ion-footer>

            <!--
                Improving the economics and efficiency of your shuttle department
                just makes good business sense. Not paying one cent until it's
                improved makes more sense.

                Improved csi scores and a revenue generating shuttle department in 30 days
                just makes good business sense. Not paying one cent until it's improved makes
                more sense.

                Improved csi scores and a revenue generating shuttle department in 30 days.

                Improving csi scores and generating revenue by giving away free shuttle
                rides just makes good business sense. Not paying one cent until it's
                improved makes more sense.

                Automating and optimizing your shuttle department so you can have a better 
                relationship with your customers.

                An efficient, revenue generating shuttle department that improves customer 
                relations in 30 days.

                Automating, Optimizing and Improved CSI Scores in 30 Days.ustomer your shuttle 
                department that improves customer relations in 30 days.

                Improved csi scores and a revenue generating shuttle department in 30 days
                or less or just wait longer.  lol.
            -->

        </ion-menu>
        <ion-router-outlet id="my-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
import { Injectable } from '@angular/core';

import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { CollectionService } from '@shared/servicesCommon/collection.service';
import { SessionService } from '@shared/servicesCommon/session.service';
import { TDocument, TDomain, Documents } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class DocumentsService {
    private docs;

    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService,
                private sessionService: SessionService) {
        // this.docs = [
        //     { 'displayName': 'About',                            'ext': 'html', 'viewName': Constants.VIEW_ABOUT                           },
        //     { 'displayName': 'Account',                          'ext': 'html', 'viewName': Constants.VIEW_ACCOUNT                         },
        //     { 'displayName': 'Campaigns',                        'ext': 'html', 'viewName': Constants.VIEW_CAMPAIGNS                       },
        //     { 'displayName': 'Campaign',                         'ext': 'html', 'viewName': Constants.VIEW_CAMPAIGN                        },
        //     { 'displayName': 'Dealership',                       'ext': 'html', 'viewName': Constants.VIEW_DEALERSHIP                      },
        //     { 'displayName': 'FAQ',                              'ext': 'html', 'viewName': Constants.VIEW_FAQ                             },
        //     { 'displayName': 'Feedback',                         'ext': 'html', 'viewName': Constants.VIEW_FEEDBACK                        },
        //     { 'displayName': 'Help',                             'ext': 'html', 'viewName': Constants.VIEW_HELP                            },
        //     { 'displayName': 'Introduction',                     'ext': 'html', 'viewName': Constants.VIEW_INTRODUCTION                   },
        //     { 'displayName': 'Privacy Policy',                   'ext': 'html', 'viewName': Constants.VIEW_PRIVACY_POLICY                  },
        //     { 'displayName': 'Release Notes',                    'ext': 'html', 'viewName': Constants.VIEW_RELEASE_NOTES                   },
        //     { 'displayName': 'Reports Page 1',                   'ext': 'html', 'viewName': Constants.VIEW_REPORTS_PAGE1                   },
        //     { 'displayName': 'Reports Page 2',                   'ext': 'html', 'viewName': Constants.VIEW_REPORTS_PAGE2                   },
        //     { 'displayName': 'Reports Page 3',                   'ext': 'html', 'viewName': Constants.VIEW_REPORTS_PAGE3                   },
        //     { 'displayName': 'Routes',                           'ext': 'html', 'viewName': Constants.VIEW_ROUTES                          },
        //     { 'displayName': 'Route Page 1',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE1                     },
        //     { 'displayName': 'Route Page 2',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE2                     },
        //     { 'displayName': 'Route Page 3',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE3                     },
        //     { 'displayName': 'Route Page 4',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE4                     },
        //     { 'displayName': 'Route Page 5',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE5                     },
        //     { 'displayName': 'Route Page 6',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE6                     },
        //     { 'displayName': 'Route Page 7',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE7                     },
        //     { 'displayName': 'Route Page 8',                     'ext': 'html', 'viewName': Constants.VIEW_ROUTE_PAGE8                     },
        //     { 'displayName': 'Route View',                       'ext': 'html', 'viewName': Constants.VIEW_ROUTE_VIEW                      },
        //     { 'displayName': 'Navigation Page 1',                'ext': 'html', 'viewName': Constants.VIEW_NAVIGATION_PAGE1                },
        //     { 'displayName': 'Navigation Page 2',                'ext': 'html', 'viewName': Constants.VIEW_NAVIGATION_PAGE2                },
        //     { 'displayName': 'Navigation Page 3',                'ext': 'html', 'viewName': Constants.VIEW_NAVIGATION_PAGE3                },
        //     { 'displayName': 'Navigation Page 4',                'ext': 'html', 'viewName': Constants.VIEW_NAVIGATION_PAGE4                },
        //     { 'displayName': 'Navigation Page 5',                'ext': 'html', 'viewName': Constants.VIEW_NAVIGATION_PAGE5                },
        //     { 'displayName': 'Release Notes',                    'ext': 'json', 'viewName': Constants.VIEW_RELEASE_NOTES                   },
        //     { 'displayName': 'Satisfaction Page 1',              'ext': 'html', 'viewName': Constants.VIEW_SATISFACTION_PAGE1              },
        //     { 'displayName': 'Satisfaction Page 2',              'ext': 'html', 'viewName': Constants.VIEW_SATISFACTION_PAGE2              },
        //     { 'displayName': 'Service Areas',                    'ext': 'html', 'viewName': Constants.VIEW_SERVICE_AREAS                   },
        //     { 'displayName': 'Service Areas Single',             'ext': 'html', 'viewName': Constants.VIEW_SERVICE_AREAS_SINGLE            },
        //     { 'displayName': 'Service Areas Multiple',           'ext': 'html', 'viewName': Constants.VIEW_SERVICE_AREAS_MULTIPLE          },
        //     { 'displayName': 'Settings All Aboard',              'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_ALL_ABOARD             },
        //     { 'displayName': 'Settings Approaching Dropoff',     'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_APPROACHING_DROPOFF    },
        //     { 'displayName': 'Settings Approaching Pickup',      'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_APPROACHING_PICKUP     },
        //     { 'displayName': 'Settings Campaigns Message',       'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_CAMPAIGN_MESSAGE      },
        //     { 'displayName': 'Settings Campaign Incentive',         'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_CAMPAIGN_INCENTIVE        },
        //     { 'displayName': 'Settings Campaign Incentives',        'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_CAMPAIGN_INCENTIVES       },
        //     { 'displayName': 'Settings Campaign Hosting',        'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_CAMPAIGN_HOSTING       },
        //     { 'displayName': 'Settings Campaign Test Customer',  'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_CAMPAIGN_TEST_CUSTOMER },
        //     { 'displayName': 'Settings Misc',                    'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_MISC                   },
        //     { 'displayName': 'Settings DropoffRide Completed',   'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_DROPOFF_COMPLETED      },
        //     { 'displayName': 'Settings PickupRide Completed',    'ext': 'html', 'viewName': Constants.VIEW_SETTINGS_PICKUP_COMPLETED       },
        //     { 'displayName': 'Settings',                         'ext': 'html', 'viewName': Constants.VIEW_SETTINGS                        },
        //     { 'displayName': 'Shuttles',                         'ext': 'html', 'viewName': Constants.VIEW_SHUTTLES                        },
        //     { 'displayName': 'Shuttle',                          'ext': 'html', 'viewName': Constants.VIEW_SHUTTLE                         },
        //     { 'displayName': 'Tickets',                          'ext': 'html', 'viewName': Constants.VIEW_TICKETS                         },
        //     { 'displayName': 'Tickets Page 1',                   'ext': 'html', 'viewName': Constants.VIEW_TICKETS_PAGE1                   },
        //     { 'displayName': 'Tickets Page 2',                   'ext': 'html', 'viewName': Constants.VIEW_TICKETS_PAGE2                   },
        //     { 'displayName': 'Tickets Page 3',                   'ext': 'html', 'viewName': Constants.VIEW_TICKETS_PAGE3                   },
        //     { 'displayName': 'Tracker',                          'ext': 'html', 'viewName': Constants.VIEW_TRACKER                         },
        //     { 'displayName': 'Users',                            'ext': 'html', 'viewName': Constants.VIEW_USERS                           },
        //     { 'displayName': 'User',                             'ext': 'html', 'viewName': Constants.VIEW_USER                            },

        //     { 'displayName': 'Faq',                              'ext': 'html', 'viewName': Constants.VIEW_FAQ                             },
        //     { 'displayName': 'Privacy Policy',                   'ext': 'html', 'viewName': Constants.VIEW_PRIVACY_POLICY                  },
        //     { 'displayName': 'Terms of Service',                 'ext': 'html', 'viewName': Constants.VIEW_TERMS_OF_SERVICE                },
        //     { 'displayName': 'Release Notes',                    'ext': 'html', 'viewName': Constants.VIEW_RELEASE_NOTES                   },
        //     { 'displayName': 'Roadmap',                          'ext': 'html', 'viewName': Constants.VIEW_ROADMAP                         },
        //     { 'displayName': 'Introduction',                     'ext': 'html', 'viewName': Constants.VIEW_INTRODUCTION                    },

        // ];
        // this.docs = this.docs.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);
    }

    public getObservable() {
        const collection = this.collectionService.getDocumentsCol();
        const observable = collection.valueChanges();
        return observable;
    }

    public getByUid(uid: string): Promise<TDocument> {
        const collection = this.collectionService.getDocumentsCol();
        return this.firestoreService.getByUid(collection, uid);
    }

    public getAll(): Promise<TDocument[]> {
        const collection = this.collectionService.getDocumentsCol();
        return this.firestoreService.getAll(collection);
    }

    public set(doc: TDocument, uid: string = ''): Promise<TDocument> {
        if (uid) {
            doc.uid = uid;
        }
        const collection = this.collectionService.getDocumentsCol();
        return this.firestoreService.set(collection, doc);
    }

    public delete(uid: string): Promise<void> {
        const collection = this.collectionService.getDocumentsCol();
        return this.firestoreService.delete(collection, uid);
    }

    // public getDocumentMetaDataByDisplayName(domain: string, displayName: string): TDocument {
    //     const doc = this.docs.find(item => {
    //         return (item.displayName === displayName);
    //     });
    //     if (doc) {
    //         doc.uid = `${domain}_${doc.viewName}_${doc.ext}`;
    //     }

    //     return doc;
    // }

    public getDocumentMetaDataByViewName(viewName: string, domain?: string): TDocument {
        if (!domain) {
            domain = this.sessionService.getDomain();
            // Documents are stored with a prefix of 'staging' or 'app', never 'localhost'.
            // if (domain === 'localhost') {
            //     domain = 'staging';
            // }
        }

        const doc: any = Documents.find(item => {     // TDocument
            return (item.viewName === viewName);
        });
        if (doc) {
            doc.uid = `${domain}_${doc.viewName}`;
        }

        return doc;
    }

    public async getDocsByDomain(domain: TDomain): Promise<TDocument[]> {
        domain += '_';      // return docs that begin with 'staging_' or 'app_'
        const docs = await this.getAll();
        const subsetDocs = docs.filter(doc => {
            const env = doc.uid.startsWith(domain);
            return env;
        });
        return subsetDocs;
    }

    // public async getAllStagingDocs() {
    //     const docs = await this.getAll();
    //     const stagingDocs = docs.filter(doc => {
    //         const isStagingDoc = doc.uid.startsWith('staging_');
    //         return isStagingDoc;
    //     });
    //     return stagingDocs;
    // }

    // public async getAllProductionDocs() {
    //     const docs = await this.getAll();
    //     const prodDocs = docs.filter(doc => {
    //         const isProdDoc = doc.uid.startsWith('app_');
    //         return isProdDoc;
    //     });
    //     return prodDocs;
    // }

    public getAvailableDocs() {
        return this.docs;
    }

}

<ion-content class="ion-no-padding ion-no-scroll">
    <ion-list id="popoverMenu">
        <div class="ion-text-left">
            <ion-button expand="full" color="secondary" class="ion-no-margin" *ngFor="let item of menuItems" 
                    (click)="onItemClick(item)" [attr.data-test]="getDataTestId(item)" 
                    [attr.data-content]="getDataContent(item)">
                        <span>{{item.name}}</span>
            </ion-button> 
        <!-- This close button is used by Cypress.  
             Due to the 1 pixel height of the button and that the foreground color of the label
             matches the background color of the button, the button is there but not visible.  -->
            <ion-button expand="full" color="secondary" class="dismissButton" (click)="onDismissClick()" data-test="dismiss">
                <ion-label color="secondary">
                    Close
                </ion-label>
            </ion-button>
        </div>
    </ion-list>
</ion-content>

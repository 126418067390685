<ion-header>
    <ion-toolbar>
        <ion-title>Variables</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onCancelClick()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-radio-group>
            <ion-item *ngFor="let variable of variables" href="javascript:;">
                <ion-label class="ion-text-wrap">
                    <span class="line1">{{variable?.name}}</span><br />
                    <span class="line2">{{variable?.desc}}</span>
                </ion-label>
                <ion-radio value="{{variable?.name}}" (click)="onItemClick(variable)"></ion-radio>
            </ion-item>
        </ion-radio-group>
</ion-list>
</ion-content>

<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button color="primary" expand="full" (click)="onCancelClick()">Cancel</ion-button>
            </ion-col>
            <ion-col>
                <ion-button color="primary" expand="full" (click)="onInsertClick()">Insert</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>

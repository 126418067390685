<ion-header>
    <ion-toolbar color="primary">
        <ion-title class="helpTitle">Help</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onCloseClick()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
    <div class="body" [innerHTML]="myHTML"></div>
</ion-content>

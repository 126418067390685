import { Injectable } from '@angular/core';

import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { CollectionService } from '@shared/servicesCommon/collection.service';
import { TDealership } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class DealershipService {
    private documentName = 'dealershipProfile';

    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService) {
    }

    public getNullable(): TDealership {
        const dealership = {
            'address': {
                'city': '',
                'coordinate': {},
                'countryCode': '',
                'countryName': '',
                'isoCountryCode':     '',
                'isoSubdivisionCode': '',        
                'fullAddress': '',
                'state': '',
                'statecode': '',
                'streetAddress': '',
                'zipcode': ''
            },
            'dealershipName': '',
            'phoneNumber': '',
            'uid': ''
        };
        return dealership;
    }

    private getDealershipProfileReference() {
        const collection = this.collectionService.getProfilesCol();
        const accountProfileDoc = collection.doc(this.documentName);
        const ref = accountProfileDoc.ref;
        return ref;
    }

    public async get(): Promise<TDealership> {
        const ref = this.getDealershipProfileReference();
        let dealership: TDealership = await this.firestoreService.get(ref);
        return dealership;
    }

    // public getByFieldValue(fieldName: string, fieldValue: string): Promise<TAccountProfile[]> {
    //     const collection = this.collectionService.getAccountProfilesCol();
    //     return this.firestoreService.getByFieldValue(collection, fieldName, fieldValue);
    // }

    public async getDealershipByAccountNumber(accountNumber: string): Promise<TDealership> {
        this.collectionService.setAccountNumber(accountNumber);
        return this.get();
    }

    public async set(dealership: TDealership): Promise<TDealership>  {
        dealership.uid = this.documentName;
        const collection = this.collectionService.getProfilesCol();
        return this.firestoreService.set(collection, dealership);
    }

    public update(dealership: TDealership): Promise<TDealership> {
        const collection = this.collectionService.getProfilesCol();
        return this.firestoreService.update(collection, dealership);
    }
}

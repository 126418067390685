import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import { Platform } from '@ionic/angular';
import { TRole } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class PermissionsService {

    // private androidPermissions: AndroidPermissions
    // , private diagnostic: Diagnostic
    constructor(private platform: Platform) {
    }

    //   this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA)
    //     .then(result =>
    //         console .log('Has permission?', result.hasPermission),
    //         err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
    //     );
    // const androidPermissions = [
    //     this.androidPermissions.PERMISSION.CAMERA,
    //     this.androidPermissions.PERMISSION.GET_ACCOUNTS
    // ];
    // const diagnosticPermissions =
    // <uses-permission android:name="android.permission.ACCESS_FINE_LOCATION" />
    // <uses-permission android:name="android.permission.ACCESS_COARSE_LOCATION" />
    // <uses-permission android:name="android.permission.CALL_PHONE" />
    // this.androidPermissions.requestPermissions(permissions);

    public async getPermission(role: TRole): Promise<boolean> {
        let status: boolean;
        const onDevice = this.platform.is('cordova');

        if (onDevice) {
            // const status1 = await this.getDiagnosticAuthorization(this.diagnostic.permission.ACCESS_COARSE_LOCATION);
            // const status2 = await this.getDiagnosticAuthorization(this.diagnostic.permission.ACCESS_FINE_LOCATION);
            // const status3 = await this.getDiagnosticAuthorization(this.diagnostic.permission.CALL_PHONE);

            const status1 = await this.getDiagnosticAuthorization(Diagnostic.permission.ACCESS_COARSE_LOCATION);
            const status2 = await this.getDiagnosticAuthorization(Diagnostic.permission.ACCESS_FINE_LOCATION);
            const status3 = await this.getDiagnosticAuthorization(Diagnostic.permission.CALL_PHONE);

            // All permissions must be granted to proceed.
            status = (status1 && status2 && status3);
        } else {
            status = true;
        }

        return status;
    }

    private getDiagnosticAuthorization(permission: any): Promise<any> {
        const promise = new Promise(async (resolve, reject) => {
            // const status = await this.diagnostic.getPermissionAuthorizationStatus(permission);
            const status = await Diagnostic.getPermissionAuthorizationStatus(permission);
            // if (status === this.diagnostic.permissionStatus.GRANTED) {
                if (status === Diagnostic.permissionStatus.GRANTED) {
                resolve(true);
            } else {
                // const data = await this.diagnostic.requestRuntimePermission(permission);
                const data = await Diagnostic.requestRuntimePermission(permission);
                resolve(true);
            }
        });

        return promise;
    }
}

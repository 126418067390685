import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

// import { DocumentsService } from '/shared/servicesCommon/documents.service';
import { DocumentsService } from '../../servicesCommon/documents.service';
import { UxHelperService } from '../../servicesCommon/uxHelper.service';
import { TDocument } from '../../models/index';

@Component({
    'selector': 'app-helpModal',
    'templateUrl': './helpModal.page.html',
    'styleUrls': ['./helpModal.page.scss'],
})
export class HelpModalPage implements OnInit {
    // public title: string;
    public helpBody: string;
    public myHTML: string;
    private domain: string;
    private viewName: string;

    // private firebazeStorageService: FirebazeStorageService
    constructor(private navParams: NavParams, private uxHelperService: UxHelperService,
                private documentsService: DocumentsService, private modalController: ModalController) {
    }

    async ngOnInit(): Promise<void> {

        this.domain = this.navParams.get('domain');
        this.viewName = this.navParams.get('pageName');
        // const helpDocName = this.navParams.get('helpDocName');
        this.myHTML = await this.getHelpContent(this.viewName) as string;
    }

    public async getHelpContent(helpDocName: string) {
        try {
            await this.uxHelperService.showSpinner('Loading help.');
            const docMetaData: TDocument = this.documentsService.getDocumentMetaDataByViewName(this.viewName, this.domain);
            const doc: TDocument = await this.documentsService.getByUid(docMetaData.uid);
            return doc.contents;
        } catch (err) {
            await this.uxHelperService.hideSpinner();
            const emsg = `Help document not found for: ${helpDocName}`;
            this.onCloseClick();
            this.uxHelperService.showAlert('Error', emsg);
        } finally {
            await this.uxHelperService.hideSpinner();
        }
    }

    public onCloseClick() {
        this.modalController.dismiss();
    }
}

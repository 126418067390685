import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@shared/servicesCommon/utils.service';

@Pipe({
    name: 'myPhone',
    // pure: true
})
export class MyPhonePipe implements PipeTransform {
    private utilsService: UtilsService = new UtilsService();

    transform(value: unknown, ...args: unknown[]): string {     // unknown {
        const phoneNumber = value as string;

        if (!phoneNumber) {
            return '';
        }
        const str = this.utilsService.formatPhoneNumberForDisplay(phoneNumber);
        return str;
    }
}

import { Injectable } from '@angular/core';

import { EventsService } from '@shared/servicesCommon/events.service';
import { FirebaseFunctionsService } from '@shared/servicesCommon/firebaseFunctions.service';
import { GeolocationService } from '@shared/servicesCommon/geolocation.service';
import { SessionService } from '@shared/servicesCommon/session.service';
import { UxHelperService } from '@shared/servicesCommon/uxHelper.service';
import { VariableReplacementService } from '@shared/servicesCommon/variableReplacement.service';
import { Constants, TCampaignIncentiveSet, TCoordinate, TCustomer, TSetting } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class SmsService {
    private settings: TSetting;
    private static areEventHandlersInitialized: boolean = false; 

    constructor(private sessionService: SessionService, private firebaseFunctionService: FirebaseFunctionsService,
                private variableReplacementService: VariableReplacementService, private eventsService: EventsService,
                private geoLocationService: GeolocationService, private uxHelperService: UxHelperService) {
    }

    public async initialize(setting: TSetting) {
        this.settings = setting;

        if (SmsService.areEventHandlersInitialized === false) {
            SmsService.areEventHandlersInitialized = true;

            this.eventsService.subscribe(Constants.EVENT_TICKET_CREATED, (data) => { this.onTicketCreatedEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_ALL_ABOARD, (data) => { this.onAllAboardEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_APPROACHING_DROPOFF, (data) => { this.onApproachingDropoffEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_DROPOFF_COMPLETED, (data) => { this.onDropoffCompletedEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_APPROACHING_PICKUP, (data) => { this.onApproachingPickupEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_PICKUP_ARRIVED, (data) => { this.onPickupArrivedEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_PICKUP_COMPLETED, (data) => { this.onPickupCompletedEvent(data); });
            this.eventsService.subscribe(Constants.EVENT_CAMPAIGN_MESSAGE, (data) => { this.onCampaignMessageEvent(data); });
            // this.eventsService.subscribe(Constants.EVENT_ARCHIVE_SESSION_MESSAGES, (data) => { this.onArchiveSessionMessages(data); });
        }
    }

    public async onTicketCreatedEvent(data: any): Promise<any> {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
            this.settings.ticketCreated.enabled === false) {
            return 0;
        }

        const customer: TCustomer = data.customers[0];
        const debugData: any = data.debug;
        const rawMessage = this.settings.ticketCreated.rawMessage;

        await this.variableReplacementService.reset();
        this.variableReplacementService.customer = customer;
        this.variableReplacementService.debugData = debugData;
        const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

        return this.firebaseFunctionService.sendSms([customer], 'smsTicketCreated', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }

    public async onAllAboardEvent(data: any): Promise<any> {
        let response;

        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.allAboard.enabled === false) {
                return 0;
            }

            const customers: TCustomer[] = data.customers;
            const debugData: any = data.debug;
            const rawMessage = this.settings.allAboard.rawMessage;
            const numMinutes = this.settings.allAboard.numMinutes;

            for (const customer of customers) {
                await this.variableReplacementService.reset();
                this.variableReplacementService.customer = customer;
                this.variableReplacementService.numMinutes = numMinutes;
                this.variableReplacementService.debugData = debugData;
                const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

                response = await this.firebaseFunctionService.sendSms([customer], 'smsAllAboard', renderedMessage);
            }
            return response;
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }

    public async onApproachingDropoffEvent(data: any): Promise<any> {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.dropoffApproaching.enabled === false) {
                return 0;
            }

            const customer: TCustomer = data.customers[0];
            const rawMessage = this.settings.dropoffApproaching.rawMessage;
            const debugData: any = data.debug;

            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.debugData = debugData;
            this.variableReplacementService.numMilesToGo = this.settings.dropoffApproaching.numMilesToGo;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

            return this.firebaseFunctionService.sendSms([customer], 'smsDropoffApproaching', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }

    public async onDropoffCompletedEvent(data: any) {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.dropoffCompleted.enabled === false) {
                return 0;
            }

            const customer: TCustomer = data.customers[0];
            const rawMessage = this.settings.dropoffCompleted.rawMessage;
            const debugData: any = data.debug;

            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.debugData = debugData;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

            return this.firebaseFunctionService.sendSms([customer], 'smsDropoffCompleted', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);            
        }
    }

    public async onApproachingPickupEvent(data: any): Promise<any> {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.pickupApproaching.enabled === false) {
                    return 0;
            }

            const customer: TCustomer = data.customers[0];
            const rawMessage = this.settings.pickupApproaching.rawMessage;
            const debugData: any = data.debug;
            const toCoordinate: TCoordinate = data.toCoordinate;
            const currentCoordinate: TCoordinate = await this.geoLocationService.getCurrentLocation();

            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.currentCoordinate = currentCoordinate;
            this.variableReplacementService.toCoordinate = toCoordinate;
            this.variableReplacementService.debugData = debugData;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

            return this.firebaseFunctionService.sendSms([customer], 'smsPickupApproaching', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);            
        }
    }

    public async onPickupArrivedEvent(data: any) {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.pickupArrived.enabled === false) {
                return 0;
            }

            const customer: TCustomer = data.customers[0];
            const rawMessage = this.settings.pickupArrived.rawMessage;
            const debugData: any = data.debug;
            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.debugData = debugData;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

            return this.firebaseFunctionService.sendSms([customer], 'smsPickupArrived', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }

    public async onPickupCompletedEvent(data: any) {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.pickupApproaching.enabled === false) {
                    return 0;
            }

            const customer: TCustomer = data.customers[0];
            const rawMessage = this.settings.pickupCompleted.rawMessage;
            const debugData: any = data.debug;

            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.debugData = debugData;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

            return this.firebaseFunctionService.sendSms([customer], 'smsPickupCompleted', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }

    // This is called when the logged in user wants to send the campaign message to himself.
    public async onCampaignMessageEvent(data: any): Promise<any> {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.customer === false ||
                this.settings.campaignMessage.enabled === false) {
                return 0;
            }
            
            const customer: TCustomer = data.customers[0];
            const rawMessage = this.settings.campaignMessage.rawMessage;
            const debugData: any = data.debug;
        
            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.debugData = debugData;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);
        
            return this.firebaseFunctionService.sendSms([customer], 'smsPickupCompleted', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }

    // This is called to send the campaign to 'real' customers.
    public async sendCampaignMessage(data: any): Promise<any> {
        try {
            if (this.sessionService.getAccountProfile().featuresInfo.campaign === false ||
                this.settings.campaignMessage.enabled === false) {
                return 0;
            }

            const rawMessage       = this.settings.campaignMessage.rawMessage;
            const debugData: any   = data.debug;
            const customer         = data.customers[0];
            const incentiveSet     = data.incentiveSet as TCampaignIncentiveSet;

            // The customer may have opted out of receiving these incentives in which case he is no longer active.
            if (!customer.isActive) {
                return null;
            }

            await this.variableReplacementService.reset();
            this.variableReplacementService.customer = customer;
            this.variableReplacementService.incentiveSetId = incentiveSet.incentiveSetId;
            this.variableReplacementService.debugData = debugData;
            const renderedMessage = this.variableReplacementService.doVariableReplacements(rawMessage);

            return this.firebaseFunctionService.sendSms([customer], 'smsCampaign', renderedMessage);
        } catch (err) {
            this.uxHelperService.showAlert('Warning', err.message);
        }
    }
}

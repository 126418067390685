import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CollectionService } from '@shared/servicesCommon/collection.service';
import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { TSetting } from '@shared/models/index';

@Injectable({
  'providedIn': 'root'
})
export class SettingsService {

    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService) {
    }

    private getSettingReference() {
        const collection = this.collectionService.getSettingsCol();
        const settingDoc = collection.doc('setting');
        const ref = settingDoc.ref;
        return ref;
    }

    public getObservable(): Observable<TSetting[]> {
        const collection = this.collectionService.getSettingsCol();
        if (!collection) {
            return null;
        }

        const observable = collection.valueChanges();
        return observable;
    }

    public getByAccountNumber(accountNumber: string): Promise<TSetting> {
        const ref = this.collectionService.getSettingsByAccountNumberRef(accountNumber);
        return this.firestoreService.get(ref);
    }

    public async get(): Promise<TSetting> {
        const ref = this.getSettingReference();
        return this.firestoreService.get(ref);
    }

    public async set(setting: TSetting): Promise<TSetting>  {
        setting.uid = 'setting';
        const collection = this.collectionService.getSettingsCol();
        await this.firestoreService.set(collection, setting);
        return setting;
    }

    public async update(setting): Promise<TSetting> {
        const collection = this.collectionService.getSettingsCol();
        await this.firestoreService.set(collection, setting);
        return setting;
    }

    public async writeToFirebaseStorage(filename, html) {
        return this.firestoreService.writeToFirebaseStorage(filename, html);
    }
}

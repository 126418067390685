// Angular Stuff
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// import { Insomnia } from '@ionic-native/insomnia/ngx';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { LaunchNavigator } from '@ionic-native/launch-navigator';
// Removed from package
// import { CallNumber } from '@ionic-native/call-number/ngx';
// import { Diagnostic } from '@ionic-native/diagnostic/ngx';
// import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
// import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
// import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
// import { Geolocation } from '@capacitor/geolocation';
// import { FirebazeStorageService } from '@shared/servicesCommon/firebazeStorage.service';

// import { CallNumber } from '@awesome-cordova-plugins/call-number';
// import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';

// My Stuff & import services that need their own data
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { GlobalErrorHandlerService } from '@shared/servicesCommon/globalErrorHandler.service';

// Modals that are shared with the backend
import { PopoverMenuPageModule } from '@shared/modalsCommon/popoverMenu/popoverMenu.module';
import { CalendarModalPageModule } from '@shared/modalsCommon/calendarModal/calendarModal.module';
import { CheckboxModalPageModule } from '@shared/modalsCommon/checkboxModal/checkboxModal.module';
import { HelpModalPageModule } from '@shared/modalsCommon/helpModal/helpModal.module';
import { RadioModalPageModule } from '@shared/modalsCommon/radioModal/radioModal.module';

// Modals that are unique to the app
import { TicketsModalPageModule } from '@modals/ticketsModal/ticketsModal.module';
import { AddressModalPageModule } from '@modals/addressModal/addressModal.module';
import { PickupRequestModalPageModule } from '@modals/pickupRequestModal/pickupRequestModal.module';
import { VariablesModalPageModule } from '@modals/variablesModal/variablesModal.module';

import { ServiceWorkerModule } from '@angular/service-worker';
import { PipesModule } from '@shared/pipes/index';

// https://stackoverflow.com/questions/67845455/could-not-reach-cloud-firestore-backend-connection-failed-1-times
// import firebase from 'firebase/app';
// import 'firebase/firestore'
// const firebaseApp = firebase.initializeApp(environment.firebase);
// const db = firebaseApp.firestore();
// db.settings({
//     experimentalForceLongPolling: true, // this line
//     // useFetchStreams: false, // and this line
// });

@NgModule({
    declarations: [
        AppComponent,
        // HoursMinutesSeconds
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        // FirebazeStorageService,
        FormsModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        PopoverMenuPageModule,
        CalendarModalPageModule,
        CheckboxModalPageModule,
        TicketsModalPageModule,
        VariablesModalPageModule,
        HelpModalPageModule,
        AddressModalPageModule,
        PickupRequestModalPageModule,
        RadioModalPageModule,
        PipesModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        // CallNumber,
        // LaunchNavigator,
        // Insomnia,
        // NativeGeocoder,
        // Geolocation,
        // AndroidPermissions,
        // Diagnostic,
        Title,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: SETTINGS, useValue: {} }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

import { environment } from 'src/environments/environment';
import { UtilsService } from '@shared/servicesCommon/utils.service';
import { ConnectivityService } from '@shared/servicesCommon/connectivity.service';
import { FirebaseFunctionsService } from '@shared/servicesCommon/firebaseFunctions.service';
import { TLoginCredentials } from '@shared/models/interfaces';

@Injectable({
    'providedIn': 'root'
})
export class AuthService {
    private AuthMode = {
        UNKNOWN: 0,
        AUTHENTICATE_LOGIN_MODE: 1,
        REAUTHENTICATE_LOGIN_MODE: 2,
        LOG_OUT_MODE: 3,
        REGISTER_NEW_USER_MODE: 4,
        UPDATE_PASSWORD_MODE: 5,
        DELETE_USER_MODE: 6
    };

    // private loggedInStatus = 'false';
    private currentMode = this.AuthMode.UNKNOWN;
    private onAuthStateChangedListener = null;
    private secondaryApp: any;

    // private localStorageService: LocalStorageService
    constructor(public afAuth: AngularFireAuth, private utilsService: UtilsService,
        private firebaseFunctionsService: FirebaseFunctionsService, private connectivityService: ConnectivityService,) {
        this.secondaryApp = firebase.initializeApp(environment.firebase, 'Secondary');
    }

    // public async initialize() {
    //     this.loggedInStatus = await this.localStorageService.get('LoggedInStatus') || 'false';
    // }

    // https://firebase.google.com/docs/auth/web/auth-state-persistence
    // https://www.youtube.com/watch?v=si5fhwYVakk
    public async login(email: string, password: string): Promise<TLoginCredentials> {
        try {
            // New sign-in will be persisted with session persistence.
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            const cred = await firebase.auth().signInWithEmailAndPassword(email, password);
            const credentials: TLoginCredentials = {'email': cred.user.email, 'userUid': cred.user.uid};
            await this.showUserClaims();
            return credentials;
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                // The default error message is too cryptic for my taste.  So I decided to change it.
                error.message = 'This email address is not registered or the password is invalid.';
            } else if (error.code === 'auth/network-request-failed') {
                error.message = 'Internet connection not available.';
            }
            throw(error);
        }
    }

    public async showUserClaims(): Promise<any> {
        try {
            const user   = this.getCurrentUser();
            const token  = await user.getIdTokenResult();
            const claims = token.claims;    
            console.log('User Claims = ', claims);
            return claims;
        } catch (err) {
            console.log(err);
        }
        // firebase.auth().currentUser.getIdTokenResult().then((token) =>{
        //     console.log(token.claims);
        // }).catch((error)=>{
        //     console.log(error);
        // })
    }

    // public setLoggedInStatus(status: boolean) {
    //     this.localStorageService.set('LoggedInStatus', status.toString());
    // }

    // public async isLoggedIn() {
    //     const loggedInStatus = await this.localStorageService.get('LoggedInStatus') || 'false';
    //     return loggedInStatus;
    // }

    public async loginWithUserToken(token: string): Promise<TLoginCredentials> {
        try {
            await firebase.auth().signInWithCustomToken(token);
            const user = this.getCurrentUser();
            await this.showUserClaims();
            return user;
        } catch (err) {
            throw(err);
        }
    }

    public loginOld(email: string, password: string): Promise<TLoginCredentials> {
        const promise = new Promise<TLoginCredentials>(async (resolve, reject) => {
            try {
                await this.logout();
                const authData = await this.afAuth.signInWithEmailAndPassword(email, password);
                if (authData != null) {
                    const cred: TLoginCredentials = {'email': authData.user.email, 'userUid': authData.user.uid};
                    resolve(cred);
                } else {
                    const error = {'message': 'User not logged in.', 'code': 'Invalid credentials'};
                    reject(error);
                }
            } catch (error) {
                if (error.code === 'auth/wrong-password') {
                    // The default error message is too cryptic for my taste.  So I decided to change it.
                    error.message = 'This email address is not registered or the password is invalid.';
                } else if (error.code === 'auth/network-request-failed') {
                    error.message = 'Internet connection not available.';
                }
                reject(error);
            }
        });
        return promise;
    }

    public async logout() {
        // return this.afAuth.auth.signOut();
        await this.afAuth.signOut();
    }

    public resetPassword(email: string) {
        return this.afAuth.sendPasswordResetEmail(email);
    }

    //
    public async createUserWithEmailAndPassword(email, password): Promise<string> {
        const userCred = await this.afAuth.createUserWithEmailAndPassword(email, password);
        const uid = userCred.user.uid;
        return uid;
    }

    public registerUser(email: string, password: string) {
        // When creating a user, firebase automatically logs the original user out and
        // the newly created user in.  This behavior doesn't work for this application.
        // The manager needs to create drivers, service advisors, etc without being logged out.
        // The workaround is to create the new user using a secondary app.  The original
        // user remains logged in on the main app and the new user is logged in on the
        // secondary app.
        const promise = new Promise((resolve, reject) => {
            this.secondaryApp.auth().createUserWithEmailAndPassword(email, password)
            .then((authData) => {
                this.secondaryApp.auth().signOut();     // I don't know if this is necessary
                resolve(authData);
            });
        });
        return promise;
    }

    public isEmailAddressAvailable(email) {
        const promise = new Promise((resolve, reject) => {
            this.afAuth.fetchSignInMethodsForEmail(email)
            .then((snapshot) => {
                const available = (snapshot.length === 0);
                resolve(available);
            })
            .catch((err) => {
                reject(err);
            });
        });
        return promise;
    }

    public getCurrentUser() {
        try {
            const isOnline = this.connectivityService.isOnline();
            if (!isOnline) {
                return;
                // throw new Error ('Internet connection unavailable.')
            }
            const user = firebase.auth().currentUser;
            return user;
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                // The default error message is too cryptic for my taste.  So I decided to change it.
                error.message = 'This email address is not registered or the password is invalid.';
            } else if (error.code === 'auth/network-request-failed') {
                error.message = 'Internet connection unavailable.';
            }
            throw(error);
        }
    }

    public isAuthenticated(): boolean {
        const user = this.getCurrentUser();
        const isLoggedIn = (user !== null);
        return isLoggedIn;
    }

    public async isAuthenticatedNew(): Promise<boolean >{
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        return firebase.auth().currentUser !== null;
    }

    public async sendPasswordResetEmail(email: string) {
        const promise = new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                resolve(true);
            })
            .catch(err => {
                reject(err.message);
            });
        });
        return promise;
    }

    // A user either logged in successfully or the user logged out.
    // In both cases the authentication state changed.
    //    firebase.auth().onAuthStateChanged(function(user) {
    public myOnAuthStateChanged(user) {
        if (user === undefined || user === null) {
            // The user logged out.  Nothing to do.
            return;
        }

        switch (this.currentMode) {
            case this.AuthMode.UNKNOWN:
                // Nothing to do.
                break;

            case this.AuthMode.AUTHENTICATE_LOGIN_MODE:
                if (user) {
                    // Firebase has successfully authenticated this user.  All we need to do
                    // is let the entire application know we have a new user.
                }
                break;

            case this.AuthMode.REAUTHENTICATE_LOGIN_MODE:
                break;

            // case AuthMode.LOG_OUT_MODE:
            //     break;

            case this.AuthMode.REGISTER_NEW_USER_MODE:
                // Registration of a new user happens in two cases. 1) When the SuperUser logs in and creates
                // a new account/user.  2) When the Manager creates a new service advisor and driver.
                // In both cases, the registration of a new user implicitly logs that user in and
                // implicitly logs the current user out.  We need to undo this.  We need to keep the
                // SuperUser and the Manager logged in.
                break;

            case this.AuthMode.UPDATE_PASSWORD_MODE:
                // If the user changed their password then firebase auth will implicitly
                // log the user back in which will result in the onAuthStateChanged method being called.
                // So, it is wrong to broadcast that a new user has logged in.
                // return;
                break;

            case this.AuthMode.DELETE_USER_MODE:
                break;
        }
    }

    // Login the user with this email and password.
    // Once the user has been logged in, a broadcast message will be published so
    // the rest of the application can be initialized with this user.
    public authenticateLogin(email, password) {
        this.onAuthStateChangedListener = firebase.auth().onAuthStateChanged(this.myOnAuthStateChanged);
        return this.authenticate(this.AuthMode.AUTHENTICATE_LOGIN_MODE, email, password);
    }

    // Relogin the user with this email and password.
    // Once the user has been logged in, NO broadcast message will be published.  The
    // assumption is that the user was just logged in a short while ago.
    private reauthenticateLogin(email, password) {
        const promise = this.authenticate(this.AuthMode.REAUTHENTICATE_LOGIN_MODE, email, password);
        return promise;
    }

    public authenticate(mode, email, password) {
        const promise = new Promise((resolve, reject) => {
            // First log this user out.  This is important during development. The onAuth method was being called
            this.logout()
                .then((result) => {
                    this.currentMode = mode;
                    firebase.auth().signInWithEmailAndPassword(email, password);
                });
        });
        return promise;
    }
}

import { firestore } from 'firebase-admin'

export type TApp = 'app' | 'backend' | undefined;

export interface IAccount {
    accountNumber: string;
    accountId?: string;
    affiliateId?: string;
    dealershipName?: string;    // dealershipName is only used in Account TOC
    signupCouponCode?: string;
    uid?: string;
}
export type TAccount = IAccount;

export interface IAccountTOC {
    items: TAccountItem[];
}
export type TAccountTOC = IAccountTOC;

export interface IAccountItem {
    accountNumber: string;
    accountId: string;
    dealershipName?: string;
    uid: string;
}
export type TAccountItem = IAccountItem;
export interface ICreateAccountInfo {
    // Manager Info
    'firstName':           string;
    'lastName':            string;
    'cellPhone':           string;
    'email':               string;
    'userUid':             string;
    'password'?:           string;
    'roleTitle':           string;

    // 'value': this.utilsService.getRoleTitle(info.role)
    // 'value': info.packageName
    // 'value': this.dateService.formatTimestamp(info.expirationDate, DateTimeFormats.ShortDate)


    // Dealership Info
    'dealershipName':      string;
    'phoneNumber':         string;
    
    'countryAbbr':         string;
    'countryName':         string;
    'countryCode':         string;
    'isoCountryCode':      string;
    'isoSubdivisionCode':  string;

    'timezone':            string;
    'phonePrefixes':       string;
    'fullAddress':         string;
    'streetAddress':       string;
    'state':               string;
    'statecode':           string;
    'zipcode':             string;
    'city':                string;
    'coordinate': {
        latitude:          number;
        longitude:         number;
    };

    // Other Info
    'packageName':         TPackageName;
    'expirationDate':      string;
    'accountNumber':       string;
    'affiliateId':         string;
    'signupCouponCode':    string;
    'lists':               string[];
    'tags':                string[];
}
export type TCreateAccountInfo = ICreateAccountInfo;

export interface IAccountProfile {
    accountId?: string;
    accountNumber: string;
    activationInfo: TActivationInfo;
    ambassadorInfo?: TAmbassadorInfo;
    creditInfo?: TCreditInfo;
    debug?: boolean;
    featuresInfo?: TFeaturesInfo;
    fromSmsNumber?: string;
    modifiedDate?: number;
    notes?: string;

    packageName: TPackageName;
    periodInfo: TPeriodInfo;    
    phoneNumber?: string;
    pricingInfo?: TPricingInfo;
    showSetUserButton?: boolean;
    signupDate?: TTimestamp;
    sku?: string;
    status?: TAccountStatus;
    type?: TPackageType;
    uid?: string;
}
export type TAccountProfile = IAccountProfile;

export type TAccountStatus = 'Active' | 'In Trial Period' | 'Trial Period Expired' | 
                             'Cancelled By Support' | 'Cancelled By Customer' | 'Package Expired';

export interface IAddress {
    city: string;
    coordinate?: TCoordinate;
    countryAbbr?: string;
    countryCode: string;
    countryName: string;
    fullAddress?: string;
    isoCountryCode: string;
    isoSubdivisionCode: string;
    state?: string;
    statecode?: string;
    streetAddress: string;
    zipcode: string;
}
export type TAddress = IAddress;

export interface ICreditInfo {
    availableCredits?: number;
    lastCreditPurchaseDate?: TTimestamp;
};
export type TCreditInfo = ICreditInfo;

export interface IPricingInfo {
    priceName?: string;
    status?: TPriceStatus;
    paygCreditsCostPerCustomerMsg?: number;
    paygCreditsCostPerCampaignMsg?: number;
    paygBasePrice?: number;
    unlimitedMonthlyBasePrice?: number;
    unlimitedYearlyBasePrice?: number;
    ambassadorYearlyBasePrice?: number;
    uid?: string;
};
export type TPricingInfo = IPricingInfo;
export type TPriceStatus = 'Active' | 'Non-Active';

export type TPackageType = 'Pay As You Go' | 'Monthly' | 'Yearly' | 'Ambassador' | 'Trial';

export type TPackageName =  'Trial Package' | 'Pay As You Go Package' | 
                            'Unlimited Monthly Package' | 'Unlimited Yearly Package' | 'Ambassador Package';

export interface IActivationInfo {
    activationFeeOrderNumber?: number;
    activationDate?: TTimestamp;
};
export type TActivationInfo = IActivationInfo;

export interface IAmbassadorInfo {
    startDate?: TTimestamp;
    endDate?: TTimestamp;
};
export type TAmbassadorInfo = IAmbassadorInfo;

export interface IPeriodInfo {
    startDate: TTimestamp;
    expirationDate: TTimestamp;
    premiumSupportExpirationDate: TTimestamp;
    webHostingExpirationDate: TTimestamp;
};
export type TPeriodInfo = IPeriodInfo;

export interface IFeaturesInfo {
    premiumSupport?: boolean;
    webHosting?: boolean;    
    lockedInPricing?: boolean;
    campaign?: boolean;
    customer?: boolean;
    efficiency?: boolean;
};
export type TFeaturesInfo = IFeaturesInfo;

export interface IBackupFilename {
    fileName?: string;
}
export type TBackupFilename = IBackupFilename;

export interface ICampaign {
    createdDate?: TTimestamp;
    sentDate?: TTimestamp;
    numRecipients?: number;
    message?: string;
    uid: string;
}
export type TCampaign = ICampaign;

// The TTimestamp declared in functions is different than the TTimestamp declared in the App.
// In functions this is legal:
//    const ts = this.dateService.now();
//    ts.toDate();
// However it is not legal in the App.
// The obvious solution is to use only a single definition of what a TTimestamp is.
// So, I removed the vanilla TTimestamp and replaced it with the firestore.Timestamp.
// Let's see if that works!
//
// import {Timestamp } from '@firebase/firestore-types';    // commented out on 12/2/2022
// export type TTimestamp = Timestamp;                      // commented out on 12/2/2022
export type TTimestamp = firestore.Timestamp;               // inserted on 12/2/2022

export interface ICampaignIncentive {
    title: string;
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    line5?: string;
    line6?: string;
    line7?: string;
    note?: string;
    buttonText?: string;
    showCallButton?: boolean;
    sequenceNumber?: number;    // Remove this
    uid: string;
}
export type TCampaignIncentive = ICampaignIncentive;

export interface ICampaignIncentiveSet {
    setName: string;
    incentiveSetId: number;
    incentiveUids: string[];
    uid: string;
}
export type TCampaignIncentiveSet = ICampaignIncentiveSet;

export interface ICampaignOptions {
    customerName?: string;
}
export type TCampaignOptions = ICampaignOptions;

export interface IChannel {
    deploymentChannelName?: string;
    downloadProgress?: number;
    isLoading?: boolean;
    loadChannel?: string;
}
export type TChannel = IChannel;

export interface ICoordinate {
    latitude?: number;
    longitude?: number;
}
export type TCoordinate = ICoordinate;

export interface ICoordinateExt {
    latitude?: number;
    longitude?: number;
    locationName?: string;
    letter?: string;
}
export type TCoordinateExt = ICoordinateExt;

export interface ICustomer {
    cellPhone?: string;
    cellPhoneStr?: string;
    countryCode?: string;
    customerId?: string;
    maskedCellPhone?: string;
    customerName?: string;
    createdDate?: TTimestamp;
    isShuttlePassenger?: boolean;
    shuttleDates?: TTimestamp[];
    isActive?: boolean;
    checked?: boolean;      // Is checked needed anymore.  I think checked was replaced by selected.
    selected?: boolean;
    sessionId?: number;
    disabled?: boolean;
    uid: string;
}
export type TCustomer = ICustomer;

export interface Icoordinate {
    latitude?: number;
    longitude?: number;
}

export interface IRegion {
    'regionName': string;
    'regionCode': string;
}
export type TRegion = IRegion;

export interface IIsoCountry {
    countryName: string;
    isoCountryCode: string;
    countryAbbr: string;
    phonePrefixes: string[];
    regions: TRegion[];     //string[];
    uid?: string;
}
export type TIsoCountry = IIsoCountry;

export interface IIsoTimezone {
    offset: string;
    timezoneName: string;
    caption: string;
}
export type TIsoTimezone = IIsoTimezone;

export interface IIsoTimezones {
    timezones: TIsoTimezone[];
    createdDate?: TTimestamp;
    uid: string;
}
export type TIsoTimezones = IIsoTimezones;

export interface IChartData {
    dailyValues: number[],
    runningTotalValues: number[],
    labels: string[],
    backgroundColors: any[],
    borderColors: any[]
}
export type TChartData = IChartData;
// export const NullChartData = {
//     dailyValues: [0],
//     runningTotalValues: [0],
//     labels: [''],
//     backgroundColors: [0],
//     borderColorsyValues: [0],

// };

export interface ICurrentId {
    accountId: number;      // This is the only accountId that can be a number.  All others must be a string.
    customerId: number;     // This is the only customerId that can be a number.  All others must be a string.
    incentiveSetId: number;
}
export type TCurrentId = ICurrentId;
export const NullCurrentId: TCurrentId = {'accountId': -1, 'customerId': -1, 'incentiveSetId': -1};

export interface IDailyValue {
    date:   string;
    count:  number;
    campaignUids?: string[];
    customerUids?: string[];
    ticketUids?: string[];
    metadata: any;
    trips?: [ {
        tripUid?:    string;
        ticketUids?: string[];
    } ];
}
export type TDailyValue = IDailyValue;
export interface IDailyValueDoc {
    date:   string;
    count:  number;
    campaignUids?: string[];
    customerUids?: string[];
    ticketUids?: string[];
    trips?: [ {
        tripUid?:    string;
        ticketUids?: string[];
    } ];
}
export type TDailyValueDoc = IDailyValueDoc;

// export type TEmailType = 'text' | 'html';
export interface IEmail {
    from?: string;
    htmlBody?: string;      // use body for html types
    subject: string;
    // sendAs: TEmailType; 
    textBody?: string;      // use text for text types
    to: string;
}
export type TEmail = IEmail;

export interface IFunnelrUserFormField {
    formFieldId: string;
    value: string;
}
export type TFunnelrUserFormField = IFunnelrUserFormField;

export interface IFunnelrTagField {
    tagId: string;
    isRevoke: boolean;
}
export type TFunnelrTagField = IFunnelrTagField;

export interface IFunnelrListField {
    listId: string;
    unsubscribeReasonKey?: string;
    isRevoke: boolean;
}
export type TFunnelrListField = IFunnelrListField;

// "listId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "unsubscribeReasonKey": "Inappropriate",
// "isRevoke": true

export interface IFunnelrUser {
    email: string;
    firstName: string;
    lastName: string;
    telephone: string;
    city: string;
    timeZoneKey: string;
    isoCountryCode: string;
    isStaff: boolean;
    postalCode: string;
    street: string;
    isoSubdivisionCode: string;
    password: string;
    username: string;
    company: string;
    statusId?: string;
    statusName?: string;
    userProfile: TFunnelrUserFormField[];
    tags?: TFunnelrTagField[];
    lists?: TFunnelrListField[];
    userUid?: string;
    userGuid?: string;
    userId?: string;
}

export type TFunnelrUser = IFunnelrUser;
export interface ISummaryCampaignDailyValues {
    count: number;
    date: string;
    campaignUids: string[];
}
export type TSummaryCampaignDailyValues = ISummaryCampaignDailyValues;

export interface ISummaryCustomerDailyValues {
    count: number;
    date: string;
    customerUids: string[];
}
export type TSummaryCustomerDailyValues = ISummaryCustomerDailyValues;

export interface ISummaryTicketDailyValues {
    count: number;
    date: string;
    uids: string[];
    metadata: {
        serviceAdvisor: string;
        createdDate: TTimestamp;
    }
}
export type TSummaryTicketDailyValues = ISummaryTicketDailyValues;

export interface ISummaryTripInfo {
    completedDate: TTimestamp;
    driver: string;
    tripUid: string;
    ticketUids: string[];
}
export type TSummaryTripInfo = ISummaryTripInfo;

export interface ISummaryTripDailyValues {
    count: number;
    date: string;
    // trips: TSummaryTripInfo[];
    uids: string[];
    metadata: {
        driver: string;
        completedDate: TTimestamp;
    }
}
export type TSummaryTripDailyValues = ISummaryTripDailyValues;


// export interface ISummarySmsInfo {
//     tripUid: string;
//     ticketUids: string[];
// }
// export type TSummarySmsInfo = ISummarySmsInfo;

export interface ISummarySmsDailyValues {
    count: number;
    date: string;
    smsUids: string[]   // smsUidsTSummarySmsInfo[];
}
export type TSummarySmsDailyValues = ISummarySmsDailyValues;


export interface ISummaryCampaignDoc {
    dailyValues: TSummaryCampaignDailyValues[];
    uid: string;
}
export type TSummaryCampaignDoc = ISummaryCampaignDoc;

export interface ISummaryCustomerDoc {
    dailyValues: TSummaryCustomerDailyValues[];
    uid: string;
}
export type TSummaryCustomerDoc = ISummaryCustomerDoc;

export interface ISummaryTicketDoc {
    dailyValues: TSummaryTicketDailyValues[];
    uid: string;
}
export type TSummaryTicketDoc = ISummaryTicketDoc;

export interface ISummaryTripDoc {
    dailyValues: TSummaryTripDailyValues[];
    uid: string;
}
export type TSummaryTripDoc = ISummaryTripDoc;

export interface ISummarySmsDoc {
    dailyValues: TSummarySmsDailyValues[];
    uid: string;
}
export type TSummarySmsDoc = ISummarySmsDoc;

export type TSummaryDocs = 'campaignsSummary' | 'customersSummary' | 'ticketsSummary' | 'tripsSummary' | 'smsSummary';

export interface IDataResult {
    status?:     boolean;
    message?:    string;
    data?:       any;
    length?:     number;
}

export interface IDealership {
    address: TAddress;
    dealershipName: string;
    phoneNumber?: string;
    uid: string;
}
export type TDealership = IDealership;

export interface IDistance {
    kilometers?: number;
    miles?: number;
}
export type TDistance = IDistance;

export interface IDistinctColorInfo {
    name?: string;
    hexColor?: string;
}
export type TDistinctColorInfo = IDistinctColorInfo;

export interface IDocument {
    documentName?: string;
    domain?: string;
    viewName?: string;
    contents?: string;
    uid: string;
}
export type TDocument = IDocument;

export interface IDomainInfo {
    domain?: string;
    domainWithPort?: string;
    origin?: string;
    properOrigin?: string;      // will never be localhost.  localhost is replaced with staging!
}
export type TDomainInfo = IDomainInfo;
// export type TDomain = 'localhost' | 'staging' | 'app';  // prod
export type TDomain = 'staging' | 'app';  // prod

export interface IDriver {
    firstName?: string;
    fullName?: string;
    lastName?: string;
    uid?: string;
}
export type TDriver = IDriver;

export interface IError {
    errDate?: string;
    errorMessage?: string;
    stack?: string;
    errMsg2?: string;
    fileName?: string;
    lineNumber?: string;
    accountNumber?: string;
    usersFullName?: string;
    usersEmail?: string;
    internetConnectivityStatus?: string;
    uid?: string;
}
export type TError = IError;

// Step 1) Keep the following data in the event node
export interface IEvent {
    accountNumber?: string;  // who?: account
    userEmail?: string;      // who?: user
    eventDate?: number;      // when
    eventType?: string;      // what?: Signup, Login, WatchedVideo, SetupAccountProfile, TicketCompleted, etc
    eventTag?: string;       // VideoId, ExpirationDate
}

export interface IGeocoderResult {
    requestedAddress?: string;       // TRequestedAddress;
    result?: {
        formattedAddress?: string;
        coordinate?: TCoordinate;
    };
    statusMsg?: string;
}
export type TGeocoderResult = IGeocoderResult;

export interface IInternetConnection {
    isConnected?: boolean;
}
export type TInternetConnection = IInternetConnection;

export interface ILastPaymentInfo {
    paymentDate?: number;
    amount?: number;
    reason?: string;
}
export type TLastPaymentInfo = ILastPaymentInfo;

export interface ILoginCredentials {
    email?: string;
    userUid?: string;
}
export type TLoginCredentials = ILoginCredentials;

export interface IMarkerOptions {
    position?: any,
    icon?: string,
    animation?: any,
    title?: string,
    duration?: number,
    easing?: string,
    map?: any,
    shuttleName?: string
};
export type TMarkerOptions = IMarkerOptions;


export interface INavigationVM {
    attendanceSummary?: string;
    currentLegIdx?: number;
    currentLeg?: TRouteLeg;
    currentTicket?: TTicket;
    dropoffTickets?: TTicket[];
    isDebugMode?: boolean;
    myVector?: TVector;
    numAttendees?: number;
    numDropoffsStr?: string;
    numEmptySeats?: number;
    route?: TRoute;
    shuttles?: TShuttle[];
    step1Complete?: boolean;
    step2Complete?: boolean;
    step3Complete?: boolean;
    tickets?: TTicket[];
    trip?: TTrip
}
export type TNavigationVM = INavigationVM;

export interface INetPromoterScore {
    createdDate?: number;
    nps?: number;
    question?: string;
    reason?: string;
    suggestions?: string;
    uid?: string;
}
export type TNetPromoterScore = INetPromoterScore;


export interface IOrder {
    billing: TOrderBilling;
    header: TOrderHeader;
    items: TOrderItem[];
    shipping: TOrderShipping;
    uid?: string;
}
export type TOrder = IOrder; 

export interface IOrderBilling {
    accountNumber?: string;
    address1?: string;
    address2?: string;
    city?: string;
    company?: string;
    country?: string;
    email?: string;
    phone?: string;
    postcode?: string;
    state?: string;
    firstname?: string;
    lastname?: string;
}
export type TOrderBilling = IOrderBilling;

export interface IOrderHeader {
    currency?: string;
    createdDate?: {
        date: TTimestamp;
        timezone: string;
        timezone_type: number;
    };
    createdDateFormatted?: string;
    description?: string;
    discountTotal?: number;
    notes?: string;
    orderId?: number;
    paymentMethod?: string;
    paymentMethodTitle?: string;
    shippingTotal?: number;
    status?: string;
    total?: number;
    totalFormatted?: string;
    totalTax?: number;
    version?: string;
}
export type TOrderHeader = IOrderHeader;

export interface IOrderShipping {
    firstName?: string;
    lastName?: string; 
    company?: string;  
    address1?: string; 
    address2?: string; 
    city?: string;     
    state?: string;    
    postcode?: string; 
    country?: string;  
}
export type TOrderShipping = IOrderShipping;

export interface IOrderItem {
    // price?: number;
    // priceFormatted?: string;
    quantity: number;
    sku: string;
    subtotal: number;
    subtotalFormatted?: string;
    tax: number;
    taxFormatted?: string;
    total: number;
    totalFormatted?: string;
    type: string;
    unitPrice: number;
    unitPriceFormatted?: string;
}
export type TOrderItem = IOrderItem;

export type TPeriod = 'days' | 'months' | 'years';

export type TPickupStatus = 'findShuttles' | 'sendRequest' | 'responseReceived' | 'waitAndSupplyDefaultDeclinedResponse' | 'quit';

export interface IPickupRequest {
    ticket?: TTicket;
    promises?: any[];
    closestShuttles?: TShuttle[];
    status?: TPickupStatus;
    responses?: any[];
    currentIdx?: number;
    waitingCount?: number;
    availableShuttlesCallback?: any;
    createdDate?: number;
    shuttleUid?: string;
    uid?: string;
}
export type TPickupRequest = IPickupRequest;

export interface IPickupResponse {
    ticket?: TTicket;
    ticketUid?: string;
    requestUid?: string;
    accepted?: boolean;
    createdDate?: number;
    shuttleUid?: string;
    uid?: string;
}
export type TPickupResponse = IPickupResponse;
// 'shuttleUid': shuttleUid, 'ticketUid': ticket.uid, 'ticket': ticket,
// 'requestUid': requestUid, 'accepted': accept, 'uid': shuttleUid

// export interface IPirateEvent {
//     accountNumber?: string;
//     createdDate?: number;
//     tag?: string;
//     type?: string;
//     userEmail?: string;
// }
// export type TPirateEvent = IPirateEvent;

export interface IPopoverMenuItem {
    name?: string;
    testId?: string;
    content?: string;
}
export type TPopoverMenuItem = IPopoverMenuItem;

export interface IPositionTracker {
    subscribe(onNewPosition?: (coords?: Icoordinate) => void): void;
}

export interface IPost {
    id?: number;
    title?: string;
    subtitle?: string;
    excerpt?: string;
    imageUrl?: string;
    uid?: string;
    categories?: string[];
}
export type TPost = IPost;

export interface IRandomWords {
    numbers: string[];
    colors: string[];
    adjectives: string[];
    nouns: string[];
}
export type TRandomWords = IRandomWords;

// export interface IRegistrationData {
//     affiliateId?: string;
//     countryName?: string;
//     countryCode?: string;
//     incentiveCode?: string;
//     dealershipName?: string;
//     email?: string;
//     firstName?: string;
//     lastName?: string;
//     role?: string;
//     state?: string;
//     statecode?: string;
// }
// export type TRegistrationData = IRegistrationData;

export interface IReportType {
    'title'?: string;
    'description'?: string;
    'name'?: string;
    'columns'?: string[];
    'sortColumns'?: number[];
    'colWidths'?: number[];
    'reportParmsNeeded'?: boolean;
}
export type TReportType = IReportType;

export interface IReport {
    // type?: string;
    createReportService(reportOptions?: TReportOptions): any;
}
export type TReport = IReport;

export interface IReportData {
    'data'?: string[];
}
export type TReportData = IReportData;

export interface IReportOptions {
    selectedReport?: IReportType;
    startingDate?: any;
    endingDate?: any;
}
export type TReportOptions = IReportOptions;

export interface IGetReportData {
    getReportData(): any;   // should return Promise<TReportData> but the typescript transpiler complains
}

// export type TRequestedAddress?: string;

// export interface IRequestedAddress {
//     streetAddress?: string;
// }
// export type TRequestedAddress = IRequestedAddress;

// export declare type RunGuardsAndResolvers = 'pathParamsChange' | 'pathParamsOrQueryParamsChange' |
//   'paramsChange' | 'paramsOrQueryParamsChange' | 'always' |
//   ((from?: ActivatedRouteSnapshot, to?: ActivatedRouteSnapshot) => boolean);


export declare type TRole = 'accountant' | 'driver' |  'manager' | 'serviceAdvisor' | 'undefined';

export type TRoleInfo = {
    title: string;
    role: TRole
};

// export interface IRouteOld {
//     dealership?: TDealership;
//     endingPoint?: TRoutePoint;
//     modifiedDate?: number;
//     routeLegs?: TRouteLeg[];
//     selectedTickets?: TTicket[];
//     shuttle?: TShuttle;
//     startingPoint?: TRoutePoint;
//     uid?: string;
// }
// export type TRoute = IRouteOld;

export interface IRoute {
    endingPoint?: TRoutePoint;
    modifiedDate?: number;
    routeLegs?: TRouteLeg[];
    selectedTickets?: TTicket[];
    shuttle?: TShuttle;
    startingPoint?: TRoutePoint;
    uid?: string;
}
export type TRoute = IRoute; 

export interface IRouteLeg {
    from?: TRoutePoint;
    isUrgent?: boolean;
    letter?: string;
    to?: TRoutePoint;
    passengerName?: string;
    status?: string;
    ticketUid?: string;
    waypointNumber?: number;
}
export type TRouteLeg = IRouteLeg;

export interface ILocation {
    address: TAddress;
    locationName?: string;
}
export type TLocation = ILocation;

export interface IRoutePoint {
    address?: string;
    address1?: string;
    address2?: string;
    coordinate?: TCoordinateExt;
    locationName?: string;
    passengerName?: string;
    letter?: string;
    waypointNumber?: number;
}
export type TRoutePoint = IRoutePoint;

export interface IServiceArea {
    city?: string;
    distance?: number;
    state?: string;
    zipcode?: string;
    uid?: string;
}
export type TServiceArea = IServiceArea;

export interface ISession {
    email?: string;
    firstName?: string;
    lastName?: string;
    loginDate?: number;
    role?: string;
    userUid?: string;
}
export type TSession = ISession;

export interface IAllAboard {
    enabled?: boolean;
    numMinutes?: number;
    rawMessage?: string;
    renderedMessage?: string;
}
export type TAllAboard = IAllAboard;

export interface IApproachingDropoff {
    enabled?: boolean;
    numMilesToGo?: number;
    rawMessage?: string;
    renderedMessage?: string;
}
export type TApproachingDropoff = IApproachingDropoff;

export interface IApproachingPickup {
    enabled?: boolean;
    numMilesToGo?: number;
    rawMessage?: string;
    renderedMessage?: string;
}
export type TApproachingPickup = IApproachingPickup;

export interface IHosting {
    enabled?: boolean;
    campaignHostingLink?: string;
    enabledStr?: string;
}
export type THosting = IHosting;

export interface ICampaignMessage {
    enabled?: boolean;
    rawMessage?: string;
    renderedMessage?: string;
    messageSize?: string;
}
export type TCampaignMessage = ICampaignMessage;

export interface IPickupArrived {
    enabled?: boolean;
    rawMessage?: string;
    renderedMessage?: string;
}
export type TPickupArrived = IPickupArrived;

export interface IRideCompleted {
    enabled?: boolean;
    rawMessage?: string;
    renderedMessage?: string;
}
export type TRideCompleted = IRideCompleted;

export interface ITicketCreated {
    enabled?: boolean;
    rawMessage?: string;
    renderedMessage?: string;
}
export type TTicketCreated = ITicketCreated;

export interface IMisc {
    showNearbyAddress?: boolean;
    showShuttleDistance?: boolean;
    showSimulationButton?: boolean;
    saveNavigationLocationFrequency?: number;
    showTrackerModeButton: boolean;
    quickLogin?: boolean;
}
export type TMisc = IMisc;

export interface ISetting {
    ticketCreated?: TTicketCreated;
    allAboard?: TAllAboard;
    dropoffApproaching?: TApproachingDropoff;
    dropoffCompleted?: TRideCompleted;
    pickupApproaching?: TApproachingPickup;
    pickupArrived?: TPickupArrived;
    pickupCompleted?: TRideCompleted;
    hosting?: THosting;
    campaignMessage?: TCampaignMessage;
    misc?: TMisc;
    modifiedDate?: number;
    uid?: string;
}
export type TSetting = ISetting;

export interface IShuttle {
    shuttleName: string;
    description?: string;
    color?: string;
    status: TShuttleStatus;
    passengerCapacity: number;
    uid?: string;
}
export type TShuttle = IShuttle;
export type TShuttleStatus = 'Idle' | 'Assigned' | 'Traveling';

export interface ISkuAction {
    sku: string;
    description: string;
    increaseExpiration: boolean;            // if true, bump up expiration days
    increaseExpirationDays: number;         // if < 1, don't touch expiration date
    increaseExpirationMonths: number;       // if < 1, don't touch expiration date
    increaseExpirationYears: number;        // if < 1, don't touch expiration date
    increasePremiumSupportMonths: number;   // if < 1, don't touch premium support expiration date
    increasePremiumSupportYears: number;    // if < 1, don't touch premium support expiration date
    increaseWebHostingMonths: number;       // if < 1, don't touch web hosting expiration date
    increaseWebHostingYears: number;        // if < 1, don't touch web hosting expiration date
    updateActivationInfo: boolean;
    updateCredits: boolean;
    updatePackageDetails: boolean;
    sendCompleteOrderEmail: boolean;  
    type: string;
    uid: string;
}
export type TSkuAction = ISkuAction;

export interface ISmsSentStatus {
    sequenceNo: number;
    passengerName: string;
    cellPhone?: string;
    maskedCellPhone?: string;
    sentStatus: TSentStatus;
    sentDate: string;
    ticket: TTicket;
    sessionId?: number;
};
export type TSmsSentStatus = ISmsSentStatus;
export type TSentStatus = 'pending' | 'sent';

export interface ISmsFromNumber {
    fromNumber: string;
    numAccounts: number;
    uid: string;
}
export type TSmsFromNumber = ISmsFromNumber;

export interface ISmsSentMessage {
    // accountNumber?: string;
    // bodyText?: string;
    cellPhone?: string;
    countryCode?: string;
    customerName?: string;
    customerReceivedMessageDate?: TTimestamp;
    fromNumber?: string;
    message?: string;
    numSegments?: number;
    sentToCustomerDate?: TTimestamp;
    sentToTwilioDate?: TTimestamp;
    receivedAt?: TTimestamp;
    sentDate?: TTimestamp;
    sentAt?: TTimestamp;
    sessionId?: number;
    smsType?: TSmsType;
    toNumber?: string;
    uri?: string;
    uid: string;
}
export type TSmsSentMessage = ISmsSentMessage;

export type TSmsType = 'smsTicketCreated' | 'smsAllAboard' | 'smsDropoffApproaching' | 'smsDropoffCompleted' |
                       'smsPickupApproaching' | 'smsPickupArrived' | 'smsPickupCompleted' | 'smsCampaign';

export interface ISpeed {
    kph?: number;
    mph?: number;
}
export type TSpeed = ISpeed;

export interface IStateInfo {
    state?: string;
    statecode?: string;
}
export type TStateInfo = IStateInfo;

export interface ISummary {
    'numCampaigns'?: number,
    'numCustomers'?: number,
    'numRoutes'?: number,
    'numServiceAreas'?: number,
    'numShuttles'?: number,
    'numTickets'?: number,
    'numTrips'?: number,
    'numUsers'?: number,

    'smsTicketCreated'?: number;
    'smsAllAboard'?: number;
    'smsDropoffApproaching'?: number;
    'smsDropoffCompleted'?: number;
    'smsPickupApproaching'?: number;
    'smsPickupArrived'?: number;
    'smsPickupCompleted'?: number;

    'dataUsage'?: {
        'campaigns'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'customers'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'expirationDateExtension'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'serviceAreas'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'shuttles'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsCampaigns'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsTicketCreated'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsAllAboard'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsDropoffApproaching'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsDropoffCompleted'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsPickupApproaching'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsPickupArrived'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'smsPickupCompleted'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'tickets'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'tracker'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'trips'?: {
            count?: number,
            lastModifiedDate?: number
        };
        'users'?: {
            count?: number,
            lastModifiedDate?: number
        };
    };
    'viewUsage'?: {
        'expirationExtension'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsTicketCreated'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsAllAboard'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsApproachingDropoff'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsDropoffCompleted'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsApproachingPickup'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsPickupArrived'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsPickupCompleted'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'settingsCampaign'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'navigation'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'routes'?: {
            count?: number,
            lastAccessedDate?: number
        };
        'tickets': {
            count?: number,
            lastAccessedDate?: number
        };
        'tracker': {
            count?: number,
            lastAccessedDate?: number
        };
    };
    uid?: string;
}
export type TSummary = ISummary;

export type TSummaryType = 'campaignsSummary' | 'customerSummary' | 'ticketsSummary' |'tripsSummary'
;
export interface ITask {
    header?: {
        taskType?: TTaskType;
        taskCreatedDate?: number;    // Can't use timestamp.  It doesn't support milliseconds.
        // taskCreatedDate?: number;
        // executeWhen?: TTaskExecuteWhen;
        // executeWhen?: Timestamp;
        executeWhen?: number;
        accountNumber?: string;
        countryCode?: string;
        dealershipPhoneNumber?: string;
        domain?: string;
    };
    textMessageData?: {
        customer?: TCustomer;
        smsType?: TSmsType;
        textMessage?: string;
    };
    ticketData?: {
        ticketUid?: string;
        executeWhen?: number;
    };
    // registrationData?: TRegistrationData;
    uid?: string;
}
export type TTask = ITask;

export type TTaskType = 'sendText' | 'updateTicketStatus' | 'createAccount';
// export type TTaskExecuteWhen = 'asap' | 'schedule';

// export interface IVariables {       // Text Message Replacement Variables
//     accountNumber?: string;         // newTextMessage = newTextMessage.replace('[AccountNumber]', accountNumber);
//     campaignLink?: string;          // newTextMessage = newTextMessage.replace('[CampaignLink]', newCampaignLink);
//     customer?: TCustomer;           // message = message.replace('[Name]', customer.name);
//     currentTime?: string;           // message = message.replace('[CurrentTime]', now);
//     customerName?: string;          // newTextMessage = newTextMessage.replace('[CustomerName]', customer.customerName);
//     dealershipName?: string;        // message = message.replace('[DealershipName]', dealershipName);
//     departureTime?: string;         // message = message.replace('[DepartureTime]', fromNow);
//     domain?: string;                // let newTextMessage = textMessage.replace('[Domain]', domain);
//     fakeCustomer?: TCustomer;       //
//     fakeDepartureTime?: string;     //
//     hostingLink?: string;           // let newTextMessage = textMessage.replace('[Domain]', domain);
//     numMilesToGo?: string;          // message = message.replace('[NumMilesToGo]', numMilesToGo.toString());
//     numMinutes?: string;            // message = message.replace('[NumMinutes]', numMinutes.toString());
//     numMiles?: number;              // message = message.replace('[NumMiles]', distance.miles.toString());
//     optoutLink?: string;            // newTextMessage = newTextMessage.replace('[OptoutLink]', newOptoutLink);
//     shuttleDate?: number;           // newTextMessage = newTextMessage.replace('[ShuttleDate]', shuttleDate);
//     toCoordinate?: TCoordinate;
//     currentCoordinate?: TCoordinate;
//     debugData?: any;
// }
// export type TVariables = IVariables;

export interface ITicket {
    address?: string;
    allAboardDate?: TTimestamp;
    assignedDate?: TTimestamp;
    assignedDateStr?: string;
    attendance?: boolean;
    attendanceStr?: string;
    cellPhone?: string;
    completedDate?: TTimestamp;
    // coordinate?: TCoordinate;
    createdDate?: TTimestamp;
    currentDate?: TTimestamp;
    serviceAdvisor?: string;
    driver?: string;
    dropoff?: TTicketLocation;
    enrouteDate?: TTimestamp;
    fromAddress?: string;
    fromDealership?: boolean;
    isUrgent?: boolean;
    locationName?: string;
    note?: string;
    numPassengers?: number;
    numPassengersStr?: string;
    passengerName?: string;
    pickup?: TTicketLocation;
    priority?: boolean;
    priorityStr?: string;
    status?: TTicketStatus;
    selected?: boolean;
    takeBelongingsDate?: TTimestamp;
    toAddress?: string;
    uid: string;
}
export type TTicket = ITicket;

export interface ITicketLocation {
    address?: string;
    city?: string;
    coordinate?: TCoordinate;
    locationName?: string;
    streetAddress?: string;
    zipcode?: string;
}
export type TTicketLocation = ITicketLocation;

export declare type TTicketStatus = 'pending' | 'assigned' | 'enroute' | 'current' | 'completed';
export declare type TTripType = 'Actual' | 'Simulated';

export interface ITrip {
    actualLocations?: TTripActualLocationDoc[];
    driver?: string;
    endTripDate?: TTimestamp;
    startTripDate?: TTimestamp;
    shuttle?: TShuttle;
    tickets?: TTicket[];
    plannedRoute?: TRoute;
    status?: TTripStatus;
    totalPositions?: number;
    type?: TTripType;
    uid: string;
}
export type TTrip = ITrip;
export declare type TTripStatus = 'driving' | 'completed';

export interface ITripActualLocation_Original {
    dateTime?: TTimestamp;
    coordinate?: TCoordinate;
    legIndex?: number;
    sequenceNum?: number;
    speed?: TSpeed;
    uid?: string;
}
export type TTripActualLocation_Original = ITripActualLocation_Original;

export interface ITripActualPosition {
    dateTime: TTimestamp;
    coordinate: TCoordinate;
    speed: TSpeed;
    heading: any;
    legIndex: number;
}
export type TTripActualPosition = ITripActualPosition;

export interface ITripActualLocationDoc {
    actualPositions: TTripActualPosition[];
    legIndex?: number;
    sequenceNum?: number;
    uid?: string;
}
export type TTripActualLocationDoc = ITripActualLocationDoc;


export interface IUser {
    firstName: string;
    lastName: string;
    cellPhone: string;
    email: string;
    role: TRole;
    roleTitle: string;
    customerId: string;
    uid: string;
}
export type TUser = IUser;

export interface IUserAccountMap {
    'accountId'?: string,
    'accountNumber'?: string;
    'createdDate'?: number;
    'email'?: string;
    'modifiedDate'?: number;
    'pwd'?: string;
    'uid': string;
    'funnelrUserGuid'?: string;
    'funnelrUserUid'?: string;

}
export type TUserAccountMap = IUserAccountMap;

export declare type TValidator = 'text' | 'optphone' | 'number' | 'email' | 'phone';

export interface IVector {
    accuracy?: string;
    currentLocation?: TCoordinate;
    currentTime?: number;
    // direction?: number;
    distanceFromDealership?: TDistance;
    distanceToTicket?: TDistance;
    driver?: TDriver;
    heading?: any;
    modifiedDate?: number;
    nearAddress?: string;
    previousLocation?: TCoordinate;
    previousTime?: number;
    shuttle?: TShuttle;
    speed?: TSpeed;
    tripUid?: string;
    uid?: string;
}
export type TVector = IVector;
export declare type TVectorType = 'real' | 'simulated';

export interface IVideo {
    audience?: string[];
    description?: string;
    sequenceNumber?: number;
    title?: string;
    trustedUrl?: any;
    videoUrl?: string;
    uid?: string;
}
export type TVideo = IVideo;

export interface IZipcodes {
    state: string;
    statecode: string;
    countrycode: string;
    numZipcodes: number;
    timezone: string;
    uid: string;
    zipcodes: TZipcode[];
}
export type TZipcodes = IZipcodes;

export interface IZipcode {
    city: string;
    coordinate: TCoordinate;
    county: string;
    zipcode: string;
}
export type TZipcode = IZipcode;


import { Component, OnInit, Injector } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';

import { AddressBasePage } from '@shared/pagesCommon/index';
import { RadioModalPage } from '@shared/modalsCommon/index';
import { AddressVerificationService } from '@shared/servicesCommon/addressVerification.service';
import { FirebaseFunctionsService } from '@shared/servicesCommon/firebaseFunctions.service';
import { ServiceAreasService } from '@shared/servicesCommon/serviceAreas.service';
import { TLocation, TAddress, TServiceArea } from '@shared/models/index';

@Component({
    'selector': 'app-addressModal',
    'templateUrl': './addressModal.page.html',
    'styleUrls': ['./addressModal.page.scss'],
})
export class AddressModalPage extends AddressBasePage implements OnInit {
    public location: TLocation;
    public address: TAddress;
    public title: string;
    public addressVerified = false;
    private zipsCities = [];
    private zipcodePromptSelectedZipCity: string;

    // private utilsService: UtilsService, private uxHelperService: UxHelperService, private modalController: ModalController, 
    constructor(private navParams: NavParams, private firebaseFunctionsService: FirebaseFunctionsService,
                private addressVerificationService: AddressVerificationService, 
                private serviceAreasService: ServiceAreasService, injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.title = this.navParams.get('title');
        this.location = this.navParams.get('location');
        this.address = this.location.address;
        this.loadServiceAreas();
    }

    private async loadServiceAreas() {
        const serviceAreas: any = await this.serviceAreasService.getAll();
        serviceAreas.forEach((area) => {
            const line1 = area.zipcode + ' - ' + area.city;
            const line2 = area.distance + ' miles away';
            this.zipsCities.push(
                {
                    'line1': line1,
                    'line2': line2,
                    'name': line1 + '[' + line2 + ']',
                    'zipcode': area.zipcode,
                    'city': area.city,
                    'distance': area.distance,
                    'selected': false
                }
            );
        });
    }

    public async onVerifyAddressClick() {
        try {
            this.addressVerified = false;
            await this.uxHelperService.showSpinner('Verifing address.');

            const validation = await this.addressVerificationService.verifyAddress(this.address);
            if (validation.found) {
                this.addressVerified = true;
                this.address.streetAddress = validation.foundAddress;
                this.address.fullAddress = validation.location.formattedAddress;
                this.address.coordinate = validation.location.coordinate;
            }
            await this.uxHelperService.hideSpinner();
            await this.potentiallyUseFoundInfo(validation, this.address);
        } catch (err) {
            await this.uxHelperService.hideSpinner();
            this.uxHelperService.showAlert('Address Not Found', err.message);
        }
    }

    public async onZipcodeChange() {
        this.addressVerified = false;
        let zipcode = this.address.zipcode;
        zipcode = zipcode?.toString() || '';

        if (zipcode.length === 5) {
            try {
                await this.uxHelperService.showSpinner('Loading data.');
                const serviceArea: TServiceArea = await this.serviceAreasService.getByUid(zipcode);
                await this.uxHelperService.hideSpinner();

                if (serviceArea) {
                    this.address.city = serviceArea.city;
                } else {
                    this.address.city = '';
                    this.uxHelperService.showAlert('Error', `${zipcode}: is not one of our service areas.`);
                }
            } catch (err) {
                await this.uxHelperService.hideSpinner();
                this.uxHelperService.showAlert('Error', err);
            }
        }
    }

    public onAddressChange() {
        this.addressVerified = false;
    }

    public openZipcodePromptClick() {
        const promise = new Promise(async (resolve, reject) => {
            const items2 = this.zipsCities.map(item => item.line1);

            const options: ModalOptions = {
                'component': RadioModalPage,
                'componentProps': { 'items': items2, 'selectedItem': this.zipcodePromptSelectedZipCity, 'title': 'Select City and Zipcode' },
                'backdropDismiss': false,
                'showBackdrop': true,
                'cssClass': 'addressRadioModal'
            };

            const myModal = await this.modalController.create(options);
            await myModal.present();
            const response: any = await myModal.onDidDismiss();
            if (response.data.okay) {
                this.zipcodePromptSelectedZipCity = response.data.selectedItem;
                const arr = this.zipcodePromptSelectedZipCity.split(' - ');
                const zip = arr[0];
                const city = arr[1];
                this.address.zipcode = zip;
                this.address.city = city;
                // We have a new zipcode and city, the address needs to be reverified.
                this.addressVerified = false;
            }
        });
        return promise;
    }

    public async onOkayClick() {
        try {
            await this.uxHelperService.showSpinner('Verifing address.');
            this.address.fullAddress = this.utilsService.formatAddressForGoogleMaps(
                this.address.streetAddress, this.address.city, this.address.state,
                this.address.zipcode, this.address.countryName);

            let response: any = await this.firebaseFunctionsService.convertStreetAddressToLatLng(
                            this.address.fullAddress);
            const result = response.results[0];
            this.address.fullAddress = result.formatted_address;
            this.address.coordinate.latitude = result.geometry.location.lat;
            this.address.coordinate.longitude = result.geometry.location.lng;

            response = { 'okay': true, 'location': this.address };
            this.modalController.dismiss(response);
        } catch (err) {
            this.uxHelperService.showAlert('Error', err.message);
        } finally {
            await this.uxHelperService.hideSpinner();
        }
    }

    public onCancelClick() {
        const response = { 'okay': false, 'location': null };
        this.modalController.dismiss(response);
    }
}

<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onCancelClick()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button color="primary" expand="block" (click)="onSelectNoneClick()">Select None</ion-button>
            </ion-col>
            <ion-col>
                <ion-button color="primary" expand="block" (click)="onSelectAllClick()">Select All</ion-button>
            </ion-col>
            <ion-col *ngIf="showTestCustomerButton===true">
                <ion-button color="primary" expand="block" (click)="onSelectManagersClick()">Select Managers</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-list>
        <ion-item *ngFor="let selectable of selectables">
            <ion-label class="ion-text-wrap">
                <span class="line1">{{selectable.line1}}</span><br />
                <span class="line1">{{selectable.line2}}</span>
            </ion-label>
            <ion-checkbox [(ngModel)]="selectable.selected"></ion-checkbox>
        </ion-item>
    </ion-list>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button color="primary" expand="block" (click)="onCancelClick()">Cancel</ion-button>
                </ion-col>
                <ion-col>
                    <ion-button color="primary" expand="block" (click)="onOkayClick()">Okay</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
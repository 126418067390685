import { Injectable } from '@angular/core';
// import { Platform } from '@ionic/angular';

import { Constants, TStateInfo, TCoordinate, TDistance, TDomainInfo, TSpeed, TRole } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class UtilsService {

    // private platform: Platform
    constructor() {
    }

    // Moved back to mapHelper
    // public onDevice(): boolean {
    //     const onDevice: boolean = this.platform.is('cordova');
    //     return onDevice;
    // }

    public countCh(str: string, ch: string) {
        let result = 0;
        for (let i = 0; i < str.length; i++) {
            if (str[i] === ch) {
                result++;
            }
        }
        return result;
    }

    public sprintf(format: string, ...args: any) {
        let i = 0;
        return format.replace(/%s/g, () => args[i++]);
    }

    public padWithLeadingZeros(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    public replaceAll(src: string, search: string, replaceWith: string): string {
        if (!src || !search || !replaceWith) {
            return src;
        }
        const newSrc = src.split(search).join(replaceWith);
        return newSrc;
    }

    public replaceCh(src: string, search: string, replaceWith: string): string {
        if (!src || !search) {
            return src;
        }
        let newSrc = src;
        const invalidChars = search.split('');
        invalidChars.forEach(ch => {
            newSrc = newSrc.replace(ch, replaceWith);
        })
        return newSrc;
    }

    public allEqual(input: string): boolean {
        if (!input) {
            return false;
        }
        const chars = input.split('');
        const allCharsEqual = chars.every(char => char === input[0]);
        return allCharsEqual;
    }

    // Creates a deep copy - a totally different and distinct object from the incoming obj.
    public deepClone(obj: any) {
        const deepClone = JSON.parse(JSON.stringify(obj));
        return deepClone;
    }

    public getStateInfo(stateOrStateCode: string): TStateInfo {
        let stateInfo: TStateInfo;

        if (stateOrStateCode.length === 2) {
            const infos: any = Constants.US_STATES.filter((item: TStateInfo) => {
                return (item.statecode === stateOrStateCode);
            });
            stateInfo = infos[0];
        } else {
            const infos: any = Constants.US_STATES.filter((item: TStateInfo) => {
                return (item.state === stateOrStateCode);
            });
            stateInfo = infos[0];
        }
        return stateInfo;
    }

    // public getRandomInt(min, max) {
    //     const num = Math.floor(Math.random() * (max - min + 1)) + min;
    //     return num;
    // }

    public isBlank(val: string) {
        if (!val) {
            return true;
        }
        const str = val.toString();
        const hasValue = (str && str.trim().length > 0);
        return !hasValue;
    }

    // This method has not been debugged.
    public toTitleCase(sentence: string) {
        const words = sentence.split(' ');
        const newWords = words.map((word) => {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        });
        const newSentence = newWords.join(' ');
        return newSentence;
    }

    public formatAddressForGoogleMaps(street: string, city: string, state: string, zipcode: string, country: string) {
        const part1 = street;
        const part2 = city + ' ' + state;
        const part3 = zipcode;
        let part4 = country;

        // When displaying a long address on the phone, the address is being truncated.
        // At this point, I don't really need the USA/part4.  So leave it off.
        //        var fullAddress = part1 + ', ' + part2 + ', ' + part3;  // + ', ' + part4;
        let fullAddress = '';
        fullAddress += (part1) ? part1 + ', ' : '';
        fullAddress += (part2) ? part2 + ', ' : '';
        fullAddress += (part3) ? part3 + ', ' : '';
        fullAddress += (part4) ? part4 : '';
        return fullAddress;
    }

    public sleep (ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
      
    // public async timeoutHandler() {
    //     await this.sleep(1)
    //     // heavy duty code here
    // }
      
    // // setTimeout(timeoutHandler, 10000)


    // Incoming phone number: 12135551234
    // return phone number: (xxx) xxx-1234
    public maskPhoneNumber(phoneNumber: string): string {
        if (!phoneNumber) {
            return;
        }
        const pn = this.unformatPhoneNumber(phoneNumber);
        // if (pn.length !== 10 && pn.length !== 11) {
        //     return '';
        // }

        if (pn.length === 10) {
            const last = pn.slice(6);
            const maskedPhoneNumber = '(xxx) xxx-' + last;
            return maskedPhoneNumber;
        }
        if (pn.length === 11) {
            const last = pn.slice(7);
            const maskedPhoneNumber = '(xxx) xxx-' + last;
            return maskedPhoneNumber;
        }
    }

    public formatCoordinateForDisplay(coordinate: TCoordinate) {
        const lat  = this.round(coordinate.latitude, 6);
        const lng = this.round(coordinate.longitude, 6);
        const str = `(${lat}, ${lng})`;
        return str;
    }

    public formatPhoneNumberForDialer(phoneNumber: string) {
        if (!phoneNumber) {
            return null;
        }

        if (phoneNumber.indexOf('tel:') === -1) {
            const prefix = 'tel:+';
            const countryCode = '1';
            const formattedPhoneNumber = prefix + countryCode + phoneNumber;
            return formattedPhoneNumber;
        } else {
            return phoneNumber;
        }
    }

    public formatPhoneNumberForDisplay(phoneNumber: string) {
        if (!phoneNumber) {
            return null;
        }

        phoneNumber = this.unformatPhoneNumber(phoneNumber);
        const areacode = phoneNumber.substring(0, 3);
        const prefix = phoneNumber.substring(3, 6);
        const final = phoneNumber.substring(6);
        const formatted = '(' + areacode + ') ' + prefix + '-' + final;
        return formatted;
    }

    // Remove these characters:  -+() and spaces
    public unformatPhoneNumber(phoneNumber: string) {
        if (!phoneNumber) {
            return null;
        }
        phoneNumber = phoneNumber.replace(/[-+()\s]/g, '');
        return phoneNumber;
    }

    public compareArrays(arr1: any[], arr2: any[]): boolean {
        const equals = (a, b) => (a.length === b.length) && (a.every((v, i) => v === b[i]));

        const isEqual = equals(arr1, arr2);
        return isEqual;;
    }

    public getDomainInfo(): TDomainInfo {
        const domainInfo: any = {};
        let domain = '';
        const location = window.location;
        const hostname = location.hostname.toLowerCase();

        // if (hostname === '127.0.0.1' || hostname.includes('localhost')) {
        //     domain = 'localhost';
        // } else if (hostname.includes('staging')) {
        //     domain = 'staging';
        // } else if (hostname.includes('app')) {
        //     domain = 'app';
        // }  else if (hostname.includes('backend')) {
        //     domain = 'backend';
        // }

        if (hostname.includes('backend')) {
            domain = 'backend';
        } else if (hostname.includes('app')) {
            domain = 'app';
        } else {
            domain = 'staging';
        }

        domainInfo.domain = domain;
        domainInfo.domainWithPort = location.host;
        domainInfo.origin = location.origin;

        // 
        if (location.origin.includes('localhost')) {
            domainInfo.properOrigin = 'https://staging.shuttlenomix.com'
        } else {
            domainInfo.properOrigin = location.origin;
        }

        // console.log('domainInfo = ', JSON.stringify(domainInfo));
        return domainInfo;
    }

    private getDomain() {
        let domain: string;
        const location = window.location;
        const hostname = location.hostname.toLowerCase();

        if (hostname.includes('localhost')) {
            // domain = 'localhost';
            domain = 'staging';
        } else if (hostname.includes('staging')) {
            domain = 'staging';
        } else if (hostname.includes('app')) {
            domain = 'app';
        }  else if (hostname.includes('backend')) {
            domain = 'backend';
        }

        return domain;
    }

    public getLogRocketAppID(): string {
        let appID: string;
        const domain = this.getDomain();
        switch (domain) {
            case 'localhost':
                appID = Constants.LOGROCKET_APPID_LOCALHOST;
                break;

            case 'staging':
                appID = Constants.LOGROCKET_APPID_STAGING;
                break;

            case 'app':
                appID = Constants.LOGROCKET_APPID_APP;
                break;

            case 'backend':
                appID = Constants.LOGROCKET_APPID_BACKEND;
                break;
        }
        return appID;
    }

    public buildQueryString(params: any) {
        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return query;
    }

// --------------------------------------------------------------------------
//  This routine calculates the distance between two points (given the
//  latitude/longitude of those points). It is being used to calculate
//  the distance between two locations using GeoDataSource (TM) products
//
//  Definitions:
//    South latitudes are negative, east longitudes are positive
//
//  Passed to function:
//    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)
//    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)
//    unit = the unit you desire for results
//           where: 'M' is statute miles (default)
//                  'K' is kilometers
//                  'N' is nautical miles
//
//  Worldwide cities and other features databases with latitude longitude
//  are available at https://www.geodatasource.com
//
//  For enquiries, please contact sales@geodatasource.com
//
//  Official Web site: https://www.geodatasource.com
//
//               GeoDataSource.com (C) All Rights Reserved 2018
//
// --------------------------------------------------------------------------
    // public calculateDistance(coordinate1: TCoordinate, coordinate2: TCoordinate, unit: string = 'M'): number {
    //     const lat1 = coordinate1.latitude;
    //     const lon1 = coordinate1.longitude;
    //     const lat2 = coordinate2.latitude;
    //     const lon2 = coordinate2.longitude;

    //     if ((lat1 === lat2) && (lon1 === lon2)) {
    //         return 0;
    //     } else {
    //         const radlat1 = Math.PI * lat1 / 180;
    //         const radlat2 = Math.PI * lat2 / 180;
    //         const theta = lon1 - lon2;
    //         const radtheta = Math.PI * theta / 180;
    //         let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    //         if (dist > 1) {
    //             dist = 1;
    //         }
    //         dist = Math.acos(dist);
    //         dist = dist * 180 / Math.PI;
    //         // dist = dist * 60 * 1.1515;
    //         if (unit === 'K') { dist = dist * 1.609344; }
    //         if (unit === 'N') { dist = dist * 0.8684;   }

    //         return dist;
    //     }
    // }


    private toRadians(value: number): number {
        return value * Math.PI / 180;
    }

    // This formula was taken from: www.movable-type.co.uk/scripts/latlong.html
    // As the Crow flies - Calculation in due consideration of Earth curvation
    public calculateDistance(coordinate1: TCoordinate, coordinate2: TCoordinate): TDistance {
        const maxDistance = { 'miles': Number.MAX_SAFE_INTEGER, 'kilometers': Number.MAX_SAFE_INTEGER };

        if (!coordinate1 || !coordinate1.latitude || !coordinate1.longitude ||
            !coordinate2 || !coordinate2.latitude || !coordinate2.longitude) {
            return maxDistance;
        }
        // if (coordinate1.latitude == 0 || coordinate1.longitude == 0 ||
        // 	coordinate2.latitude == 0 || coordinate2.longitude == 0) {
        // 	return null;
        // }

        const R = 6371e3;		// Radius of earth in meters;

        // When I figure out how to add toRadians to the Number type without typescript complaining
        // then put this back.
        // let φ1 = coordinate1.latitude.toRadians();
        // let λ1 = coordinate1.longitude.toRadians();
        // let φ2 = coordinate2.latitude.toRadians();
        // let λ2 = coordinate2.longitude.toRadians();
        const φ1 = this.toRadians(coordinate1.latitude);
        const λ1 = this.toRadians(coordinate1.longitude);
        const φ2 = this.toRadians(coordinate2.latitude);
        const λ2 = this.toRadians(coordinate2.longitude);

        const Δφ = φ2 - φ1;
        const Δλ = λ2 - λ1;

        const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2)) + ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;

        const kilometers = d / 1000;
        const miles = kilometers * 0.621371;

        const kilometersRounded = Math.round(kilometers * 100) / 100;
        const milesRounded = Math.round(miles * 100) / 100;
        const obj = { 'kilometers': kilometersRounded, 'miles': milesRounded };

        return obj;
    }

    public calculateSpeed(coordinate1: TCoordinate, coordinate2: TCoordinate, secs: number) {
        const nullcoordinate = { 'latitude': 0, 'longitude': 0 };
        const nullSpeed: TSpeed = { 'mph': 0, 'kph': 0 };

        if ((coordinate1 === nullcoordinate) || (coordinate2 === nullcoordinate)) {
            return nullSpeed;
        }

        const dist = this.calculateDistance(coordinate1, coordinate2);
        const mph = (dist.miles / secs) * 3600;
        const kph = (dist.kilometers / secs) * 3600;

        const speed: TSpeed = {
            'mph': this.round(mph, 2),
            'kph': this.round(kph, 2)
        };
        return speed;
    }

    public calculateSpeedFromMetersPerSecond(metersPerSecond: number): TSpeed {
        const nullSpeed: TSpeed = { 'mph': 0, 'kph': 0 };
        const secondsPerHour: number = 3600;
        const metersPerMile: number = 1609.34;
        const metersPerKilometer: number = 1000;

        if ((metersPerSecond < 1)) {
            return nullSpeed;
        }

        const kph = (metersPerSecond / metersPerKilometer) * secondsPerHour;
        const mph = (kph * metersPerKilometer) / metersPerMile;

        const speed: TSpeed = {
            'mph': this.round(mph, 2),
            'kph': this.round(kph, 2)
        };
        return speed;
    }

    // round(12345.6789, 2)   // 12345.68
    // round(12345.6789, 1)   // 12345.7
    public round(value: number, numDecimalPlaces: number) {
        const multiplier = Math.pow(10, numDecimalPlaces || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    /**
     * This function allow you to modify a JS Promise by adding some status properties.
     * Based on: http://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
     * But modified according to the specs of promises : https://promisesaplus.com/

     Usage:
     // Your promise won't cast the .then function but the returned by MakeQuerablePromise
        var originalPromise = new Promise(function(resolve,reject){
            setTimeout(function(){
                resolve("Yeah !");
            },10000);
        });

        var myPromise = MakeQuerablePromise(originalPromise);

        console .log("Initial fulfilled:", myPromise.isFulfilled());//false
        console .log("Initial rejected:", myPromise.isRejected());//false
        console .log("Initial pending:", myPromise.isPending());//true

        myPromise.then(function(data){
            console .log(data); // "Yeah !"
            console .log("Final fulfilled:", myPromise.isFulfilled());//true
            console .log("Final rejected:", myPromise.isRejected());//false
            console .log("Final pending:", myPromise.isPending());//false
        });
     */
    public MakeQuerablePromise(promise: any) {
        // Don't modify any promise that has already been resolved.
        if (promise.isFulfilled) {
            return promise;
        }

        // Set initial state
        let isPending = true;
        let isRejected = false;
        let isFulfilled = false;

        // Observe the promise, saving the fulfillment in a closure scope.
        const result = promise.then(
            function(v: any) {
                isFulfilled = true;
                isPending = false;
                return v;
            },
            function(e: any) {
                isRejected = true;
                isPending = false;
                throw e;
            }
        );

        result.isFulfilled = function() { return isFulfilled; };
        result.isPending = function() { return isPending; };
        result.isRejected = function() { return isRejected; };
        return result;
    }

        // Make certain the incoming email address is of the correct form.
    public validateEmailAddress(email): boolean {
        // According to this stackoverflow article,
        // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
        // it says 1) Dont' try to validate an email address with regular expressions, cuz their are always wrong
        // 2) the only way to guarantee a valid email address is to send them an email and have them respond.
        // My first version was a regex and I couldn't understand it.  So I chose a brute force version.
        // A valid email address is of this form:  namePart@domainPart.extPart
        // let email9 = '';
        // const ampCount1 = this.utilsService.countCh(email9, '@');

        // email9 = 'D';
        // const ampCount2 = this.utilsService.countCh(email9, '@');

        // email9 = 'D@asdf';
        // const ampCount3 = this.utilsService.countCh(email9, '@');

        // email9 = 'Dsdf@dfgb@';
        // const ampCount4 = this.utilsService.countCh(email9, '@');

        if (!email) {
            return false;
        }

        // const ampCount = email.match(/@/g).length;
        const ampCount = this.countCh(email, '@');
        if (ampCount === 0 || ampCount > 1) {
            return false;
        }

        let parts = email.split('@');
        // if (parts.length !== 2) {
        if (parts[1].length === 0) {
            return false;
        }

        const namePart = parts[0];
        parts = parts[1].split('.');
        const domainPart = parts[0];
        const extPart = parts[1];

        if (!extPart) {
            return false;
        }

        const valid = (namePart.length > 0 && domainPart.length > 0 && extPart.length > 0);
        return valid;

        // const format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
        //     (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
        //     (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        // const re = new RegExp(format);
        // return re.test(email);
    }

    // Validate the incoming email and password.
    // public validateLoginCredentials(email, password) {
    //     let success = this.utilsService.validateEmailAddress(email);
    //     if (success) {
    //         success = this.validatePassword(password);
    //     }
    //     return success;
    // }

    // Firebase's requirement is that passwords are at least 6 chars.
    public validatePassword(password) {
        const valid = (password && password.length > 5);
        return valid; 
    }

    public findUndefinedProperty(obj: any) {
        // This solution is for when you want to use 
        // `break`, `return` which forEach doesn't support
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // your logic here
            }
        }
    }

    public isAlphaNumeric(str: string) {
        let code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
          code = str.charCodeAt(i);
          if (!(code > 47 && code < 58) &&      // numeric (0-9)
              !(code > 64 && code < 91) &&      // upper alpha (A-Z)
              !(code > 96 && code < 123)) {     // lower alpha (a-z)
            return false;
          }
        }
        return true;
      }

    public sort(arr: any[], sortColumn: string, sortAscending: boolean = true): any {
        if (arr.length < 2) {
            return arr;
        }

        const sortedArray = arr.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) {
                // return -1;
                return (sortAscending ? -1 : 1);
            } else if (a[sortColumn] > b[sortColumn]) {
                // return 1;
                return (sortAscending ? 1 : -1);
            } else {
                return 0;
            }
        });
        return sortedArray;
    }

    public formatAsCurrency(dollars: number): string {
        let retval = '';

        switch (typeof dollars) {
            // case 'string':
            case 'undefined':
                break;

            case 'number':
                const options = {
                    'style': 'currency',
                    'currency': 'USD',
                    // These options are needed to round to whole numbers if that's what you want.
                    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                };
                const formatter = new Intl.NumberFormat('en-US', options);
                retval = formatter.format(dollars);
                break;
        }

        return retval;
        // if (typeof dollars === 'number') {
        //     var formatter = new Intl.NumberFormat('en-US', {
        //         'style': 'currency',
        //         'currency': 'USD',
        //         // These options are needed to round to whole numbers if that's what you want.
        //         //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //         //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        //     });
            
        //     const currency = formatter.format(dollars); /* $2,500.00 */
        //     return currency;
        // } else {
        //     return dollars;
        // }
    }

    // Usage:
    // useSingularOrPlural(1, 'shuttle defined.', 'shuttles defined.');    // 1 shuttle defined. 
    // useSingularOrPlural(2, 'shuttle defined.', 'shuttles defined.');    // 2 shuttles defined. 
    public useSingularOrPlural(num: number, singular: string, plural: string): string {
        let text = num.toString() + ' ';
        text += (num === 1) ? singular : plural;

        return text;
    }

    // const queryString = "https://us-central1-shuttlenomix.cloudfunctions.net/smsResponseWebhook?accountNumber=00000-0000-00000&smsType=allAboard&customerName=Michael%20Carr&countryCode=%2B1";
    // const parts = this.parseQueryString(queryString);
    // parts.url = https://us-central1-shuttlenomix.cloudfunctions.net/smsResponseWebhook
    // parts.accountNumber = 00000-0000-00000
    // parts.smsType = allAboard
    // parts.contactName = Michael Carr
    // parts.countryCode = +1
    public parseQueryString(queryString: string = ''): any {
        if (!queryString) {
            queryString = window.location.search;
        }

        const query = queryString.split('?');
        const parms = query[1].split('&');

        let parts = {
            'url': query[0],
        };
        for (var i = 0; i < parms.length; ++i)
        {
            var p = parms[i].split('=', 2);
            if (p.length == 1)
                parts[p[0]] = "";
            else
                parts[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }

        delete parts['url'];
        return parts;

        // var qs = (function(a) {
        //     if (a == "") return {};
        //     var b = {};
        //     for (var i = 0; i < a.length; ++i)
        //     {
        //         var p=a[i].split('=', 2);
        //         if (p.length == 1)
        //             b[p[0]] = "";
        //         else
        //             b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        //     }
        //     return b;
        // })(window.location.search.substr(1).split('&'));
    }

    public shuffle(array: any[]) {
        let randomIndex: number;
        let currentIndex: number = array.length;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }
    
    public getRoleTitle(role: TRole): string {
        let title: string;

        switch (role) {
            case 'driver':
                title = 'Driver';
                break;

            case 'manager':
                title = 'Manager';
                break;

            case 'serviceAdvisor':
                title = 'Service Advisor';
                break;

            case 'accountant':
                title = 'Accountant';
                break;

            default:
                title = 'undefined';
                break;
        }
        return title;
    }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CollectionService } from '@shared/servicesCommon/collection.service';
import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { UtilsService } from '@shared/servicesCommon/utils.service';
import { TServiceArea, TDistance } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class ServiceAreasService {
    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService,
                private utilsService: UtilsService) {
    }

    public getObservable(): Observable<TServiceArea[]> {
        const collection = this.collectionService.getServiceAreasCol();
        const observable = collection.valueChanges();
        return observable;
    }

    // Return the promise of the object beneath the key value.
    public getByUid(uid: string): Promise<TServiceArea> {
        const collection = this.collectionService.getServiceAreasCol();
        return this.firestoreService.getByUid(collection, uid);
    }

    public getByFieldValue(fieldName, fieldValue): Promise<TServiceArea[]> {
        const collection = this.collectionService.getServiceAreasCol();
        return this.firestoreService.getByFieldValue(collection, fieldName, fieldValue);
    }

    public getAll(): Promise<TServiceArea[]> {
        const collection = this.collectionService.getServiceAreasCol();
        return this.firestoreService.getAll(collection);
    }

    public async set(area: TServiceArea): Promise<TServiceArea> {
        area.uid = area.zipcode;
        const collection = this.collectionService.getServiceAreasCol();
        return this.firestoreService.set(collection, area);
    }

    public update(area: TServiceArea): Promise<TServiceArea> {
        const collection = this.collectionService.getServiceAreasCol();
        return this.firestoreService.update(collection, area);
    }

    public delete(uid: string): Promise<void> {
        const collection = this.collectionService.getServiceAreasCol();
        return this.firestoreService.delete(collection, uid);
    }
}

import { Injectable } from '@angular/core';

@Injectable({
    'providedIn': 'root'
})
export class KeyValuePairService {
    private keyValuePairs = new Map<string, any>();

    constructor() {
    }

    public getValue(key: string): any {
        return this.keyValuePairs[key];
    }

    public setValue(key: string, value?: any): void {
        if (!value) {
            value = '';
        }
        this.keyValuePairs[key] = value;
    }

        // let key = '[CurrentName]';    
        // console.log(this.variableValuePairs[key]);
        // this.variableValuePairs[key] = 'Carr';
        // console.log(this.variableValuePairs[key]);        

}
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TAccount, TCampaign, TCampaignIncentive, TCampaignIncentiveSet, TIsoCountry, TCustomer,
     TError, TPickupRequest, TPickupResponse, TRoute, TServiceArea, TSetting, TShuttle, TSmsFromNumber,
     TSmsSentMessage, TSummary, TTicket, TTrip, TUser, TUserAccountMap, TVector, TZipcode, TIsoTimezones } 
from '@shared/models/interfaces';

@Injectable({
    'providedIn': 'root'
})
export class CollectionService {
    private accountNumber: string;

    // These collections need to be defined early, that is before a user logs in
    private backupFilesNamesCol: AngularFirestoreCollection;
    // private countriesCol: AngularFirestoreCollection<TIsoCountry>;
    private documentsCol: AngularFirestoreCollection;
    private errorsCol: AngularFirestoreCollection<TError>;
    private firebaseFunctionsCol: AngularFirestoreCollection<TAccount>;

    private isoCountriesCol: AngularFirestoreCollection;    // <TIsoCountries>;
    private isoTimezonesCol: AngularFirestoreCollection<TIsoTimezones>;
    
    private pricesCol: AngularFirestoreCollection;
    private skuActionsCol: AngularFirestoreCollection;
    private tasksCol: AngularFirestoreCollection;
    private userAccountMapsCol: AngularFirestoreCollection<TUser>;

    // The following references will be defined when a user logs in and we have an account number.
    private accountsCol: AngularFirestoreCollection<TAccount>;
    private accountNumberDoc: AngularFirestoreDocument;

    // private accountProfilesCol: AngularFirestoreCollection;
    private accountNumberCol: any;
    private campaignsCol: AngularFirestoreCollection<TCampaign>;
    private campaignIncentivesCol: AngularFirestoreCollection<TCampaignIncentive>;
    private campaignIncentiveSetsCol: AngularFirestoreCollection<TCampaignIncentiveSet>;
    private customersCol: AngularFirestoreCollection<TCustomer>;
    private pickupRequestsCol: AngularFirestoreCollection<TPickupRequest>;
    private pickupResponsesCol: AngularFirestoreCollection<TPickupResponse>;
    private profilesCol: AngularFirestoreCollection;
    private routesCol: AngularFirestoreCollection<TRoute>;
    private serviceAreasCol: AngularFirestoreCollection<TServiceArea>;
    private settingsCol: AngularFirestoreCollection<TSetting>;
    private shuttlesCol: AngularFirestoreCollection<TShuttle>;
    private smsFromNumbersCol: AngularFirestoreCollection<TSmsFromNumber>;
    private smsSentMessagesCol: AngularFirestoreCollection<TSmsSentMessage>;
    private smsSentMessagesAllCol: AngularFirestoreCollection;
    private smsSentMessagesCategoriesCol: AngularFirestoreCollection;
    private summariesCol: AngularFirestoreCollection<TSummary>;
    private ticketsCol: AngularFirestoreCollection<TTicket>;
    private tripsCol: AngularFirestoreCollection<TTrip>;
    private usersCol: AngularFirestoreCollection<TUser>;
    private vectorsCol: AngularFirestoreCollection<TVector>;
    private zipcodesCol: AngularFirestoreCollection<TZipcode>;
    
    // private ordersCol: AngularFirestoreCollection;
    // private sessionsCol: AngularFirestoreCollection;
    // private videosCol: AngularFirestoreCollection;
    // private zipcodes2Col: AngularFirestoreCollection;
    // private internetConnectedCol: firebase.default.database.Reference;
    // private netPromoterScoresCol: AngularFirestoreCollection;


    // private firebase: AngularFireDatabase, 
    constructor(private firestore: AngularFirestore) {
        // this.internetConnectedCol = this.firebase.database.ref('.info/connected');

     // this.accountsCol = this.firestore.collection<TAccount>('accounts');
        this.accountsCol = this.firestore.collection('accounts');
        // this.countriesCol = this.firestore.collection('countries');
        this.documentsCol = this.firestore.collection('docs');
        this.errorsCol = this.firestore.collection('errors');
        this.firebaseFunctionsCol = this.firestore.collection('firebaseFunctions');
        this.isoCountriesCol = this.firestore.collection('isoCountries');
        this.isoTimezonesCol = this.firestore.collection('isoTimezones');
        this.smsFromNumbersCol = this.firestore.collection('smsFromNumbers');
        this.pricesCol = this.firestore.collection('prices');
        this.skuActionsCol = this.firestore.collection('skuActions');
        this.tasksCol = this.firestore.collection('tasksQueue');
        this.userAccountMapsCol = this.firestore.collection('userAccountMaps');
        this.zipcodesCol = this.firestore.collection('zipcodes');

        // this.videosCol = this.firestore.collection('videos');
        // this.zipcodes2Col = this.firestore.collection('zipcodes2');
    }

    // These references need to be defined after a user logs in - they are account number dependent
    public buildCollections(accountNumber: string) {
        this.accountNumber = accountNumber;

        this.accountNumberDoc         = this.accountsCol.doc(accountNumber);
        // this.accountProfilesCol    = this.accountNumberDoc.collection('accountProfiles');
        this.campaignsCol             = this.accountNumberDoc.collection<TCampaign>('campaigns');
        this.campaignIncentivesCol    = this.accountNumberDoc.collection<TCampaignIncentive>('incentives');
        this.campaignIncentiveSetsCol = this.accountNumberDoc.collection<TCampaignIncentiveSet>('incentiveSets');
        this.customersCol             = this.accountNumberDoc.collection<TCustomer>('customers');
        this.routesCol                = this.accountNumberDoc.collection<TRoute>('routes');
        this.pickupRequestsCol        = this.accountNumberDoc.collection<TPickupRequest>('pickupRequests');
        this.pickupResponsesCol       = this.accountNumberDoc.collection<TPickupResponse>('pickupResponses');
        this.profilesCol              = this.accountNumberDoc.collection('profiles');
        this.serviceAreasCol          = this.accountNumberDoc.collection<TServiceArea>('serviceAreas');
        this.settingsCol              = this.accountNumberDoc.collection<TSetting>('settings');
        this.shuttlesCol              = this.accountNumberDoc.collection<TShuttle>('shuttles');
        this.smsSentMessagesCol       = this.accountNumberDoc.collection<TSmsSentMessage>('smsSentMessages');
        this.summariesCol             = this.accountNumberDoc.collection<TSummary>('summaries');
        this.ticketsCol               = this.accountNumberDoc.collection<TTicket>('tickets');
        this.tripsCol                 = this.accountNumberDoc.collection<TTrip>('trips');
        this.usersCol                 = this.accountNumberDoc.collection<TUser>('users');
        this.vectorsCol               = this.accountNumberDoc.collection<TVector>('vectors');
        
        // this.ordersCol                = this.accountNumberDoc.collection('orders');
        // this.netPromoterScoresCol     = this.accountNumberDoc.collection('netPromoterScores');
        // this.sessionsCol              = this.accountNumberDoc.collection('sessions');
     // this.accountsCol = this.firestore.collection<TAccount>('accounts');

    }

    public buildCollectionsForAnonymousUsers(accountNumber: string): void {
        this.accountNumber = accountNumber;

        this.accountNumberDoc      = this.accountsCol.doc(accountNumber);
        this.vectorsCol            = this.accountNumberDoc.collection('vectors');
    } 

    public getAccountChildCol(accountNumber, childNodeName) {
        return this.buildCollection(['accounts', accountNumber, childNodeName]);
    }

    public setAccountNumber(accountNumber: string) {
        this.buildCollections(accountNumber);
    }

    public getAccountsCol(): AngularFirestoreCollection<TAccount> {
        return this.accountsCol;
    }

    // public getAccountNumberCol() {
    //     return this.accountNumberCol;
    // }

    // public getAccountProfilesCol(): AngularFirestoreCollection {
    //     return this.accountProfilesCol;
    // }

    public getBackupFileNamesCol(): AngularFirestoreCollection {
        return this.backupFilesNamesCol;
    }

    public getCampaignsCol(): AngularFirestoreCollection<TCampaign> {
        return this.campaignsCol;
    }

    public getCampaignIncentivesCol(): AngularFirestoreCollection<TCampaignIncentive> {
        return this.campaignIncentivesCol;
    }

    public getCampaignIncentiveSetsCol(): AngularFirestoreCollection<TCampaignIncentiveSet> {
        return this.campaignIncentiveSetsCol;
    }

    public getCampaignIncentivesColByAccountNumber(accountNumber: string) {
        // const collection = this.getAccountsCol();
        // const accountNumberDoc = collection.doc(accountNumber);
        // const incentivesCollection = accountNumberDoc.collection('incentives');
        // const accountProfileDoc = summariesCollection.doc('summary');
        // const ref = accountProfileDoc.ref;
        // return ref;

        const nodes = ['accounts', accountNumber, 'incentives'];
        return this.buildCollection(nodes);
    }

    // public getCountriesCol(): AngularFirestoreCollection<TIsoCountry> {
    //     return this.countriesCol;
    // }

    public getCustomersCol(): AngularFirestoreCollection<TCustomer> {
        return this.customersCol;
    }

    public getCustomerColByAccountNumber(accountNumber: string): AngularFirestoreCollection {
        const nodes = ['accounts', accountNumber, 'customers'];
        return this.buildCollection(nodes);
    }

    public getDocumentsCol(): AngularFirestoreCollection {
        return this.documentsCol;
    }

    public getFirebaseFunctionsCol(): AngularFirestoreCollection {
        return this.firebaseFunctionsCol;
    }

    public getErrorsCol(): AngularFirestoreCollection {
        return this.errorsCol;
    }

    public getIsoCountriesCol(): AngularFirestoreCollection {
        return this.isoCountriesCol;
    }

    public getIsoTimezonesCol(): AngularFirestoreCollection<TIsoTimezones> {
        return this.isoTimezonesCol;
    }

    // public getInternetConnectedCol() {
    //     return this.internetConnectedCol;
    // }

    public getNetPromoterScoresCol(): AngularFirestoreCollection {
        // return this.netPromoterScoresCol;
        return null;
    }

    // About to rename to getPickupRequestsCol()
    // public getRequestsCol(shuttleUid) {
    //     const nodes = ['accounts', '?', 'pickupRequests'];
    //     nodes.push(shuttleUid);
    //     return this.buildCollection(nodes);
    // }

    // // About to be renamed to getPickupResponsesCol()
    // public getResponsesCol(shuttleUid) {
    //     const nodes = ['accounts', '?', 'pickupResponses'];
    //     nodes.push(shuttleUid);
    //     return this.buildCollection(nodes);
    // }

    public getOrdersCol(): AngularFirestoreCollection {
        // return this.ordersCol;
        return null;
    }

    public getPickupRequestsCol(): AngularFirestoreCollection<TPickupRequest> {
        return this.pickupRequestsCol;
    }

    public getPickupResponsesCol(): AngularFirestoreCollection<TPickupResponse> {
        return this.pickupResponsesCol;
    }

    public getPricesCol(): AngularFirestoreCollection {
        return this.pricesCol;
    }

    public getProfilesCol(): AngularFirestoreCollection {
        return this.profilesCol;
    }

    public getRoutesCol(): AngularFirestoreCollection<TRoute> {
        return this.routesCol;
    }

    public getServiceAreasCol(): AngularFirestoreCollection<TServiceArea> {
        return this.serviceAreasCol;
    }

    // public getSessionsCol(): AngularFirestoreCollection {
    //     return this.sessionsCol;
    // }

    public getSettingsCol(): AngularFirestoreCollection<TSetting> {
        return this.settingsCol;
    }

    public getSettingsByAccountNumberRef(accountNumber: string) {
        // const nodes = ['accounts', accountNumber, 'settings', 'setting'];
        // const collection = this.buildCollection(nodes);
        // return collection.ref;

        const collection = this.getAccountsCol();
        const accountNumberDoc = collection.doc(accountNumber);
        const settingsCollection = accountNumberDoc.collection('settings');
        const settingDoc = settingsCollection.doc('setting');
        const ref = settingDoc.ref;
        return ref;
    }

    public getShuttlesCol(): AngularFirestoreCollection<TShuttle> {
        return this.shuttlesCol;
    }

    // public getSingletonColByAccountNumber(accountNumber: string): AngularFirestoreCollection {
    //     const nodes = ['accounts', accountNumber, 'singletons'];
    //     return this.buildCollection(nodes);
    // }

    public getSkuActionsCol(): AngularFirestoreCollection {
        return this.skuActionsCol;
    }

    public getSmsFromNumbersCol(): AngularFirestoreCollection<TSmsFromNumber> {
        return this.smsFromNumbersCol;
    }

    public getSmsSentMessagesCol(): AngularFirestoreCollection {
        return this.smsSentMessagesCol;
    }

    public getSmsSentMessagesAllRawCol(): AngularFirestoreCollection {
        const nodes = ['accounts', '?', 'smsSentMessages', 'all', 'raw'];
        const collection = this.buildCollection(nodes);
        return collection;
    }

    // public getSmsSentMessagesAllCol(): AngularFirestoreCollection {
    //     return this.smsSentMessagesAllCol;
    // }

    public getSmsSentMessagesCategoriesCol(category: string): AngularFirestoreCollection {
        const nodes = ['accounts', '?', 'smsSentMessages', 'categories', category];
        const collection = this.buildCollection(nodes);
        return collection;
        // return this.smsSentMessagesCategoriesCol;
    }

    public getSummaryByAccountNumberRef(accountNumber) {
        const collection = this.getAccountsCol();
        const accountNumberDoc = collection.doc(accountNumber);
        const summariesCollection = accountNumberDoc.collection('summaries');
        const accountProfileDoc = summariesCollection.doc('overallSummary');
        const ref = accountProfileDoc.ref;
        return ref;
    }

    public getSummariesCol(): AngularFirestoreCollection<TSummary> {
        return this.summariesCol;
    }

    public getTasksCol(): AngularFirestoreCollection {
        return this.tasksCol;
    }

    public getTicketsCol(): AngularFirestoreCollection<TTicket> {
        return this.ticketsCol;
    }

    public getTripCol(tripUid): AngularFirestoreCollection {
        const nodes = ['accounts', '?', 'trips'];
        nodes.push(tripUid);
        const collection = this.buildCollection(nodes);
        return collection;
    }

    public getTripsCol(): AngularFirestoreCollection<TTrip> {
        return this.tripsCol;
    }

    public getTripTicketsCol(tripUid): AngularFirestoreCollection {
        const nodes = ['accounts', '?', 'trips'];
        nodes.push(tripUid);
        nodes.push('tickets');
        const collection = this.buildCollection(nodes);
        return collection;
    }

    public getTripActualLocationCol(tripUid: string): AngularFirestoreCollection {
        const nodes = ['accounts', '?', 'trips'];
        nodes.push(tripUid);
        nodes.push('actualLocations');
        const collection = this.buildCollection(nodes);
        return collection;
    }    
    
    public getUsersCol(): AngularFirestoreCollection<TUser> {
        return this.usersCol;
    }    
    
    public getUserAccountMapsCol(): AngularFirestoreCollection<TUserAccountMap> {
        return this.userAccountMapsCol;
    }    

    public getVectorsCol(): AngularFirestoreCollection<TVector> {
        return this.vectorsCol;
    }    

    public getVideosCol(): AngularFirestoreCollection {
        // return this.videosCol;
        return null;
    }    

    public getZipcodesCol(): AngularFirestoreCollection<TZipcode> {
        return this.zipcodesCol;
    }    

    public getZipcodes2Col(): AngularFirestoreCollection {
        return this.zipcodesCol;
    }    

    public getProfileByAccountNumberRef(accountNumber: string) {
        // console.error('Delete this method. This buildCollections doesnt belong here.');

        const collection = this.getAccountsCol();
        const accountNumberDoc = collection.doc(accountNumber);
        const profilesCollection = accountNumberDoc.collection('accountProfiles');
        const accountProfileDoc = profilesCollection.doc('accountProfile');
        const ref = accountProfileDoc.ref;
        return ref;
    }

    public getUsersColByAccountNumber(accountNumber: string): AngularFirestoreCollection {
        const collection = this.getAccountsCol();
        const accountNumberDoc = collection.doc(accountNumber);
        const usersCollection = accountNumberDoc.collection('users');
        // const accountProfileDoc = usersCollection.doc('accountProfile');
        // const ref = accountProfileDoc.ref;
        return usersCollection;
    }

    public buildCollection(nodeNames) {
        let idx = nodeNames.indexOf('?');
        if (idx > -1) {
            nodeNames[idx] = this.accountNumber;
        }

        // let col = this.rootCol;

        let path = '';
        for (idx = 0; idx < nodeNames.length; idx++) {
            const nodeName = nodeNames[idx];
            path += nodeName + '/';     // col = col.child(nodeName);
        }

        return this.firestore.collection(path);
    }
}

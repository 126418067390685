import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    'selector': 'app-pickupRequestModal',
    'templateUrl': './pickupRequestModal.page.html',
    'styleUrls': ['./pickupRequestModal.page.scss'],
})
export class PickupRequestModalPage implements OnInit {
    public ticket: any = {};
    private intervalPromise: any;

    constructor(public modalController: ModalController, private navParams: NavParams) {
    }

    ngOnInit(): void {
        this.ticket = this.navParams.get('ticket');
        this.ticket.timeRemaining = 11;

        this.intervalPromise = setInterval(() => {
            if (--this.ticket.timeRemaining === 0) {
                this.onDeclineClick();
            }
        }, 1000);
    }

    public onAcceptClick() {
        clearInterval(this.intervalPromise);
        this.modalController.dismiss({ 'accept': true });
    }

    public onDeclineClick() {
        clearInterval(this.intervalPromise);
        this.modalController.dismiss({ 'accept': false });
    }
}

<ion-header>
    <ion-toolbar>
        <ion-title>Tickets</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onCancelClick()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item-divider color="tertiary">Assigned Tickets</ion-item-divider>
        <ion-item *ngFor="let ticket of assignedTickets">
            <ion-label class="ion-text-wrap" [class.urgent]="ticket.isUrgent">
                <span class="line1">Passenger: {{ticket.passengerName}}</span><br />
                <span class="line2">Type: {{getType(ticket)}}</span><br />
                <span class="line2">To: {{getToAddress(ticket)}}</span><br />
                <span class="line2">Note: {{ticket.note}}</span><br />
                <!-- <br />  -->
                <!-- If this break is not here, then the urgent background color will not include the note. -->
            </ion-label>
        </ion-item>

        <ion-item-divider color="tertiary">En Route Tickets</ion-item-divider>
        <ion-item *ngFor="let ticket of enrouteTickets">
            <ion-label class="ion-text-wrap" [class.urgent]="ticket.isUrgent">
                <span class="line1">Passenger: {{ticket.passengerName}}</span><br />
                <span class="line2">Type: {{getType(ticket)}}</span><br />
                <span class="line2">To: {{getToAddress(ticket)}}</span><br />
                <span class="line2">Note: {{ticket.note}}</span><br />
                <br /> <!-- If this break is not here, then the urgent background color will not include the note. -->
            </ion-label>
        </ion-item>

        <ion-item-divider color="tertiary">Current Tickets</ion-item-divider>
        <ion-item *ngFor="let ticket of currentTickets">
            <ion-label class="ion-text-wrap" [class.urgent]="ticket.isUrgent">
                <span class="line1">Passenger: {{ticket.passengerName}}</span><br />
                <span class="line2">Type: {{getType(ticket)}}</span><br />
                <span class="line2">To: {{getToAddress(ticket)}}</span><br />
                <span class="line2">Note: {{ticket.note}}</span><br />
                <br /> <!-- If this break is not here, then the urgent background color will not include the note. -->
            </ion-label>
        </ion-item>

        <ion-item-divider color="tertiary">Completed Tickets</ion-item-divider>
        <ion-item *ngFor="let ticket of completedTickets">
            <ion-label class="ion-text-wrap" [class.urgent]="ticket.isUrgent">
                <span class="line1">Passenger: {{ticket.passengerName}}</span><br />
                <span class="line2">Type: {{getType(ticket)}}</span><br />
                <span class="line2">To: {{getToAddress(ticket)}}</span><br />
                <span class="line2">Note: {{ticket.note}}</span><br />
                <br /> <!-- If this break is not here, then the urgent background color will not include the note. -->
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-button color="primary" expand="full" (click)="onCancelClick()">Cancel</ion-button>
    </ion-toolbar>
</ion-footer>

import { Injectable } from '@angular/core';
import { TAddress, TServiceArea } from '@shared/models/index';
import { ServiceAreasService } from '@shared/servicesCommon/serviceAreas.service';
import { FirebaseFunctionsService } from '@shared/servicesCommon/firebaseFunctions.service';
import { UtilsService } from '@shared/servicesCommon/utils.service';

type TAddressComponent = 'street_number' | 'route' | 'postal_code';
type TComponent = {
    long_name: string;
    short_name: string;
    types: string[];
};

@Injectable({
    'providedIn': 'root'
})
export class AddressVerificationService {
    private serviceAreas: TServiceArea[] = [];
    

    constructor(private firebaseFunctionService: FirebaseFunctionsService, private utilsService: UtilsService,
                private serviceAreasService: ServiceAreasService) {
    }

    public async verifyAddress(addr: TAddress): Promise<any> {
        try {
            this.serviceAreas = await this.serviceAreasService.getAll();

            // Make certain the street address doesn't have invalid chars.
            addr.streetAddress = this.utilsService.replaceCh(addr.streetAddress, '.#', '');

            const testAddress = this.utilsService.formatAddressForGoogleMaps(
                addr.streetAddress, addr.city, addr.state, addr.zipcode, addr.countryName);
    
            // const response: any = await this.firebaseFunctionService.convertStreetAddressToLatLng(addr.streetAddress);
            const response: any = await this.firebaseFunctionService.convertStreetAddressToLatLng(testAddress);
            const validation: any = this.validateAddress(addr, response);

            return validation;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    private validateAddress(requestedAddress: any, response: any): any {
        if (response.status === 'ZERO_RESULTS') {
            throw new Error('Street not found in this zipcode');
        }
        
        // const status = response.status;
        const result = response.results[0];
        const locationType = result.geometry.location_type;
        const addressComponents = result.address_components;

        // The user only typed in a street number, a street name and a zipcode.
        // Even though the firebase function returned a lot more information like city, county, country etc.
        // we only need to validate the three typed in values.
        const foundStreetNums  = this.extractValuesFromAddressComponentsByType(addressComponents, 'street_number');
        const foundStreetNames = this.extractValuesFromAddressComponentsByType(addressComponents, 'route');
        const foundZipcodes    = this.extractValuesFromAddressComponentsByType(addressComponents, 'postal_code');
        // const foundAddress     = (foundStreetNums?.long_name) ?  + ' ' + foundStreetNames?.long_name;
        // const foundAddress     = foundStreetNums?.long_name + ' ' + foundStreetNames?.long_name;
        let foundAddress = '';
        if (foundStreetNums && foundStreetNames) {
            foundAddress     = foundStreetNums.long_name + ' ' + foundStreetNames.long_name;
        }
        // The user typed in these values.
        const requestedZipcode = requestedAddress.zipcode.toString();
        const parts = requestedAddress.streetAddress.split(' ');
        const requestedStreetNumber = parts[0];

        // Remove the street number to be left with the street name
        const addr = requestedAddress.streetAddress.toString();
        const requestedStreetName = addr.replace(requestedStreetNumber, '').trim().toString();

        // Verify that the typed in values resulted in a good address.
        const streetNumberVerified = this.verifyValue(requestedStreetNumber, foundStreetNums);
        const streetNameVerified   = this.verifyValue(requestedStreetName, foundStreetNames);
        const zipcodeVerified      = this.verifyValue(requestedZipcode, foundZipcodes);

        //
        const zipcode1 = this.isZipcodeServiceable(foundZipcodes?.long_name);
        const zipcode2 = this.isZipcodeServiceable(requestedZipcode);
        const isZipcodeServiceable = zipcode1 && zipcode2;

        //
        let statusTitle: string = '';
        let statusMessage: string = '';
        let streetNamePartialMatch = false;
        let zipcodePartialMatch = false;

        if (!isZipcodeServiceable) {
            statusTitle   = 'We do not service this zipcode.';
            statusMessage = requestedZipcode;
        } else if (!zipcodeVerified) {
            // statusTitle    = 'Address not found in zipcode ' + requestedZipcode;
            statusTitle    = 'Address not found in zipcode';
            statusMessage  = requestedZipcode;
            if (foundZipcodes) {
                zipcodePartialMatch = true;
                // statusMessage = 'Address was found in zipcode ' + foundZipcodes.long_name;
            }
        } else if (!streetNameVerified) {
            statusTitle   = 'Address not found on this street or street not found in this zipcode.';
            statusMessage = requestedAddress.streetAddress;
            if (foundAddress) {
                streetNamePartialMatch = true;
            }
        } else if (!streetNumberVerified) {
            statusTitle   = 'Street number not found on this street.';
            statusMessage = requestedAddress.streetAddress;
        } else if (streetNumberVerified && streetNameVerified) {
            statusTitle   = 'Exact address found.';
            statusMessage = result.formatted_address;
            streetNamePartialMatch = false;
        } else if (streetNumberVerified || streetNameVerified) {
            statusTitle   = 'Address not found; however, a nearby match was found. Do you want to use this address?';
            statusMessage = requestedAddress.streetAddress;
        } else {
            statusTitle   = 'Address not found.';
            statusMessage = requestedAddress.streetAddress;
        }

        const validation: any = {
            'requestedAddress': requestedAddress,
            'location': {
                'formattedAddress': result.formatted_address,
                'locationType': locationType,
                'coordinate': {
                    'latitude': result.geometry.location.lat,
                    'longitude': result.geometry.location.lng
                }
            },
            'foundAddress': foundAddress,
            'found': (streetNumberVerified && streetNameVerified && zipcodeVerified),
            'foundZipcode': foundZipcodes.long_name,
            'statusTitle': statusTitle,
            'statusMessage': statusMessage,
            'streetNamePartialMatch': streetNamePartialMatch,
            'zipcodePartialMatch': zipcodePartialMatch
        };

        return validation;
    }

    // This method belongs in serviceAreas.service
    private isZipcodeServiceable(requestedZipcode: string): boolean {
        const item = this.serviceAreas.find(value => {
            return (value.zipcode === requestedZipcode);
        });

        return (item != undefined);
    }

    private extractValuesFromAddressComponentsByType(components: any, type: TAddressComponent): TComponent {
        let comp: any = null;
        let idx = -1;
        components.forEach(component => {
            ++idx;
            const types = component.types;
            types.forEach(typ => {
                if (typ === type) {
                    comp = component;
                }
            });
        });
        return comp;
    }

    private verifyValue(testValue: string, acceptableValues: any) {
        // Assume the user types in the address for Costco:  3411 St Rose Parkway
        // The firebase function returns short name:  3411 St Rose Pkwy and long name: 3411 Saint Rose Parkway
        // The user doesn't realize he typed in a combination of short and long names.

        if (!testValue || !acceptableValues || !acceptableValues.long_name || !acceptableValues.short_name) {
            return false;
        }

        const testwords = testValue.toLowerCase().split(' ');

        const acceptablevalue1 = acceptableValues.long_name.toLowerCase();
        const acceptablevalue2 = acceptableValues.short_name.toLowerCase();

        const allAcceptableValues = acceptablevalue1 + ' ' + acceptablevalue2;
        const words: string[] = allAcceptableValues.split(' ');
        
        let allTestWordsAcceptable = true;
        testwords.forEach(word => {
            const found = words.includes(word);
            if (!found) {
                allTestWordsAcceptable = false;
            }
        });
        // words.includes(testvalue);
        // const foundWord = words.includes(testvalue);

        return allTestWordsAcceptable;

        // Do not do this:
        // Search for encia in the string = 'valencia boulevard valencia blvd'
        // The above string search will return true.  Obviously there is no street named encia.
    }

}
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Injector } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ModalOptions, PopoverOptions } from '@ionic/core';

import { AuthService } from '@shared/servicesCommon/auth.service';
import { ConnectivityService } from '@shared/servicesCommon/connectivity.service';
import { DateService } from '@shared/servicesCommon/date.service';
import { EventsService } from '@shared/servicesCommon/events.service';
import { LocalDataService } from '@shared/servicesCommon/localData.service';
import { SessionService } from '@shared/servicesCommon/session.service';
import { SettingsService } from '@shared/servicesCommon/settings.service';
import { SmsService } from '@shared/servicesCommon/sms.service';
import { UtilsService } from '@shared/servicesCommon/utils.service';
import { UxHelperService } from '@shared/servicesCommon/uxHelper.service';
import { VariableReplacementService } from '@shared/servicesCommon/variableReplacement.service';

import { CalendarModalPage, PopoverMenu } from '@shared/modalsCommon/index';
import { Constants, TPopoverMenuItem, TSetting, TTimestamp } from '@shared/models/index';

@Component({
    'selector': 'app-viewBase',
    'templateUrl': './viewBase.page.html',
    'styleUrls': ['./viewBase.page.scss'],
})
//ptb when I make the class export abstract class ViewBasePage implements OnInit, OnDestroy {
export class ViewBasePage implements OnInit, OnDestroy {
    @ViewChild('header', { read: ElementRef }) headerRef: ElementRef;
    @ViewChild('headerToolbar', { read: ElementRef }) headerToolbarRef: ElementRef;
    @ViewChild('title', { read: ElementRef }) titleRef: ElementRef;
    private currentTitle = '';
    private settingsSubscription: any;
    // private subscriptions: any = [];
    // private isHelpVisible = false;  // this must match the default opacity setting in variables.scss
    protected auth: AuthService;
    protected utilsService: UtilsService;
    protected eventsService: EventsService;
    protected smsService: SmsService = null;
    protected connectivityService: ConnectivityService;
    protected localDataService: LocalDataService;
    protected uxHelperService: UxHelperService;
    protected sessionService: SessionService;
    protected settingsService: SettingsService;
    protected variableReplacementService: VariableReplacementService;
    protected dateService: DateService;
    protected Constants = Constants;
    protected modalController: ModalController;
    protected popoverController: PopoverController;
    // protected window: Window;

    protected helpDocName: string;
    protected pageName: string;
    private formErrorsMap: Map<string, any>;
    private setting: TSetting;
    private popoverMenuItems = [];
    private moreMenuItemClickCallback: any;
    public vb: any = {};

    constructor(private injector: Injector) {
        this.popoverController = injector.get(PopoverController);
        this.auth = injector.get(AuthService);
        this.utilsService = injector.get(UtilsService);
        this.eventsService = injector.get(EventsService);
        this.uxHelperService = injector.get(UxHelperService);
        this.modalController = injector.get(ModalController);
        this.localDataService = injector.get(LocalDataService);
        this.connectivityService = injector.get(ConnectivityService);
        this.sessionService = injector.get(SessionService);
        this.settingsService = injector.get(SettingsService);
        this.variableReplacementService = injector.get(VariableReplacementService);
        this.dateService = injector.get(DateService);
        // this.window = injector.get(Window);
    }

    ngOnInit(): void {
        this.getSettings();

        // When the internet connection state changes then re/enable the login button.
        this.vb.isOnline = this.connectivityService.isOnline();
        this.vb.disabled = !this.vb.isOnline;
        this.connectivityService.monitor().subscribe(isConnected => {
            this.vb.isOnline = isConnected;     // this.connectivityService.isOnline();
            this.vb.disabled = !this.vb.isOnline;
            const title = (this.vb.isOnline) ? this.currentTitle : 'No Internet Connection';
            const kolor = (this.vb.isOnline) ? '#3d4e66' : '#cccccc';

            if (this.titleRef) {
                this.titleRef.nativeElement.innerHTML = title;
                document.body.style.setProperty('--myDisabledEnabledColor', kolor);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.formErrorsMap) {
            this.formErrorsMap.clear();
            this.formErrorsMap = null;
        }

        if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
        }
        // this.subscriptions.forEach((subscription: any) => {
        //     if (subscription) {
        //         subscription.unsubscribe();
        //     }
        // });
    }

    protected ionViewDidEnter() {
        if (this.titleRef) {
            this.currentTitle = this.titleRef.nativeElement.innerHTML;
        }
        // Try to find the Usetiful window so I can close it.
        for (var i = 0; i < window.frames.length; i++) {
            console.log(window.frames[i]);
        }
    }

    // The Welcome view and the Login view are both derived from ViewBase.  However,
    // neither class requires settings.  Settings is account dependent and only has a
    // valid value after login.
    private async getSettings() {
        const settingsObservable = this.settingsService.getObservable();
        if (settingsObservable) {
            this.settingsSubscription = settingsObservable.subscribe(async (settings: TSetting[]) => {
                if (settings && settings.length > 0) {
                    this.setting = settings[0];
                    // await this.variableReplacementService.initialize(this.setting);
                    if (!this.smsService) {
                        this.smsService = this.injector.get(SmsService);
                    }
                    this.smsService.initialize(this.setting);
            }
            });
            // this.registerSubscription(settingsSubscription);
        }
    }

    // protected registerSubscription(subscription) {
    //     if (subscription) {
    //         this.subscriptions.push(subscription);
    //     }
    // }

    protected setCurrentView(viewName: string) {
        if (viewName) {
            this.pageName = viewName;
            // The EVENT_PAGE_LOADED will be caught by app.component.ts file.
            // In response to this event, it will highlight the proper menuitem.
            // const viewNameProper = this.utilsService.toTitleCase(viewName);
            // this.eventsService.publish(Constants.EVENT_PAGE_LOADED, viewNameProper);
            this.eventsService.publish(Constants.EVENT_PAGE_LOADED, viewName);
        }
    }

    protected internetConnected() {
        return this.connectivityService.isOnline();
    }

    protected isOnline() {
        return this.connectivityService.isOnline();
    }

    private sleep(ms) {
        const promise = new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
        return promise;
    }

    // Angular doesn't have a way to pass complex objects on the router.
    // So I will use this SessionService as a way to hold the objects that I need to pass around.
    protected setViewModel(vm: any) {
        this.sessionService.setViewModel(vm);
    }
    public getViewModel() {
        return this.sessionService.getViewModel();
    }

    // public async onExecuteAutoTypeClick(event: any, formName: string) {
    //     // let formData: any = null;
    //     const data = await this.localDataService.readFile(this.Constants.FILENAME_TUTORIAL_DATA);
    //     const formData = data[formName];
    //     if (formData.length > 0) {
    //         // const uxHelper = this.injector.get(UxHelperService);
    //         this.typeFormDataIntoFields(formData, this);
    //     }
    // }

    // private typeFormDataIntoFields(formData: any, self: any) {
    //     (async function loop() {
    //         for (let idx = 0; idx < formData.length; idx++) {
    //             const fieldData = formData[idx];

    //             const element = self.getElement(fieldData.fieldName);
    //             if (element) {
    //                 await self.typeIntoInputField(element, fieldData.value)
    //                 .then(async () => {
    //                     // Wait a moment before typing into the next field.
    //                     await self.sleep(300);
    //                 });
    //             }
    //         }
    //     })();
    // }

    protected typeIntoInputField(element: any, text: string) {
        const promise = new Promise(async (resolve, reject) => {

            // Change the font color, set the input focus and initialize the field to empty.
            const elementRef = element.elementRef;
            elementRef._elementRef.nativeElement.style.color = '#ff0000';
            elementRef.setFocus();
            elementRef.value = '';

            //
            this.uxHelperService.playTypingSound();
            await this.sleep(300);

            for (let idx = 0; idx < text.length; idx++) {
                const ch = text.substring(idx, idx + 1);
                elementRef.value = elementRef.value + ch;
                await this.sleep(150);  // wait 150ms between keystrokes.  It looks better
            }
            this.uxHelperService.stopSound();
            resolve(0);
        });
        return promise;
    }

    // protected async helpModalShow() {
    //     const options: ModalOptions = {
    //         'component': HelpModalPage,
    //         // componentProps: { 'pageName': this.pageName, 'helpDocName': this.helpDocName, 'domain': this.domain },
    //         // componentProps: { 'pageName': this.pageName, 'domain': this.domain },
    //         'componentProps': { 'pageName': this.pageName, 'domain': this.sessionService.getDomain() },
    //         'backdropDismiss': true,
    //         'showBackdrop': true,
    //         'cssClass': 'myModalSize'
    //     };

    //     const myModal = await this.modalController.create(options);
    //     myModal.present();
    // }

    // public hideHelp(): void {
    //     anime.set('.helpContainer', { 'opacity': '0' });
    //     this.isHelpVisible = false;
    // }

    // private animeToggleHelpVisibility() {
    //     this.isHelpVisible = !this.isHelpVisible;

    //     const params = {
    //         targets: '.helpContainer',
    //         opacity: '0',
    //         easing: 'linear',
    //         // visibilitys: 'hidden',
    //         duration: '400'
    //     };
    //     // params.visibility = (this.isHelpVisible) ? 'visible' : 'hidden';
    //     params.opacity = (this.isHelpVisible) ? '1' : '0';
    //     anime(params);
    // }

    // public onPress() {
    //     this.helpModalShow();
    // }

    public onTitleClick() {
        // this.helpModalShow();
    }

    // public onToggleHelpClick(/* init?: boolean */) {
    //     // this.animeToggleHelpVisibility();
    //     this.helpModalShow();
    // }

    protected async openCalendarModal(dateRangeStart: TTimestamp, dateRangeEnd: TTimestamp,
                selectedDate: TTimestamp, title: string): Promise<TTimestamp> {
        const promise = new Promise(async (resolve, reject) => {
            const options: ModalOptions = {
                'component': CalendarModalPage,
                'componentProps': {
                    'title': title,
                    'dateRangeStart': dateRangeStart,
                    'dateRangeEnd': dateRangeEnd,
                    'selectedDate': selectedDate
                },
                'backdropDismiss': false,
                'showBackdrop': true,
                'cssClass': 'myCalendarModal'
            };
    
            const myModal = await this.modalController.create(options);
            myModal.onDidDismiss()
                .then((response: any) => {
                    if (response.data.okay) {
                        resolve(response.data.selectedDate);
                        // if (promptForStartDate) {
                        //     this.startingDate = response.data.selectedDate;
                        // } else {
                        //     this.endingDate = response.data.selectedDate;
                        // }
                        // this.verifyDateRange();
                    }
                });
    
            await myModal.present();
        });
        return promise as Promise<TTimestamp>;
    }

    // 
    public resetPopoverMenu(): void {
        this.popoverMenuItems = [];
    }

    public pushPopoverMenuItem(menuItemName: string, testId: string = '', content: string = ''): void {
        // Prevent the Usetiful menu item from showing.
        if (menuItemName === 'Show Tour') {
            return;
        }
        const item: TPopoverMenuItem = { 'name': menuItemName, 'testId': testId, 'content': content };
        this.popoverMenuItems.push(item);
    }

    protected moreMenuItemClick(callback: any) {
        this.moreMenuItemClickCallback = callback;
    }

    // , callback: any
    public async onMoreClick(event: Event): Promise<void> {
        const options: PopoverOptions = {
            'component': PopoverMenu,
            'componentProps': { 'menuItems': this.popoverMenuItems },
            'event': event,
            'showBackdrop': false,
            'cssClass': 'myPopoverMenuClass',
        };

        const popover = await this.popoverController.create(options);
        popover.onDidDismiss()
        .then((response) => {
            if (response.data) {
                if (this.moreMenuItemClickCallback) {
                    // Allow the derived page to handle the click.
                    // In the case of 'Show Tour', no special handling is necessary to show the usetiful tour.
                    this.moreMenuItemClickCallback(response.data.selectedItem, event);
                }
            }
        });
        popover.present();
    }

/* For Debugging Only
    protected showDataInTab(data: any): void {
        const secsInYear = 60*60*24*365;
        const timestamp = this.dateService.getMinimumDate();
        const yearLater = this.dateService.add(timestamp, secsInYear, 'seconds', false);
        console.log('timestamp = ', this.dateService.formatTimestamp(timestamp));
        console.log('yearLater = ', this.dateService.formatTimestamp(yearLater));

        const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'});
        // const dialogConfig = new MatDialogConfig();
    
        // dialogConfig.autoFocus = true;
        // dialogConfig.data = {
        const blobUrl = window.URL.createObjectURL(blob);
        //   backupType: 'snippets'
        // };
    
        console.log(data);
        console.log(JSON.stringify(data));

        console.log(blob);
        console.log(JSON.stringify(blob));
        // this.backupBookmarksDialog.open(BackupBookmarksDialogComponent, dialogConfig);
        window.open(blobUrl, '_blank');
    }
*/

}

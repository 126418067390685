import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
    'selector': 'app-radioModal',
    'templateUrl': './radioModal.page.html',
    'styleUrls': ['./radioModal.page.scss'],
})
export class RadioModalPage implements OnInit {
    public items: any = [];
    public title: string;
    public selectedItem: string;
    // public selectedValue: any;

    // https://www.youtube.com/watch?v=dOSG5dDoi1c
    constructor(public modalController: ModalController, private navParams: NavParams) {
    }

    ngOnInit(): void {
        this.title = this.navParams.get('title');
        this.items = this.navParams.get('items');
        this.selectedItem = this.navParams.get('selectedItem');
        // this.selectedValue = this.selectedItem;
        // this.selectedValue.value = this.selectedItem;
    }

    // We are going to add a data-test attribute to every radio item.
    public getDataTestId(item: string): string {
        return item;
    }

    public radioGroupChange(event) { 
        console.log(event.detail.value)
    }
     
    public onItemClick(item) {
        // this.selectedItem = item;
    }

    public onOkayClick() {
        const response = { 'okay': true, 'selectedItem': this.selectedItem };
        this.modalController.dismiss(response);
    }

    public onCancelClick() {
        const response = { 'okay': false, 'selectedItem': null };
        this.modalController.dismiss(response);
    }
}


export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAAuEBE-XgAos_OxYNMNDmJ27U9_-tciNg",
        authDomain: 'shuttlenomix.firebaseapp.com',
        databaseURL: 'https://shuttlenomix.firebaseio.com',
        projectId: 'shuttlenomix',
        storageBucket: 'shuttlenomix.appspot.com',
        messagingSenderId: '728149494111',
        appId: "1:728149494111:web:04bd7a164f31ad0e",
        measurementId: "G-8H9ESEZT3P"
    },
    googleMapsApiKey: 'AIzaSyAAuEBE-XgAos_OxYNMNDmJ27U9_-tciNg'
};

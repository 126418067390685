import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app';

import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { CollectionService } from '@shared/servicesCommon/collection.service';
import { SessionService } from '@shared/servicesCommon/session.service';
import { UxHelperService } from '@shared/servicesCommon/uxHelper.service';
import { EventsService } from '@shared/servicesCommon/events.service';
import { AccountProfileService } from '@shared/servicesCommon/accountProfile.service';
import { UtilsService } from '@shared/servicesCommon/utils.service';
import { Constants, TCustomer, TSmsType, TRole, TCreateAccountInfo, TEmail } from '@shared/models/index';
import * as JsonQueryString from 'json-query-string';

@Injectable({
    'providedIn': 'root'
})
export class FirebaseFunctionsService {
    constructor(private sessionService: SessionService, private firestoreService: FirestoreService, 
                private collectionService: CollectionService, private uxHelperService: UxHelperService,
                private angularFireFunctionsService: AngularFireFunctions, private httpClient: HttpClient,
                private accountProfileService: AccountProfileService, private eventsService: EventsService,
                private utilsService: UtilsService) {

        // The function emulator will only be available if we are running on localhost.
        // Access the function emulator if we are debugging.
        const domainInfo = this.utilsService.getDomainInfo();
        const domainWithoutPort = domainInfo.domainWithPort.substring(0, 9);    // 9 chars in 'localhost'
        if (domainWithoutPort === 'localhost' && sessionService.isDebugMode) {
            this.angularFireFunctionsService.useEmulator("localhost", 5001)
        }
        this.eventsService.subscribe(Constants.EVENT_PAGE_LOADED, (data) => { this.onTrackUsage(data); });
    }

    public async addAccountToTOC(accountNumber: string): Promise<any> {
        if (!accountNumber) {
            throw new Error('addAccountToTOC Error: Invalid parameters.');
        }

        try {
            const parms = { 
                'accountNumber': accountNumber,
            };
            const func = this.angularFireFunctionsService.httpsCallable('addAccountToTOC');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            throw err;
        };
    }

    // private supplyDefaults() {
    //     const action: any = {};
    //     action.accountNumber = this.sessionService.getAccountNumber();
    //     action.funcName = '';
    //     action.tag = '';
    //     return action;
    // }

    // private pushChild(action) {
    //     const collection = this.collectionService.getFirebaseFunctionsCol();
    //     return this.firestoreService.add(collection, action);
    // }

    public async convertStreetAddressToLatLng(streetAddress: string) {
        const parms = {'streetAddress': streetAddress};

        try {
            const func = this.angularFireFunctionsService.httpsCallable('convertStreetAddressToLatLng');
            const response = await func(parms).toPromise();
            return response;
        } catch (err) {
            console.error('Error: ', err);
            throw err;
        }
    }

    public async testFirebaseFunction() {
        try {
            // const parms = {
            //     'functionToTest': 'updateDealershipNameInTableOfContents',
            //     'accountNumber': '22269-1529-06258',
            //     'uid': 'dealership'
            // };
            
            // const parms = {
            //     // 'functionToTest': 'addAccountToTableOfContents',
            //     // 'documentName': 'ticketsSummary',
            //     // 'accountNumber': '00000-0000-00001',
            //     // 'uid': '4RfKVyYIUzYSKwlr2zHj'

            //     // 'documentName': 'smsSummary',
            //     // 'accountNumber': '00000-0000-00001',
            //     // 'uid': 'Kf2x7zfQHhlJLYn6SMHE'
            //     'functionToTest': 'getCampaignMetadata',
            //     'accountId': '4',
            //     'customerId': '1',
            //     'incentiveSetId': '7'
            // };

            // const parms = {
            //     'functionToTest': 'createAccount',
            //     'createAccountInfo': {
            //         // Manager Info
            //         'firstName':         'Fred',
            //         'lastName':          'Flinstone',
            //         'cellPhone':         '6618778631',
            //         'email':             'Fred.Flinstone@ShuttleNomix.com',
            //    
            //         // Dealership Info'
            //         'dealershipName':    'Freds Autos',
            //         'phoneNumber':       '6618778631',
            //         'countryName':       'USA',
            //         'countryCode':       '+1',
            //         'fullAddress':       '1690 Danbury Crossing Dr, Henderson, NV 89052, USA',
            //         'streetAddress':     '1690 Danbury Crossing Drive',
            //         'state':             'Nevada',
            //         'statecode':         'NV',
            //         'zipcode':           '89052',
            //         'city':              'Henderson',
            //         'coordinate': {
            //             latitude:        35.9657247,
            //             longitude:       -115.0984695,
            //         },
            // 
            //         // Other Info'
            //         'accountNumber':     '00000-0000-00099',
            //         'affiliateId':       'af1',
            //         'signupCouponCode':  'cc1',
            //     }
            // }

            // const parms = {
            //     'functionToTest': 'sendEmail',
            //     'to': 'OneMinuteSystems@gmail.com',

            //     // Example #1
            //     // 'sendAs': 'html',
            //     // 'subject': 'This is a test - Notice that click tracking has been turned OFF',
            //     // 'body': "This is the body. <a clicktracking='off' href='https://app.shuttlenomix.com'>https://app.shuttlenomix.com</a>",
                
            //     // Example #2
            //     // 'sendAs': 'html',
            //     // 'subject': 'This is a test - Notice that click tracking has been left ON',
            //     // 'body': "This is the body. <a href='https://app.shuttlenomix.com'>https://app.shuttlenomix.com</a>",

            //     // Exaple #3
            //     'sendAs': 'text',
            //     'subject': 'This is a text email',
            //     'text': 'Line 1.\nLine 2.\nLine 3.\nLine 4.',
            // };

            const parms = {};
            const func = this.angularFireFunctionsService.httpsCallable('test');
            const response = await func(parms).toPromise();

            console.log(response);
        }
        catch (err) {
            throw err;
        };
    }

    // public async sendEmail(subject: string, body: string, to: string, from: string='') {
    public async sendEmail(email: TEmail) {
        try {
            // const parms = {'subject': subject, 'body': body, 'from': from, 'to': to};
            const parms = email;
            const func = this.angularFireFunctionsService.httpsCallable('sendEmail');
            const response = await func(parms).toPromise();
            console.log(response);
        }
        catch (err) {
            throw err;
        };
    }
    // public async sendCampaignMessage(smsType: TSmsType, textMessage: string, parms: any) {
    //     const promise = new Promise((resolve, reject) => {
    //         if (!customers || customers.length === 0) {
    //             reject('sendSms Error: Invalid parameters.');
    //         }

    //         if (!this.sessionService.getAccountProfile().customer) {
    //             return;
    //         }

    //         // const setting = this.sessionService.getSetting();
    //         // const domain = this.sessionService.get Domain();
    //         // const campaignLink = setting.hosting.campaignHostingLink;
    //         // const optoutLink = setting.hosting..s.campaign.campaignOptoutLink;
    //         // const hostingEnabled = setting.hosting.enabled;
    //         // const accountNumber = this.sessionService.getAccountNumber();
    //         // const countryCode = this.sessionService.getAccountProfile().countryCode;
    //         // const dealershipPhoneNumber = this.sessionService.getAccountProfile().phoneNumber;
    //         // const parms = { 'accountNumber': accountNumber,
    //         //                 'countryCode': countryCode,
    //         //                 'dealershipPhoneNumber': dealershipPhoneNumber,
    //         //                 'customers': customers,
    //         //                 'smsType': 'campaign',
    //         //                 'textMessage': textMessage,
    //         //                 'campaignLink': campaignLink,
    //         //                 // 'optoutLink': optoutLink,
    //         //                 'hostingEnabled': hostingEnabled,
    //         //                 'domain': domain
    //         //             };
    //         const sendCampaignSms = firebase.functions().httpsCallable('sendCampaignSms');
    //         sendCampaignSms(parms)
    //         .then((result) => {
    //             console.log('In firebaseFunctionsService.sendCampaignSms() Success;  result = ', result.data);
    //             resolve(result.data);
    //         })
    //         .catch((err) => {
    //             this.uxHelperService.showAlert('Error:', err.message);
    //             // reject(err);
    //             resolve();
    //         });
    //     });
    //     return promise;
    // }

    public async sendSms(customers: TCustomer[], smsType: TSmsType, textMessage: string): Promise<any> {
        if (!customers || customers.length === 0 || !textMessage) {
            throw new Error('sendSms Error: Invalid parameters.');
        }

        try {
            const accountProfile = await this.accountProfileService.get();
            if (accountProfile.packageName === 'Pay As You Go Package' && accountProfile.creditInfo.availableCredits < 1) {
                throw new Error('Message Not Sent - Insufficient Available Credits.');
            }

            const cellPhone = customers[0].cellPhone;
            const allCharsEqual = this.utilsService.allEqual(cellPhone);
            if (allCharsEqual) {
                throw new Error('Message Not Sent - Cell Phone Number Appears To Be Invalid: ' + cellPhone);
            }

            const accountNumber = this.sessionService.getAccountNumber();
            const countryCode = this.sessionService.getDealership().address.countryCode;
            const parms = {
                'accountNumber': accountNumber,
                'customers': customers,
                'smsType': smsType,
                'countryCode': countryCode,
                'textMessage': textMessage
            };

            this.uxHelperService.showToast('Sending message...');
            const func = this.angularFireFunctionsService.httpsCallable('sendSms');
            const response = await func(parms).toPromise();
            // console.log('In firebaseFunctions.sendSms(); response = ', response);
            this.uxHelperService.showToast('The message was sent.');
            return response;
        }
        catch (err) {
            this.uxHelperService.showAlert('Error', err.message);
        }
        // The thrown errors will be caught by the methods in sms.service.ts.
        // finally {
        // };
    }

    
    public async adjustActiveCampaignTags(email, tagsToAdd, tagsToRemove) {
        const numTagsToAdjust = tagsToAdd.length + tagsToRemove.length;
        if (!email || numTagsToAdjust === 0) {
            throw new Error('adjustActiveCampaignTags Error: Invalid parameters.');
        }

        try {
            const accountNumber = this.sessionService.getAccountNumber();
            const parms = { 
                'accountNumber': accountNumber,
                'email': email,
                'tagsToAdd': tagsToAdd,
                'tagsToRemove': tagsToRemove
            };
            const func = this.angularFireFunctionsService.httpsCallable('adjustActiveCampaignTags');
            const response = await func(parms).toPromise();
            return Date.now();
        }
        catch (err) {
            throw err;
        };
    }

    // Only for debugging and updating the constants file.
    public async listAllTags() {
        try {
            const data: any = {};

            const func = this.angularFireFunctionsService.httpsCallable('listAllTags');
            const response = await func(data).toPromise();
            return Date.now();
        } catch(err) {
            throw err;
        };
    }

    // I don't think this is used.
    public async getCampaignMetadata(accountNumber: string, customerUid: string, incentiveSetId: string)
                : Promise<any> {
        try {
            const parms = {
                'accountNumber': accountNumber,
                'customerUid': customerUid,
                'incentiveSetId': incentiveSetId
            };

            const func = this.angularFireFunctionsService.httpsCallable('getCampaign');
            const result = await func(parms);
            console.log('In firebaseFunctionsService.getCampaign() Success;  result = ', result);
            return Date.now();
        } catch (err) {
            throw err;
        } 
    }

    public async updateExpirationDateCount(accountNumber: string) {
        try {
            const parms = { 'accountNumber': accountNumber };

            const func = firebase.functions().httpsCallable('expirationDateExtension');
            const result = await func(parms);
            console.log('In firebaseFunctionsService.expirationDateExtension() Success;  result = ', result);
            return Date.now();
        } catch (err) {
            throw err;
        }
    }

    // https://us-central1-shuttlenomix.cloudfunctions.net/restoreBackup?timestamp=2019-12-15-09-39-16&accountId=19347-0902-3457
    public async restoreFromBackup(backupFilename: string, accountNumber: string) {
        try {
            const parms = { 'timestamp': backupFilename, 'accountNumber': accountNumber };

            const func = firebase.functions().httpsCallable('restoreBackup');
            const result = await func(parms);
            console.log('In firebaseFunctionsService.expirationDateExtension() Success;  result = ', result);
            return Date.now();
        } catch (err) {
            throw err;
        } 
    }

    public async getMetaDataByEmail() {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const prefixUrl = 'https://us-central1-shuttlenomix.cloudfunctions.net/getMetaDataByEmail';
                const url = prefixUrl + '?email=michael@shuttlenomix.com';

                const response: any = await this.httpClient.get(url).toPromise();
                console.log(response);
                resolve(response);
            } catch (err) {
                console.log('err.message = ', err.message);
                reject(err);
            }
        });
        return promise;
    }

    public async getCurrentId(): Promise<any> {
        try {
            const params: any = {};

            const func = this.angularFireFunctionsService.httpsCallable('getCurrentId');
            const response = await func(params).toPromise();
            return response;
        }
        catch (err) {
            throw err;
        };
    }

    public async getNextAccountId(): Promise<string> {
        try {
            const params: any = {};

            const func = this.angularFireFunctionsService.httpsCallable('getNextAccountId');
            const response = await func(params).toPromise();
            return response;
        }
        catch (err) {
            throw err;
        };
    }

    public async getNextIncentiveSetId(): Promise<any> {
        try {
            const params: any = {};

            const func = this.angularFireFunctionsService.httpsCallable('getNextIncentiveSetId');
            const response = await func(params).toPromise();
            return response;
        }
        catch (err) {
            throw err;
        };
    }

    public async getNextCustomerId(): Promise<any> {
        try {
            const params: any = {};

            const func = this.angularFireFunctionsService.httpsCallable('getNextCustomerId');
            const response = await func(params).toPromise();
            return response;
        }
        catch (err) {
            throw err;
        };
    }

    public async onTrackUsage(pageName: string): Promise<void> {
        try {
            // Don't track usage counts if we are doing stuff on the backend.
            if (this.sessionService.getApp() === 'backend') {
                return;
            }
            // The only kpi's we are truly interested in are tickets, routes, navigation and campaigns.
            // I don't think I really care how many times they visit the shuttles page.
            switch (pageName) {
                case Constants.VIEW_TICKETS_PAGE3:
                case Constants.VIEW_ROUTE_PAGE8:
                case Constants.VIEW_NAVIGATION_PAGE5:
                case Constants.VIEW_TRACKER_LIVE:
                case Constants.VIEW_TRACKER_HISTORICAL:
                case Constants.VIEW_REPORTS_PAGE3:
                case Constants.VIEW_CAMPAIGN_INCENTIVE_SETS:
                case Constants.VIEW_CAMPAIGNS:
                case Constants.VIEW_CUSTOMERS:
                    break;

                default:
                    return;
            }
            const accountNumber = this.sessionService.getAccountNumber();
            if (!accountNumber) {
                // If we don't have an accountNumber then all that means is the user hasn't logged in yet.
                return;
            }
            
            const parms = { 'accountNumber': accountNumber, 'pageName': pageName };

            const func = this.angularFireFunctionsService.httpsCallable('trackUsage');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            if (err.message !== 'deadline-exceeded') {
                throw err;
            }
        };
    }

    public async setUserClaim(claim: any): Promise<void> {
        try {
            if (!claim) {
                return;
            }
            
            const parms = { 
                'accountNumber': claim.accountNumber,
                'role': claim.role,
                'userUid': claim.userUid
            };

            const func = this.angularFireFunctionsService.httpsCallable('setUserClaim');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            if (err.message !== 'deadline-exceeded') {
                throw err;
            }
        };
    }

    public async createCustomToken(userUid: string) {
        try {
            if (!userUid) {
                return;
            }
            
            const parms = { 
                'userUid': userUid 
            };

            const func = this.angularFireFunctionsService.httpsCallable('createCustomToken');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            if (err.message !== 'deadline-exceeded') {
                throw err;
            }
        };
    }

    // public async createAccount(accountInfo: TCreateAccountInfo) {
    //     console.log({accountInfo});
    //     const queryString = JsonQueryString.jsonQueryStringify(accountInfo);
    //     const prefix = `https://us-central1-shuttlenomix.cloudfunctions.net/`;
    //     const func   = `createAccount?`;
    //     const url    = `${prefix}${func}${queryString}`;
                
    //     try {
    //         const response: any = await this.httpClient.get(url).toPromise();

    //         return response;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    // public async createAccount(accountInfo: TCreateAccountInfo) {
    //     // const queryString = JsonQueryString.jsonQueryStringify(accountInfo);
    //     console.log({accountInfo});
    //     // const queryString = jsonQueryStringify(accountInfo);
    //     const requestBody = JSON.parse(JSON.stringify(accountInfo));
    //     const prefix = `https://us-central1-shuttlenomix.cloudfunctions.net/`;
    //     const func   = `createAccount`;
    //     const url    = `${prefix}${func}`;
                
    //     try {
    //         const response: any = await this.httpClient.post(url, requestBody).toPromise();

    //         return response;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    // public async createAccount(accountInfo: TCreateAccountInfo) {
    //     // const prefix = 'https://4f5a-75-163-30-122.ngrok.io/';
    //     // url = "https://4420-2401-4900-3a6d-858e-d881-b262-3737-5709.in.ngrok.io/neutral-neon/us-central1/helloWorld";
    //     console.log({accountInfo});
    //     const requestBody = JSON.parse(JSON.stringify(accountInfo));
    //     const prefix = `https://us-central1-shuttlenomix.cloudfunctions.net/`;
    //     const func   = `shuttlenomix/us-central1/createAccount/`;
    //     var url      = `${prefix}${func}`;
                
    //     try {
    //         const response: any = await this.httpClient.post(url, requestBody).toPromise();

    //         return response;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    public async createAccount(accountInfo: TCreateAccountInfo) {
        try {
            if (!accountInfo) {
                return;
            }
            
            // const parms = { 
            //     'accountNumber': claim.accountNumber,
            //     'role': claim.role,
            //     'userUid': claim.userUid
            // };
            const parms = accountInfo;
            const func = this.angularFireFunctionsService.httpsCallable('createAccount');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            if (err.message !== 'deadline-exceeded') {
                throw err;
            }
        };

    }

    public async deleteUser(email: string) {
        try {
            if (!email) {
                return;
            }
            const parms = { 
                'email': email 
            };

            const func = this.angularFireFunctionsService.httpsCallable('deleteUser');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            if (err.message !== 'deadline-exceeded') {
                throw err;
            }
        };

        // const action: any = this.supplyDefaults();
        // action.funcName = 'DeleteUser';
        // action.tag = { 'userUid': userUid, 'email': email };
        // return this.pushChild(action);
    }

    public async disableUser(email: string) {
        try {
            if (!email) {
                return;
            }
            const parms = { 
                'email': email 
            };

            const func = this.angularFireFunctionsService.httpsCallable('disableUser');
            const response = await func(parms).toPromise();
            return response;
        }
        catch (err) {
            if (err.message !== 'deadline-exceeded') {
                throw err;
            }
        };
    }

    public async resetCypress(): Promise<void> {
        try {
            const func = this.angularFireFunctionsService.httpsCallable('resetCypress');
            const response = await func(null).toPromise();
            return response;
        }
        catch (err) {
            // if (err.message !== 'deadline-exceeded') {
            throw err;
            // }
        };
    }

    // public async archiveSessionMessages(sessionId: number): Promise<void> {
    //     try {
    //         const accountNumber = this.sessionService.getAccountNumber();
    //         const parms = { 'accountNumber': accountNumber, 'sessionId': sessionId };

    //         const func = this.angularFireFunctionsService.httpsCallable('archiveSessionMessages');
    //         const response = await func(parms).toPromise();
    //         return response;
    //     }
    //     catch (err) {
    //         throw err;
    //     };
    // }

}

import { NgModule } from '@angular/core';
import { MyTimestampPipe } from './timestamp.pipe'
import { MyPhonePipe } from './phone.pipe'
import { MyCurrencyPipe } from './currency.pipe'

@NgModule({
declarations: [
    MyTimestampPipe,
    MyPhonePipe,
    MyCurrencyPipe
],
imports: [],
exports: [
    MyTimestampPipe,
    MyPhonePipe,
    MyCurrencyPipe
]
})

export class PipesModule{}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    'providedIn': 'root'
})
export class LocalDataService {

    private data: any = [];

    constructor(private httpClient: HttpClient) {
    }

    public readFile(filename: string) {
        const promise = new Promise(async (resolve, reject) => {
            const res = await this.httpClient.get(filename).toPromise();
            const data = res;
            resolve(data);
        });
        return promise;
    }

    // Zipcode example - copied over for convenience
    // public loadZipcodes() {
    //     const promise = new Promise((resolve, reject) => {
    //         this.httpClient.get('assets/data/us.json').toPromise()
    //         .then((response: []) => {
    //             const zipcodes = response;
    //             zipcodes.forEach((zipcodeInfo: TZipcode) => {
    //                 zipcodeInfo.uid = zipcodeInfo.zipcode;
    //                 this.set(zipcodeInfo, zipcodeInfo.uid);
    //             });
    //             resolve(zipcodes.length);
    //         })
    //         .catch(err => {
    //         });
    //     });
    //     return promise;
    // }

}

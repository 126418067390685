import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@shared/servicesCommon/auth.service';

@Injectable({
    'providedIn': 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
                                    Observable<boolean> | Promise<boolean> | boolean {
        // const loggedIn = false; // replace with actual user auth checking logic
        const loggedIn = this.authService.isAuthenticatedNew();

        loggedIn.then((_loggedIn) => {
            if(!_loggedIn){
                this.router.navigate(['/']);
            }
        });
        // If no one is logged in navigate to the root.
        // if (!loggedIn) {
        //     this.router.navigate(['/']);
        // }
        // } else {
        //     this.router.navigate(['Dashboard']);
        // }

        return loggedIn;
    }
}

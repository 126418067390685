import { Component, OnInit, OnDestroy, Injector, HostListener } from '@angular/core';
import { Platform, NavController, MenuController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { menuController } from '@ionic/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Constants } from '@shared/models/index';;
import { PermissionsService } from './services/permissions.service';
import { AuthService } from '@shared/servicesCommon/auth.service';
import { ViewBasePage } from '@shared/pagesCommon/index';
// import { initializeAppCheck, Recaptcha } from 'firebase/app-check';
import firebase from 'firebase/app';
import 'firebase/app-check';

@Component({
    'selector': 'app-root',
    'templateUrl': './app.component.html',
    'styleUrls': ['./app.component.scss']
})
export class AppComponent extends ViewBasePage implements OnInit, OnDestroy {
    public vm: any = {
        'version': Constants.APPLICATION_VERSION,
        'dealershipName': '',
        'user': {
            'firstName': '',
            'role': '',
            'uid': ''
        }
    };
    public rootPage = 'WelcomePage';
    public activePageTitle = 'Dashboard';
    private menuController: typeof menuController;
    private previousMenuitemTitle = '';

    private expiredPages = [
        { title: 'Account',      url: 'account',         icon: 'card',                       testid: 'account'    },
        { title: 'About',        url: 'about',           icon: 'information-circle-sharp',   testid: 'about'      },
        { title: 'Logout',       url: '',                icon: 'close-sharp',                testid: 'logout'     }
    ];
    private guestPages = [
        { title: 'Tickets',      url: 'tickets',         icon: 'pricetags-sharp',            testid: 'tickets'    },
        { title: 'Logout',       url: '',                icon: 'close-sharp',                testid: 'logout'     }
    ];
    private serviceAdvisorPages = [
        { title: 'Dashboard',    url: 'dashboard',       icon: 'stats-chart-sharp',          testid: 'dashboard'  },
        { title: 'Tickets',      url: 'tickets',         icon: 'pricetags-sharp',            testid: 'tickets'    },
        { title: 'Tracker',      url: 'tracker',         icon: 'locate-sharp',               testid: 'tracker'    },
        { title: 'Settings',     url: 'userSettings',    icon: 'construct-sharp',            testid: 'userSettings' },
        { title: 'Account',      url: 'account',         icon: 'card',                       testid: 'account'    },
        { title: 'About',        url: 'about',           icon: 'information-sharp',          testid: 'about'      },
        { title: 'Help',         url: 'help',            icon: 'help-sharp',                 testid: 'help'       },
        { title: 'Logout',       url: '',                icon: 'close-sharp',                testid: 'logout'     }
    ];
    private driverPages = [
        { title: 'Dashboard',    url: 'dashboard',       icon: 'stats-chart-sharp',          testid: 'dashboard'  },
        { title: 'Routes',       url: 'routes',          icon: 'color-wand-sharp',           testid: 'routes'     },
        { title: 'Navigation',   url: 'navigation',      icon: 'map-sharp',                  testid: 'navigation' },
        { title: 'Settings',     url: 'userSettings',    icon: 'construct-sharp',            testid: 'userSettings' },
        { title: 'Account',      url: 'account',         icon: 'card',                       testid: 'account'    },
        { title: 'About',        url: 'about',           icon: 'information-sharp',          testid: 'about'      },
        { title: 'Help',         url: 'help',            icon: 'help-sharp',                 testid: 'help'       },
        { title: 'Logout',       url: '',                icon: 'close-sharp',                testid: 'logout'     }
    ];
    private managerPages = [
        { title: 'Dashboard',    url: 'dashboard',       icon: 'stats-chart-sharp',          testid: 'dashboard'  },
        { title: 'Tickets',      url: 'tickets',         icon: 'pricetags-sharp',            testid: 'tickets'    },
        { title: 'Routes',       url: 'routes',          icon: 'color-wand-sharp',           testid: 'routes'     },
        { title: 'Navigation',   url: 'navigation',      icon: 'map-sharp',                  testid: 'navigation' },
        { title: 'Tracker',      url: 'tracker',         icon: 'locate-sharp',               testid: 'tracker'    },
        { title: 'Marketing',    url: 'marketing',       icon: 'cash-sharp',                 testid: 'marketing'  },
        { title: 'Reports',      url: 'reports/report1Type',         icon: 'documents-sharp',            testid: 'reports'    },
        { title: 'Settings',     url: 'managerSettings', icon: 'construct-sharp',            testid: 'managerSettings' },
        { title: 'Account',      url: 'account',         icon: 'card',                       testid: 'account'    },
        { title: 'About',        url: 'about',           icon: 'information-sharp',          testid: 'about'      },
        { title: 'Help',         url: 'help',            icon: 'help-sharp',                 testid: 'help'       },
        { title: 'Logout',       url: '',                icon: 'close-sharp',                testid: 'logout'     },
        { title: 'Close',        url: '',                icon: '',                           testid: 'close'      }
    ];
    public appPages = [];


    // <ion-icon name="information-circle-sharp"></ion-icon>
    // private splashScreen: SplashScreen, private statusBar: StatusBar, 
    constructor(private platform: Platform, private navController: NavController,
                private router: Router, public authService: AuthService, private storage: Storage,
                private permissionsService: PermissionsService, injector: Injector) {
        super(injector);
        this.initializeApp();
        this.menuController = menuController;
    }

    initializeApp() {
        // if (this.platform.is('cordova')) {
        // this.platform.ready().then(() => {
        //     // this.statusBar.styleDefault();
        //     // this.splashScreen.hide();

        //     // timer(3000).subscribe(() => {
        //     //     this.showSplash = false;
        //     // });
        // });
        // }

        // If running on a device, then remove the splash screen after 10 seconds.
        // Otherwise, remove the splash immediately.
        // const onDevice = this.platform.is('cordova');
        // const removeSplashMillisecs = (onDevice ? 10000 : 0);
        // timer(removeSplashMillisecs).subscribe(async () => {
        //     this.showSplash = false;
        // });
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.eventsService.subscribe(Constants.EVENT_LOGIN, () => { this.onLoginEvent(); });
        this.eventsService.subscribe(Constants.EVENT_PAGE_LOADED, (viewName) => { this.onPageLoadedEvent(viewName); });
        this.eventsService.subscribe(Constants.EVENT_INTERNET_CONNECTION_CHANGE, (connected) => { this.onInternetConnectionChange(connected);});

        // this.vm.version = Constants.APPLICATION_VERSION;
        const appCheck = firebase.appCheck();
        appCheck.activate('6LeYY-MhAAAAACgciljA2EXNAnLYftQI4edEqIto', true);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.unsubscribeAll();
    }

    // This routine will only be used by Cypress to reset to an initial known state.
    @HostListener( 'window:keydown', [ '$event' ] )
    handleKeyboardEventkeydown(event: KeyboardEvent) { 
        if (this.sessionService && this.sessionService.isDebugMode()) {
            const altKey   = event.altKey;
            const ctrlKey  = event.ctrlKey;
            const shiftKey = event.shiftKey;
            const code     = event.code;

            // console.log(`altKey=${altKey}, ctrlKey=${ctrlKey}, shiftKey=${shiftKey}, code=${code}`);
            if (shiftKey && ctrlKey && altKey && code === 'Backslash') {
                this.router.navigateByUrl('/dashboard');
            }

            if (shiftKey && ctrlKey && altKey && code === 'F4') {
                this.closeSidemenu();
            }

        }         
    }

    // private onMenuItemClick(menuItemTitle) {
    //     // if (!page.component) {
    //     if (menuItemTitle === 'Logout') {
    //         this.events.publish(Constants.EVENT_LOGOUT);    // Handled by login.ts
    //     } else {
    //         this.connectivityService.getInternetConnected()
    //         .then(() => {
    //             // Get the page component that is associated with this menuitem.
    //             let page: any = this.pages.filter(p => {
    //                 return (p.title === menuItemTitle);
    //             });
    //             // Reset the content nav to have just this page
    //             // we wouldn't want the back button to show in this scenario
    //             // page.component = item array.component -->
    //             // this.nav.setRoot(page.component);

    //             this.nav.setRoot(page[0].component)
    //             .then(() => {
    //                 // close-sharp the side menu.
    //                 this.menuCtrl.close-sharp();
    //             })
    //             .catch(err => {
    //                 // This acts as a global error handler!
    //                 let text = err.message || err;
    //                 this.uxHelperService.showAlert('Unknown Error', text);
    //                 console.error(text);
    //             });
    //         })
    //         .catch((err) => {
    //             this.uxHelperService.showAlert('Error', err.message);
    //         });
    //     }
    // }

    public hideMenuitem(menuitemTitle: string): boolean {
        if (!menuitemTitle) {
            return false;
        }
        const menuitem = this.appPages.find((page: any) => {
            return (page.title === menuitemTitle);
        });
        return !menuitem;
    }

    public async onMenuItemClick(menuitemTitle: string): Promise<void> {
        try {
            //
            this.previousMenuitemTitle = menuitemTitle;

            await this.uxHelperService.showSpinner();
            const navigateToPage = this.appPages.find((page: any) => {
                return (page.title === menuitemTitle);
            });
            if (navigateToPage.url) {
                this.activePageTitle = navigateToPage.title;
                this.router.navigateByUrl(navigateToPage.url);

                // Need to force the close because sometimes it stays open and screws up Cypress.
                this.closeSidemenu();
            } else {
                this.confirmLogout();
            }
        } catch (err) {
            this.uxHelperService.showAlert('Error', err.message);
        } finally {
            await this.uxHelperService.hideSpinner();
        }
    }

    public onGoBack(): void {
        if (this.previousMenuitemTitle) {
            this.onMenuItemClick(this.previousMenuitemTitle);
        }
    }

    private closeSidemenu(): void {
        // Need to force the close because sometimes it stays open and screws up Cypress.
        this.menuController.close();
    }
    // In the sidemenu, this will highlight the proper menuitem.  
    public checkActivePage(menuitemTitle: string): boolean {
        const highlightThisMenuitem = (this.activePageTitle === menuitemTitle);
        return highlightThisMenuitem;
    }

    // Q) Why is the window.location.reload() here?
    // A) https://medium.com/@dalenguyen/handle-missing-or-insufficient-permissions-firestore-error-on-angular-or-ionic-bf4edb7a7c68
    public confirmLogout() {
        const title = 'Confirm Logout';
        const message = 'Are you sure you want to log out?';
        this.uxHelperService.showConfirmationAlert(title, message, async (response) => {
            if (response.selection) {
                await this.authService.logout();
                this.activePageTitle = null;
                this.navController.navigateRoot('/welcome');

                window.location.reload();
            }
        });
    }

    // 1) If the account is expired then show the expired view
    // 2) If the dealership has not made a payment yet, then show the introduction view which is a mini sales page.
    // 3) If the role is 'Accountant' show the Accounts Page
    private async onLoginEvent() {
        let startingPage = '';
        const user = this.sessionService.getCurrentUser();

        this.vm.dealershipName = this.sessionService.getDealershipName();
        this.vm.user = user;
        // this.vm.firstName = user.firstName;
        // this.vm.role = user.role;
        const role = user.role;
        const status = this.sessionService.getAccountStatus();

        switch (status) {
            case 'Cancelled By Customer':
            case 'Cancelled By Support':
            case 'Trial Period Expired':
            case 'Package Expired':
                // TODO: Create a cancelled page. For now just use the expired page.
                this.appPages = this.expiredPages;
                // this.router.navigateByUrl('accessDenied');
                startingPage = 'accessDenied';
                break;

            case 'In Trial Period':
            case 'Active':
                switch (role) {
                    case 'serviceAdvisor':
                        this.appPages = this.serviceAdvisorPages;
                        break;
    
                    case 'driver':
                        this.appPages = this.driverPages;
                        break;
    
                    case 'manager':
                        this.appPages = this.managerPages;
                        break;
    
                    default:
                        throw Error('Invalid user role.');
                }
                // this.router.navigateByUrl('dashboard');
                startingPage = 'dashboard';
        }
                                
        // const restoreLastActivePage = this.sessionService.getSetting()?.misc.restoreLastActivePage || false;
        const restoreLastActivePage = false;
        if (restoreLastActivePage) {
            const lastActivePage = await this.storage.get('lastActivePage');
            this.router.navigateByUrl('/' + lastActivePage);
        } else {
            this.router.navigateByUrl(startingPage);
        }

        // Permissions
        const authorized = await this.permissionsService.getPermission(role);
        if (!authorized) {
            this.router.navigateByUrl('accessDenied');
            return;
        }       
    }

    private async onPageLoadedEvent(viewName: string) {
        const item = this.managerPages.find((page) => {
            const found = (page.title.toLowerCase() === viewName.toLowerCase());
            return found;
        });
        if (item) {
            this.activePageTitle = item.title;  // viewName;

            // Remember the last page we visited so we can restore it later in the welcome page.
            await this.storage.set('lastActivePage', item.url);
        }
    }

    private onInternetConnectionChange(connected) {
        const msg = (connected) ? 'Internet Connection Available.' : 'Internet Connection Not Available.';
        this.uxHelperService.showToast(msg);
    }

    private unsubscribeAll() {
        this.eventsService.unsubscribe(Constants.EVENT_LOGIN);
        this.eventsService.unsubscribe(Constants.EVENT_LOGOUT);
        this.eventsService.unsubscribe(Constants.EVENT_LOGGED_IN_USER_CHANGED);
        this.eventsService.unsubscribe(Constants.MAINTENANCE_HOURS);

    }
}

import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { LoadingOptions, ToastOptions } from '@ionic/core';
// import { HTMLIonLoadingElement } from '@ionic/angular';
// import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';
import { Howl, /* Howler */ } from 'howler';

// import { NativeAudio }                 from '@ionic-native/native-audio';
// import { Howl } from 'Howler';
// import 'Howler';    // this works
// ptb import { Howl } from 'howler';

@Injectable({
    'providedIn': 'root'
})
export class UxHelperService {
    private spinners: any[] = [];
    private sound: any;

    constructor(private alertController: AlertController, private toastCtrl: ToastController,
        private loadingController: LoadingController) {
    }

    //#region Alerts
    public async showAlert(title: string, message: string, callback: any = null) {
        const alert = await this.alertController.create({
            'header': title,
            'message': message,
            'buttons': [
                // {
                //     'text': 'Cancel',
                //     'role': 'cancel',
                //     'cssClass': 'secondary',
                //     'handler': () => {
                //         callback({ 'selection': false });
                //     }
                // },
                {
                    'text': 'Okay',
                    'role': 'okay',
                    'handler': () => {
                        if (callback) {
                            callback({ 'selection': true });
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    // public async showAlertOriginal(title: string, message: string) {
    //     const promise = new Promise(async (resolve, reject) => {
    //         await this.showAlert2(title, '', message);
    //         resolve(true);
    //     });
    //     return promise;
    // }

    // private async showAlert2(title: string, subTitle: string, message: string) {
    //     const promise = new Promise(async (resolve, reject) => {
    //         const alert = await this.alertController.create({   // try it without the await
    //             'header': title,
    //             'subHeader': subTitle,
    //             'message': message,
    //             'buttons': ['Ok']
    //         });
    //         await alert.present();
    //         resolve(true);
    //     });
    //     return promise;
    // }

    public async showConfirmationAlert(title: string, message: string, callback: any) {
        const alert = await this.alertController.create({
            'header': title,
            'message': message,
            'buttons': [
                {
                    'text': 'Cancel',
                    'role': 'cancel',
                    'cssClass': 'secondary',
                    'handler': () => {
                        callback({ 'selection': false });
                    }
                },
                {
                    'text': 'Okay',
                    'role': 'okay',
                    'handler': () => {
                        callback({ 'selection': true });
                    }
                }
            ]
        });
        await alert.present();
    }

    public async showRadioAlert(title: string, list: Array<string>, checkedItem: string, callback: any) {
        const inputs: any = [];

        list.forEach((item) => {
            const check = (item === checkedItem) ? true : false;
            const input = {
                'type': 'radio',
                'label': item,
                'value': item,
                'checked': check
            };
            inputs.push(input);
        });

        const options = {
            'header': title,
            'inputs': inputs,
            'buttons': [
                {
                    'text': 'Cancel',
                    'role': 'cancel',
                    'cssClass': 'secondary',
                    'handler': () => {
                        if (callback) {
                            callback({ 'selection': null });
                        }
                    }
                }, {
                    'text': 'Ok',
                    'handler': (data: any) => {
                        if (callback) {
                            callback({ 'selection': data });
                        }
                    }
                }
            ]
        };

        const alert = await this.alertController.create(options);

        await alert.present();
    }

    public async showCheckboxAlert(title: string, list: Array<string>, checkedItem: string, callback: any) {
        const inputs: any = [];

        list.forEach((item) => {
            const check = (item === checkedItem) ? true : false;
            const input = {
                'type': 'checkbox',
                'label': item,
                'value': item,
                'checked': check
            };
            inputs.push(input);
        });

        const options = {
            'inputs': inputs,
            'buttons': [
                {
                    'text': 'Cancel',
                    'role': 'cancel',
                    'cssClass': 'secondary',
                    'handler': () => {
                        if (callback) {
                            callback({ 'selection': null });
                        }
                    }
                }, {
                    'text': 'Ok',
                    'handler': (data: string) => {
                        if (callback) {
                            callback({ 'selection': data });
                        }
                    }
                }
            ]
        };

        const alert = await this.alertController.create(options);
        // alert.title = title;
        await alert.present();
    }

    //#endregion

    //#region Spinner Section
    // private push(item: any): void {
    //     // if (this.spinners..size() === this.capacity) {
    //     //   throw Error("Stack has reached max capacity, you cannot add more items");
    //     // }
    //     this.spinners.push(item);
    // }

    // private pop(): Promise<any> | undefined {
    //     return this.spinners.pop();
    // }

    public async showSpinner(msg: string = ''): Promise<void> {
        try {
            if (this.spinners.length > 0) {
                return;
            }
            
            msg += '  Please wait a moment...';
            msg = msg.trim();
            
            // if (!msg) {
            //     msg = 'Please wait...';
            // }
    
            const options: LoadingOptions = {
                'message': msg,
                'spinner': 'crescent',
                'cssClass': 'mySpinnerOptions'
            };
    
            // Holding the spinner in an array, allows nested spinners to be created.
            // For an example of nested spinners, see the dealership.load() and dealership.onZipcodeChange().
            const spinner = await this.loadingController.create(options);
            this.spinners.push(spinner);
            spinner.present();
        } catch (err) {
            throw err;
        }
    }

    public async hideSpinner(): Promise<void> {
        try {
            const spinner = this.spinners.pop();
            if (spinner) {
                await spinner.dismiss();
            }
    
            // if (this.spinner) {
            //     await this.spinner.dismiss();
            //     this.spinner = null;
            // }    
        } catch (err) {
            throw err;
        }
    }

    //#region Misc Section

    public verifyValue(fieldValue: any, errMsg: string) {
        if (fieldValue === undefined || fieldValue === null || fieldValue.length === 0) {
            this.showAlert('Error', errMsg);
            return false;
        }

        return true;
    }

    /// start new
    // public async presentAlertPrompt() {
    //     const alert = await this.alertController.create({
    //       cssClass: 'my-custom-class',
    //       header: 'Prompt!',
    //       inputs: [
    //         {
    //           name: 'name1',
    //           type: 'text',
    //           placeholder: 'Placeholder 1'
    //         },
    //         {
    //           name: 'name2',
    //           type: 'text',
    //           id: 'name2-id',
    //           value: 'hello',
    //           placeholder: 'Placeholder 2'
    //         },
    //         // multiline input.
    //         {
    //           name: 'paragraph',
    //           id: 'paragraph',
    //           type: 'textarea',
    //           placeholder: 'Placeholder 3'
    //         },
    //         {
    //           name: 'name3',
    //           value: 'http://ionicframework.com',
    //           type: 'url',
    //           placeholder: 'Favorite site ever'
    //         },
    //         // input date with min & max
    //         {
    //           name: 'name4',
    //           type: 'date',
    //           min: '2017-03-01',
    //           max: '2018-01-12'
    //         },
    //         // input date without min nor max
    //         {
    //           name: 'name5',
    //           type: 'date'
    //         },
    //         {
    //           name: 'name6',
    //           type: 'number',
    //           min: -5,
    //           max: 10
    //         },
    //         {
    //           name: 'name7',
    //           type: 'number'
    //         },
    //         {
    //           name: 'name8',
    //           type: 'password',
    //           placeholder: 'Advanced Attributes',
    //           cssClass: 'specialClass',
    //           attributes: {
    //             maxlength: 4,
    //             inputmode: 'decimal'
    //           }
    //         }
    //       ],
    //       buttons: [
    //         {
    //           text: 'Cancel',
    //           role: 'cancel',
    //           cssClass: 'secondary',
    //           handler: () => {
    //           }
    //         }, {
    //           text: 'Ok',
    //           handler: () => {
    //           }
    //         }
    //       ]
    //     });
    //     await alert.present();
    //   }


    // public async showConfirmationAlert(title: string, message: string, callback: any) {
    public async promptForString(header: string, msg: string, defaultValue: string,
        callback: any) {
        // const opts: ToastOptions = {
        //     'message': message,
        //     'duration': 2500,
        //     'position': 'bottom',
        //     'animated': true,
        //     'cssClass': 'myToastOptions'
        // };
        // const toast = await this.toastCtrl.create(opts);
        // toast.present();

        const opts: any = {
            'cssClass': 'myPromptForString',
            'header': header,
            'subheader': 'subheader',
            'message': msg,
            'inputs': [
                {
                    'name': 'value',
                    'type': 'text',
                    'message': msg,
                    'value': defaultValue,
                    // placeholder: 'Placeholder 2'
                },
            ],
            'buttons': [
                {
                    'text': 'Cancel',
                    'role': 'cancel',
                    'cssClass': 'secondary',
                    'handler': () => {
                        callback({ 'selection': null });

                    }
                },
                {
                    'text': 'Ok',
                    'handler': (data: any) => {
                        callback({ 'selection': data });
                    }
                }
            ]
        };

        const alert = await this.alertController.create(opts);
        await alert.present();
    }

    /// end new    
    // The selectables array is  an array of objects that have a 'name' column and a value.
    public convertArrayToSelectables(arr: string[]): any[] {
        const selectables = [];
        arr.forEach(function (item) {
            const obj = { 'name': item };
            selectables.push(obj);
        });

        return selectables;
    }
    //#endregion

    //#region Sound Section

    public playSound(filename: string): void {
        const source = [];
        source.push(filename);

        this.sound = new Howl({ 'src': source });
        this.sound.play();
    }

    public stopSound(): void {
        this.sound.stop();
    }

    public playSentSmsSound(): void {
        this.playSound('assets/audio/Flyby.mp3');
    }

    public playPickupAlert(): void {
        this.playBeep();
        // this.playSound('assets/audio/SundayChurchAmbiance.mp3');
    }

    public playTypingSound(): void {
        this.playSound('assets/audio/KeyboardTyping.mp3');
    }

    public playBeep(): void {
        this.playSound('assets/audio/Beep.mp3');

        // new Audio('assets/audio/Beep.mp3').play();

        // this.nativeAudio.preloadSimple('beepId', 'assets/audio/Beep.mp3')
        // .then(() => {
        //     this.nativeAudio.play('beepId');
        // })
        // .catch(err => {
        // });
    }

    //#endregion

    //#region Toast Section
    public async showToast(message: string, duration: number = 2500): Promise<void> {
        const options: ToastOptions = {
            'message': message,
            'duration': duration,
            'position': 'bottom',
            'animated': true,
            'cssClass': 'myToastOptions'
        };
        const toast = await this.toastCtrl.create(options);
        toast.present();
    }

    public async showToastWithCloseButton(message: string): Promise<void> {
        const options: ToastOptions = {
            'message': message,
            'cssClass': 'myToastOptionsWithClose',
            'buttons': [
                {
                    'text': 'Close',
                    'role': 'cancel',
                    'handler': () => {
                    }
                }
            ]
            // 'position': 'bottom',
            // 'animated': true,
        };
        const toast = await this.toastCtrl.create(options);
        toast.present();
    }

    //#endregion
}
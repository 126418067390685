<ion-header>
    <ion-toolbar color="primary">
        <ion-title>
            {{title}}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid>
        <ion-row class="header">
            <ion-col size="5">
                <ion-button color="primary" expand="block" (click)="onSignupDateClick()">Signup Date</ion-button>
            </ion-col>
            
            <ion-col size="2" class="ion-text-center">
                <h2> </h2>
            </ion-col>
            
            <ion-col size="5">
                <ion-button color="primary" expand="block" (click)="onTodayClick()">Today</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    
    <ion-datetime #myDatetime
        [presentation]="presentation"
        [min]="getMinimumDateISO()"
        [max]="getMaximumDateISO()"
        [value]="selectedDateISO"
        (ionChange)="onChange($event)">
    </ion-datetime>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button color="primary" expand="block" (click)="onCancelClick()">Cancel</ion-button>
                </ion-col>
                <ion-col>
                    <ion-button color="primary" expand="full" (click)="onOkayClick()">Okay</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>


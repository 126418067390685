import { Injectable } from '@angular/core';

import { CollectionService } from '@shared/servicesCommon/collection.service';
import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { TUserAccountMap } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class UserAccountMapsService {
    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService) {
    }

    public getByUid(uid: string): Promise<TUserAccountMap> {
        const collection = this.collectionService.getUserAccountMapsCol();
        const doc = this.firestoreService.getByUid(collection, uid);
        if (doc) {
            return doc;
        } else {
            const err = new Error(`uid = ${uid} not found in userAccountMaps.`);
            throw err;
        }
    }

    public getAll(): Promise<TUserAccountMap[]> {
        const collection = this.collectionService.getUserAccountMapsCol();
        return this.firestoreService.getAll(collection);
    }

    public getByFieldValue(fieldName: string, fieldValue: string): Promise<TUserAccountMap[]> {
        const collection = this.collectionService.getUserAccountMapsCol();
        return this.firestoreService.getByFieldValue(collection, fieldName, fieldValue);
    }
    
    public add(map: TUserAccountMap): Promise<TUserAccountMap> {
        const collection = this.collectionService.getUserAccountMapsCol();
        return this.firestoreService.set(collection, map);
    }
    
    public set(map: TUserAccountMap, uid: string): Promise<TUserAccountMap> {
        map.uid = uid;
        const collection = this.collectionService.getUserAccountMapsCol();
        return this.firestoreService.set(collection, map);
    }

    public update(map: TUserAccountMap): Promise<TUserAccountMap> {
        const collection = this.collectionService.getUserAccountMapsCol();
        return this.firestoreService.update(collection, map);
    }

    public delete(uid: string): Promise<void> {
        const collection = this.collectionService.getUserAccountMapsCol();
        return this.firestoreService.delete(collection, uid);
    }
}

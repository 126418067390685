import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
    'selector': 'app-checkboxModal',
    'templateUrl': './checkboxModal.page.html',
    'styleUrls': ['./checkboxModal.page.scss'],
})
export class CheckboxModalPage implements OnInit {
    // public data: any = {};
    public selectables: any = [];
    // public items: any = [];
    public title: string;
    public showTestCustomerButton: boolean;

    constructor(public modalController: ModalController, private navParams: NavParams) {
    }

    ngOnInit(): void {
        this.selectables = this.navParams.get('selectables');
        // this.items = this.navParams.get('items');
        this.title = this.navParams.get('title');
        this.showTestCustomerButton = this.navParams.get('showTestCustomerButton');
    }

    public onItemClick(item) {
        item.selected = !item.selected;
    }

    public onSelectNoneClick() {
        this.selectables.forEach((selectable) => {
            selectable.selected = false;
        });
    }

    public onSelectAllClick() {
        this.selectables.forEach((selectable) => {
            selectable.selected = true;
        });
    }

    public onSelectManagersClick() {
        this.selectables.forEach(selectable => {
            selectable.selected = !selectable.isCustomer;
        });
    }

    public onOkayClick() {
        const selectedItems = [];
        for (let idx = 0; idx < this.selectables.length; idx++) {
            if (this.selectables[idx].selected) {
                // selectedItems.push(this.items[idx]);
                selectedItems.push(this.selectables[idx]);
            }
        }
        const response = { 'okay': true, 'selectedItems': selectedItems };
        this.modalController.dismiss(response);
    }

    public onCancelClick() {
        const response = { 'okay': false, 'selectedItems': null };
        this.modalController.dismiss(response);
    }
}

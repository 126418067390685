import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import firebase from 'firebase/app';

import { DateService } from '@shared/servicesCommon/date.service';

@Injectable({
    'providedIn': 'root'
})
export class FirestoreService {
    private items: AngularFirestoreCollection;

    // private collectionService: CollectionService
    constructor(private afs: AngularFirestore, private dateService: DateService) {
    }

    public createUid(): string {
        const uid = this.afs.createId();
        return uid;
    }

    // Use add when I want Firestore to auto generate a unique uid for me.
    public add(collection: AngularFirestoreCollection, obj: any): any {
        obj.uid = this.afs.createId();
        return this.set(collection, obj);
    }
    
    // Use set when I want a specific uid name, e.g., 'accountProfile' or zipcode 91351
    public set(collection: AngularFirestoreCollection, obj: any): Promise<any> {
        // The set will fail if this is in the object.
        delete obj.$$hashKey;
        obj.createdDate = this.dateService.now();

        try {
            const promise = new Promise(async (resolve, reject) => {
                // const connection: any = await this.internetConnected();
                // if (connection.isConnected) {
                    // const options: firebase.firestore.SetOptions() 
                    collection.doc(obj.uid).set(obj);
                    resolve(obj);
                // }
            });

            return promise;
        } catch (err) {
            console.error('FirestoreService.set(); Error = ', err.message);
            throw err;
        }
    }

    // Use update when I want to update the object defined by the uid with just partial data.
    public update(collection: AngularFirestoreCollection, obj: any): any {
        try {
            delete obj.$$hashKey;
            obj.modifiedDate = this.dateService.now();

            const promise = new Promise(async (resolve, reject) => {
                // const connection: any = await this.internetConnected();
                // if (connection.isConnected) {
                    await collection.doc(obj.uid).update(obj);
                    resolve(obj);
                // }
            });
            return promise;
        } catch (err) {
            throw err;
        }
    }

    public unsubscribeListener(listener): void {
        if (listener) {
            listener();
            listener = null;
        }
    }

    // Return an array of all the items in this collection
    public getAll(collection: AngularFirestoreCollection /*, sortColumn?: string */): Promise<any> {
        const promise = new Promise( (resolve, reject) => {
            // this.internetConnected()
            // .then(() => {
            collection.ref.get().then((querySnapshot) => {
                const items = [];
                querySnapshot.forEach((doc) => {
                    const item = doc.data();
                    item.uid = doc.id;
                    items.push(item);
                });
                resolve(items);
            })
            .catch(err => {
                reject(err);
            });
        });
        return promise;
    }

    public get(ref: DocumentReference): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            try {
                ref.get().then((snapshot) => {
                    const item = (snapshot.exists) ? snapshot.data() : null;
                    if (item) {
                        item.uid = (snapshot.exists) ? snapshot.id : null;
                    }
                    resolve(item);
                });
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    public getByUid(collection: AngularFirestoreCollection, uid: string): Promise<any> {
        try {
            const ref = collection.doc(uid).ref;
            return this.get(ref);
        } catch (err) {
            throw err;
        }
    }

    public async getByFieldValue(collection: AngularFirestoreCollection, fieldName: string, fieldValue: any): Promise<any> {
        const query = await collection.ref.where(fieldName, '==', fieldValue);
        const snapshot = await query.get();

        const items = [];
        snapshot.forEach((doc) => {
            const item = doc.data();
            item.uid = doc.id;
            items.push(item);
        });
        return items;
    }

    // The incoming ref points to a node.  Save the incoming object with this key underneath this ref.
    // public save(collection: AngularFirestoreCollection, obj: any): Promise<{}> {
    //     // The save will fail if this is in the object.  If it's not in the object will the delete fail?
    //     delete obj.$$hashKey;
    //     const promise = new Promise(async (resolve, reject) => {
    //         // const connection: any = await this.internetConnected();
    //         // if (connection.isConnected) {
    //             collection.doc(obj.uid).update(obj);
    //             resolve(obj);
    //         // }
    //     });
    //     return promise;
    // }

    // The incoming ref points to a node.  Delete the node underneath this ref
    // that has this uid.
    // public removeChild(collection: AngularFirestoreCollection, uid: string): any {
    //     return this.delete(collection, uid);
    // }

    public delete(collection: AngularFirestoreCollection, uid: string): Promise<void> {
        return collection.doc(uid).delete();
    }

    public getFirestoreFieldValue() {
        return firebase.firestore.FieldValue;
    }

    public async removeAllChildren(collection: AngularFirestoreCollection): Promise<{}> {
        const promise = new Promise(async (resolve, reject) => {
            const items: any = await this.getAll(collection);
            items.forEach(item => {
                collection.doc(item.uid).delete();
            });
            resolve(true);
        });
        return promise;
    }

    public async writeToFirebaseStorage(filename: string, data: string) {
        const storageRef = firebase.storage().ref();
        const ref = storageRef.child(filename);

        const snapshot = await ref.putString(data);
        // While the file names are the same, the references point to different files
        // mountainsRef.name === mountainImagesRef.name            // true
        // mountainsRef.fullPath === mountainImagesRef.fullPath    // false
    }

    public async size(collection: AngularFirestoreCollection): Promise<number> {
        let size: number = 0;

        await collection.ref.get().then((snapshot) => {
            size = snapshot.size;
        });
        return size;
    }

    // public async internetConnected(): Promise<{}> {
    //     const collection = this.collectionService.getInternetConnectedCol();
    //     // const doc = collection.doc();

    //     const promise = new Promise(async (resolve, reject) => {
    //         // await collection.once('value', (snap) => {
    //         const ref = collection.ref;
    //         const item = await this.get(ref);       // collection.once('value', (snap) => {
    //         const isConnected = (snap.val() === true);
    //         const msg = (isConnected ? 'Internet connected.' : 'No internet connection.  Please try again later.');
    //         const obj = {'isConnected': isConnected, 'msg': msg};
    //         resolve(obj);
    //     });
    //     return promise;
    // }
}

<ion-header>
    <ion-toolbar>
        <ion-title>Pickup Request</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onDeclineClick()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
    <ion-list-header color="secondary">
        Declining Pickup In: {{ticket.timeRemaining}} seconds
    </ion-list-header>

    <ion-list class="ion-text-wrap">
        <ion-item>
            <ion-label class="ion-text-wrap">
                <span class="line1">Destination Name: {{ticket.locationName}}</span><br />
                <span class="line1">Destination: {{ticket.fromAddress}}</span>
            </ion-label>
        </ion-item>

        <ion-item>
            <ion-label class="ion-text-wrap">
                <span class="line2">Passenger ({{ticket.numPassengers}}): {{ticket.passengerName}}</span><br />
                <span class="line2">Priority: {{ticket.priorityStr}}</span><br />
                <span class="line2">Note: {{ticket.note}}</span>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>

<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col>
                <div>
                    <ion-button expand="full" (click)="onAcceptClick()">Accept</ion-button>
                </div>
            </ion-col>
            <ion-col>
                <div>
                    <ion-button expand="full" (click)="onDeclineClick()">Decline</ion-button>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
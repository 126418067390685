import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';

import { FirestoreService } from '@shared/servicesCommon/firestore.service';
import { CollectionService } from '@shared/servicesCommon/collection.service';
import { UserAccountMapsService } from '@shared/servicesCommon/userAccountMaps.service';
import { TAccountProfile } from '@shared/models/interfaces';
import { DateService } from './date.service';

@Injectable({
    'providedIn': 'root'
})
export class AccountProfileService {
    private documentName = 'accountProfile';

    constructor(private firestoreService: FirestoreService, private collectionService: CollectionService,
                private userAccountMapsService: UserAccountMapsService, private dateService: DateService) {
    }

    public getNullable(): TAccountProfile {
        const profile: TAccountProfile = {
            accountId: '',
            accountNumber: '',
            activationInfo: {},
            ambassadorInfo: {},
            creditInfo: {},
            debug: false,
            featuresInfo: {},
            fromSmsNumber: '',
            notes: '',
            // packageName: '',
            periodInfo: {
                expirationDate: this.dateService.createTimestamp(),
                startDate: this.dateService.createTimestamp(),
                premiumSupportExpirationDate: this.dateService.createTimestamp(),
                webHostingExpirationDate: this.dateService.createTimestamp()
            },
            phoneNumber: '',
            pricingInfo: {},
            sku: '',
            // status: '',
            // type: '',
            uid: '',
            packageName: 'Trial Package'
        };
        return profile;
    }

    private getAccountProfileReference() {
        const collection = this.collectionService.getProfilesCol();
        const accountProfileDoc = collection.doc(this.documentName);
        // this.accountProfileDoc = this.accountNumberDoc.collection('accountProfiles').doc('accountProfile');
        const ref = accountProfileDoc.ref;
        return ref;
    }

    public async get(): Promise<TAccountProfile> {
        const ref = this.getAccountProfileReference();
        let profile: TAccountProfile = await this.firestoreService.get(ref);
        // profile = this.computeFeatureDesc(profile);
        return profile;
    }

    // public getByUid(uid: string): Promise<TAccountProfile> {
    //     const collection = this.collectionService.getAccountProfilesCol();
    //     return this.firestoreService.getByUid(collection, uid);
    // }

    // public getByFieldValue(fieldName: string, fieldValue: string): Promise<TAccountProfile[]> {
    //     const collection = this.collectionService.getAccountProfilesCol();
    //     return this.firestoreService.getByFieldValue(collection, fieldName, fieldValue);
    // }

    public getProfileByAccountNumber(accountNumber: string): Promise<TAccountProfile> {
        this.collectionService.setAccountNumber(accountNumber);
        return this.get();
    }

    // public async getAccountProfileByAccountId(accountId: string) {
    //     try {
    //         const maps = await this.userAccountMapsService.getByFieldValue('accountId', accountId);
    //         // const documentService = new DocumentService();
    //         // const maps: any = await documentService.findDocumentByFieldValue('userAccountMaps/', 'accountId', accountId);
    //         if (!maps || maps?.length === 0) {
    //             throw new Error(`AccountId = ${{accountId}} not found.`);
    //         }

    //         const accountNumber = maps[0].accountNumber;
    //         return this.getProfileByAccountNumber(accountNumber);
    //         // const accountProfile = await this.getProfileByAccountNumber(accountNumber);
    //         // return accountProfile;
    //     } catch (err) {
    //         throw err;
    //     }
    // }

    public async getAccountProfileByAccountId(accountId: string): Promise<TAccountProfile> {
        try {
            const maps = await this.userAccountMapsService.getByFieldValue('accountId', accountId);
            // const documentService = new DocumentService();
            // const maps: any = await documentService.findDocumentByFieldValue('userAccountMaps/', 'accountId', accountId);
            if (!maps || maps?.length === 0) {
                throw new Error(`AccountId = ${{accountId}} not found.`);
            }

            const accountNumber = maps[0].accountNumber;
            const accountProfile = await this.getProfileByAccountNumber(accountNumber);
            return accountProfile;
        } catch (err) {
            throw err;
        }
    }


    // public getByFieldValue(fieldName: string, fieldValue: string): Promise<TAccountProfile[]> {
    //     const collection = this.collectionService.getAccountProfilesCol();
    //     return this.firestoreService.getByFieldValue(collection, fieldName, fieldValue);
    // }

    public async set(accountProfile: TAccountProfile): Promise<TAccountProfile>  {
        accountProfile.uid = this.documentName;
        const collection = this.collectionService.getProfilesCol();
        return this.firestoreService.set(collection, accountProfile);
    }

    public async update(accountProfile: TAccountProfile): Promise<TAccountProfile> {
        const collection = this.collectionService.getProfilesCol();
        return this.firestoreService.update(collection, accountProfile);
    }

//     public computeFeatureDesc(profile: TAccountProfile): TAccountProfile {
// return;
//         if (!profile) {
//             return null;
//         }

//         let desc: string;
//         desc  = 'Efficiency Package: ';
//         desc += profile.featuresInfo.efficiency ? 'Enabled.' : 'Disabled.';
//         // profile.efficiencyDesc = desc;

//         desc  = 'Customer Communication Package: ';
//         desc += profile.featuresInfo.customer ? 'Enabled.' : 'Disabled.';
//         // profile.customerDesc = desc;

//         desc  = 'Campaign Package: ';
//         desc += profile.featuresInfo.campaign ? 'Enabled.' : 'Disabled.';
//         // profile.campaignDesc = desc;

//         return profile;
//     }
}

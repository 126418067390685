import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { TPopoverMenuItem } from '@shared/models/interfaces';

// https://www.youtube.com/watch?v=jRxPOs1OM34
@Component({
    'selector': 'app-popoverMenu',
    'templateUrl': './popoverMenu.menu.html',
    'styleUrls': ['./popoverMenu.menu.scss'],
})
export class PopoverMenu implements OnInit {
    public menuItems: any = [];

    constructor(private navParams: NavParams, private popoverController: PopoverController) {
    }

    ngOnInit(): void {
        this.menuItems = this.navParams.get('menuItems');    
    }

    // We are going to add a data-test attribute to every menuitem.
    // We have configured Usetiful to start showing a tour when a button
    // with a specific data-test is clicked.
    // For example, the Dashboard tour is configured to look for an element
    // with the data attribute set to 'show-dashboard-tour'.
    // So as long as the menuitem name matches the tour name, the tour
    // should launch when the menuitem is clicked. 
    public getDataTestId(menuItem: TPopoverMenuItem): string {
        // If we were passed in a testId then use it.
        if (menuItem.testId) {
            return menuItem.testId;
        }

        // We weren't passed in a testId, so generate one from the name
        let testId = menuItem.name.toLowerCase();
        testId = testId.split(' ').join('-');
        return testId;
    }

    public getDataContent(menuItem: TPopoverMenuItem): string {
        let content = '';

        // If we were passed in a content string then use it.
        if (menuItem.content) {
            content = menuItem.content;
        }
        
        return content;
    }

    public onItemClick(item) {
        this.popoverController.dismiss({'selectedItem': item});
    }
    
    public onDismissClick() {
        this.popoverController.dismiss({'selectedItem': null});
    }
}

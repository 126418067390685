import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    { 
        path: '', 
        redirectTo: 'welcome', 
        pathMatch: 'full'
    },

    {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'accessDenied',
        loadChildren: () => import('./pages/accessDenied/accessDenied.module').then(m => m.AccessDeniedPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'accountProfile',
    //     loadChildren: () => import('./pages/accountProfile/accountProfile.module').then(m => m.AccountProfilePageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'cancel',
        loadChildren: () => import('./pages/cancel/cancel.module').then(m => m.CancelPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'contactUs',
    //     loadChildren: () => import('./pages/help/pages/contactUs/contactUs.module').then(m => m.ContactUSPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaigns',
    //     loadChildren: () => import('./pages/campaigns/campaigns.module').then(m => m.CampaignsPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaign',
    //     loadChildren: () => import('./pages/campaign/campaign.module').then(m => m.CampaignPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignIncentive',
    //     loadChildren: () => import('./pages/campaignIncentive/campaignIncentive.module').then(m => m.CampaignIncentivePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignIncentive/:uid',
    //     loadChildren: () => import('./pages/campaignIncentive/campaignIncentive.module').then(m => m.CampaignIncentivePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignIncentives',
    //     loadChildren: () => import('./pages/campaignIncentives/campaignIncentives.module').then(m => m.CampaignIncentivesPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignIncentiveSet',
    //     loadChildren: () => import('./pages/campaignIncentiveSet/campaignIncentiveSet.module').then(m => m.CampaignIncentiveSetPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignIncentiveSet/:uid',
    //     loadChildren: () => import('./pages/campaignIncentiveSet/campaignIncentiveSet.module').then(m => m.CampaignIncentiveSetPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignIncentiveSets',
    //     loadChildren: () => import('./pages/campaignIncentiveSets/campaignIncentiveSets.module').then(m => m.CampaignIncentiveSetsPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignFormatSelector',
    //     loadChildren: () => import('./pages/campaignFormatSelector/campaignFormatSelector.module').then(m => m.CampaignFormatSelectorPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignHosting',
    //     loadChildren: () => import('./pages/campaignHosting/campaignHosting.module').then(m => m.CampaignHostingPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'campaignMessage',
    //     loadChildren: () => import('./pages/campaignMessage/campaignMessage.module').then(m => m.CampaignMessagePageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'campaignOptout',
        loadChildren: () => import('./pages/campaignOptout/campaignOptout.module').then(m => m.CampaignOptoutPageModule),
        // canActivate: [AuthGuard]
    },
    // {
    //     path: 'campaignTestCustomer',
    //     loadChildren: () => import('./pages/campaignTestCustomer/campaignTestCustomer.module').then(m => m.CampaignTestCustomerPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'channels',
    //     loadChildren: './pages/channels/channels.module#ChannelsPageModule',
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'customers',
    //     loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'dealership',
    //     loadChildren: () => import('./pages/managerSettings/pages/dealership/dealership.module').then(m => m.DealershipPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'dropoffAllAboard',
    //     loadChildren: () => import('./pages/managerSettings/pages/dropoffAllAboard/dropoffAllAboard.module').then(m => m.DropoffAllAboardPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'dropoffApproaching',
    //     loadChildren: () => import('./pages/managerSettings/pages/dropoffApproaching/dropoffApproaching.module').then(m => m.DropoffApproachingPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'dropoffCompleted',
    //     loadChildren: () => import('./pages/managerSettings/pages/dropoffCompleted/dropoffCompleted.module').then(m => m.DropoffCompletedPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'feedback',
    //     loadChildren: () => import('./pages/help/pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'help',
        loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'introduction',
    //     loadChildren: () => import('./pages/help/pages/introduction/introduction.module').then(m => m.IntroductionPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
        // canActivate: [AuthGuard]
    },
    {
        path: 'managerSettings',
        loadChildren: () => import('./pages/managerSettings/managerSettings.module').then(m => m.ManagerSettingsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'marketing',
        loadChildren: () => import('./pages/marketing/marketing.module').then(m => m.MarketingPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'navigation',
        loadChildren: () => import('./pages/navigation/navigation.module').then(m => m.navigationPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'navigationPage1',
    //     loadChildren: () => import('./pages/navigation/pages/navigationPage1/navigationPage1.module').then(m => m.NavigationPage1PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'navigationPage2',
    //     loadChildren: () => import('./pages/navigation/pages/navigationPage2/navigationPage2.module').then(m => m.NavigationPage2PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'navigationPage3',
    //     loadChildren: () => import('./pages/navigation/pages/navigationPage3/navigationPage3.module').then(m => m.NavigationPage3PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'navigationPage4',
    //     loadChildren: () => import('./pages/navigation/pages/navigationPage4/navigationPage4.module').then(m => m.NavigationPage4PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'navigationPage5',
    //     loadChildren: () => import('./pages/navigation/pages/navigationPage5/navigationPage5.module').then(m => m.NavigationPage5PageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'pickupApproaching',
    //     loadChildren: () => import('./pages/managerSettings/pages/pickupApproaching/pickupApproaching.module').then(m => m.PickupApproachingPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'pickupArrived',
    //     loadChildren: () => import('./pages/managerSettings/pages/pickupArrived/pickupArrived.module').then(m => m.PickupArrivedPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'pickupCompleted',
    //     loadChildren: () => import('./pages/managerSettings/pages/pickupCompleted/pickupCompleted.module').then(m => m.PickupCompletedPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'pickupRequestModal',
        loadChildren: () => import('./modals/pickupRequestModal/pickupRequestModal.module').then(m => m.PickupRequestModalPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'privacyPolicy',
    //     loadChildren: () => import('./pages/about/pages/privacyPolicy/privacyPolicy.module').then(m => m.PrivacyPolicyPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'releaseNotes',
    //     loadChildren: () => import('./pages/about/pages/releaseNotes/releaseNotes.module').then(m => m.ReleaseNotesPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsPageModule),
        // loadChildren: () => import('./pages/reportsPage1/reportsPage1.module').then(m => m.ReportsPage1PageModule),
        // loadChildren: () => import('./pages/reports/reportsPage1.module').then(m => m.ReportsPage1PageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'reportsPage1',
    //     loadChildren: () => import('./pages/reportsPage1/reportsPage1.module').then(m => m.ReportsPage1PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'reportsPage2',
    //     loadChildren: () => import('./pages/reportsPage1/pages/reportsPage2/reportsPage2.module').then(m => m.ReportsPage2PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'reportsPage3',
    //     loadChildren: () => import('./pages/reportsPage1/pages/reportsPage3/reportsPage3.module').then(m => m.ReportsPage3PageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'routes',
        loadChildren: () => import('./pages/routes/routes.module').then(m => m.RoutesPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'routePage1',
    //     loadChildren: () => import('./pages/routePage1/routePage1.module').then(m => m.RoutePage1PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage2',
    //     loadChildren: () => import('./pages/routePage2/routePage2.module').then(m => m.RoutePage2PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage3',
    //     loadChildren: () => import('./pages/routePage3/routePage3.module').then(m => m.RoutePage3PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage4',
    //     loadChildren: () => import('./pages/routePage4/routePage4.module').then(m => m.RoutePage4PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage5',
    //     loadChildren: () => import('./pages/routePage5/routePage5.module').then(m => m.RoutePage5PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage6',
    //     loadChildren: () => import('./pages/routePage6/routePage6.module').then(m => m.RoutePage6PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage7',
    //     loadChildren: () => import('./pages/routePage7/routePage7.module').then(m => m.RoutePage7PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routePage8',
    //     loadChildren: () => import('./pages/routePage8/routePage8.module').then(m => m.RoutePage8PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'routeView',
    //     loadChildren: () => import('./pages/routes/pages/routeView/routeView.module').then(m => m.RouteViewPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'satisfactionPage1',
        loadChildren: () => import('./pages/satisfactionPage1/satisfactionPage1.module').then(m => m.SatisfactionPage1PageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'satisfactionPage2',
        loadChildren: () => import('./pages/satisfactionPage2/satisfactionPage2.module').then(m => m.SatisfactionPage2PageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'serviceAreas',
    //     loadChildren: () => import('./pages/managerSettings/pages/serviceAreas/serviceAreas.module').then(m => m.ServiceAreasPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'serviceAreaSingle',
    //     loadChildren: () => import('./pages/managerSettings/pages/serviceAreaSingle/serviceAreaSingle.module').then(m => m.ServiceAreaSinglePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'serviceAreaSingle/:uid',
    //     loadChildren: () => import('./pages/managerSettings/pages/serviceAreaSingle/serviceAreaSingle.module').then(m => m.ServiceAreaSinglePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'serviceAreaMultiple',
    //     loadChildren: () => import('./pages/managerSettings/pages/serviceAreaMultiple/serviceAreaMultiple.module').then(m => m.ServiceAreaMultiplePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'settingsCacheCreds',
    //     loadChildren: () => import('./pages/managerSettings/pages/settingsCacheLoginCreds/settingsCacheLoginCreds.module').then(m => m.SettingsCacheLoginCredsPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'shuttle',
    //     loadChildren: () => import('./pages/managerSettings/pages/shuttle/shuttle.module').then(m => m.ShuttlePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'shuttle/:uid',
    //     loadChildren: () => import('./pages/managerSettings/pages/shuttle/shuttle.module').then(m => m.ShuttlePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'shuttles',
    //     loadChildren: () => import('./pages/managerSettings/pages/shuttles/shuttles.module').then(m => m.ShuttlesPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'termsOfService',
    //     loadChildren: () => import('./pages/about/pages/termsOfService/termsOfService.module').then(m => m.TermsOfServicePageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'ticketCreated',
    //     loadChildren: () => import('./pages/managerSettings/pages/ticketCreated/ticketCreated.module').then(m => m.TicketCreatedPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'tickets',
        loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsPageModule),
        canActivate: [AuthGuard]
    },
    {   path: 'ticketsModal',
        loadChildren: () => import('./modals/ticketsModal/ticketsModal.module').then(m => m.TicketsModalPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'ticketPage1',
    //     loadChildren: () => import('./pages/ticketPage1/ticketPage1.module').then(m => m.TicketPage1PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'ticketPage1/:uid',
    //     loadChildren: () => import('./pages/ticketPage1/ticketPage1.module').then(m => m.TicketPage1PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'ticketPage2',
    //     loadChildren: () => import('./pages/ticketPage2/ticketPage2.module').then(m => m.TicketPage2PageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'ticketPage3',
    //     loadChildren: () => import('./pages/ticketPage3/ticketPage3.module').then(m => m.TicketPage3PageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'tracker',
        loadChildren: () => import('./pages/tracker/tracker.module').then(m => m.TrackerPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'trackerHistorical',
    //     loadChildren: () => import('./pages/tracker/pages/trackerHistoricalPage1/trackerHistoricalPage1.module').then(m => m.TrackerHistoricalPage1Module),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'trackerHistoricalPage1',
    //     loadChildren: () => 
    //         import('./pages/tracker/pages/tracker1HistoricalFilter/tracker1HistoricalFilter.module').then(
    //             (m) => m.Tracker1HistoricalFilterModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'trackerHistoricalPage2',
    //     loadChildren: () => import('./pages/tracker/pages/trackerHistoricalPage2/trackerHistoricalPage2.module').then(m => m.TrackerHistoricalPage2Module),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'trackerHistoricalPage3',
    //     loadChildren: () => import('./pages/tracker/pages/trackerHistoricalPage3/trackerHistoricalPage3.module').then(m => m.TrackerHistoricalPage3Module),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'trackerLive',
    //     loadChildren: () => import('./pages/tracker/pages/trackerLive/trackerLive.module').then(m => m.TrackerLivePageModule),
    //     // canActivate: [AuthGuard]
    // },
    // {
    //     path: 'order/:uid',
    //     loadChildren: () => import('./pages/account/pages/order/order.module').then(m => m.OrderPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'orders',
    //     loadChildren: () => import('./pages/account/pages/orders/orders.module').then(m => m.OrdersPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'tutorials',
    //     loadChildren: () => import('./pages/help/pages/tutorials/tutorials.module').then(m => m.TutorialsPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'user',
    //     loadChildren: () => import('./pages/managerSettings/pages/user/user.module').then(m => m.UserPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'user/:uid',
    //     loadChildren: () => import('./pages/managerSettings/pages/user/user.module').then(m => m.UserPageModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'users',
    //     loadChildren: () => import('./pages/managerSettings/pages/users/users.module').then(m => m.UsersPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'userSettings',
        loadChildren: () => import('./pages/userSettings/userSettings.module').then(m => m.UserSettingsPageModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'version',
    //     loadChildren: () => import('./pages/about/pages/version/version.module').then(m => m.VersionPageModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'welcome',
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    {
        path: 'campaignOptout',
        loadChildren: () => import('./pages/campaignOptout/campaignOptout.module').then(m => m.CampaignOptoutPageModule),
    },
    {
           path: 'showCampaign',
        loadChildren: () => import('./pages/showCampaign/showCampaign.module').then(m => m.ShowCampaignPageModule),
    },
    {
        // 404
        path: '**',
        loadChildren: () => import('./pages/notFound/notFound.module').then(m => m.NotFoundPageModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

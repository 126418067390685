import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

import { compareAsc, format, formatISO, parse, parseISO, isAfter, formatISODuration, getTime } from 'date-fns';
import { addSeconds, addMinutes, addHours, addDays, addMonths, addYears } from 'date-fns';
import { setHours, setMinutes, setSeconds, intervalToDuration, formatDuration } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { format as formatTZ } from 'date-fns-tz';

import { DateTimeFormats } from '@shared/models/constants';
import { TTimestamp } from '@shared/models/interfaces';
// import { Timestamp } from '@google-cloud/firestore';

// GMT is now known as UTC:
// Prior to 1972, this time was called Greenwich Mean Time (GMT) but is now referred to as 
// Coordinated Universal Time or Universal Time Coordinated (UTC). It is a coordinated time 
// scale, maintained by the Bureau International des Poids et Mesures (BIPM).
//
// ISO:
// The International Organization for Standardization (ISO) date and time format is a standard
// way to express a numeric calendar date -- and optionally time -- in a format that eliminates
// ambiguity between entities. The format, defined in the ISO 8601 standard, is used extensively 
// by applications and machines to exchange date and time data without the uncertainly that comes 
// when trying to communicate this data across international boundaries, diverse cultures or 
// different time zones.  For example, North Americans usually write the month before the date, 
// as in March 30, 2021 or 3/30/2021. However, Europeans typically write the date before the month, 
// as in 30 March 2021 or 30.3.2021. Even the separators between the numbers can vary. ISO 8601 
// provides an international standard that eliminates this type of confusion.  Date representations 
// in ISO 8601 are based on the Gregorian calendar, which replaced the Julian calendar in 1582 to 
// better accommodate the seasonal cycles. The ISO standard also considers leap years. Additionally, 
// it is based on a 24-hour clock (sometimes referred to as military time), and it accommodates 
// varying time zones and how they differ from Coordinated Universal Time (UTC). 


// @firebase/firestore-types'
// https://javascript.plainenglish.io/date-manipulation-in-js-made-easy-with-date-fns-6c66706a5874
@Injectable({
    'providedIn': 'root'
})
export class DateService {
    static localTimezone: string;
    private minimumDate = new Date('0001-01-01T00:00:00Z');

    constructor() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public createTimestamp(seconds: number = 0, nanoseconds: number = 0): TTimestamp {
        if (seconds === 0) {
            const now = this.now();
            const utc = this.timestampToUTC(now);
            const date = new Date(utc);
            seconds = date.getTime() / 1000;
        }
        
        // Use firebase.firestore if in servicesCommon/date.service.ts
        // Use firestore.Timestamp if in functions/date.service.ts
        const timestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);
        return timestamp;
    } 

    public now(): TTimestamp {
        const now = Date.now();
        const tsNow = this.toTimestamp(now);
        return tsNow;
    } 
    
    public getMinimumDate(): TTimestamp {
        const num = this.minimumDate.getUTCFullYear();
        const date = new Date(num);
        const timestamp = this.toTimestamp(date);
        return timestamp;
    }
    
    public isMinimumDate(ts: TTimestamp): boolean {
        if (!ts || ts.seconds === 0) {
            return true;
        }
        const minimumTimestamp = this.toTimestamp(this.minimumDate);
        const isEqual = (ts.seconds === minimumTimestamp.seconds);
        return isEqual;
    }

    // If using cloud function:  admin.firestore.Timestamp.fromDate()
    public toTimestamp(dt: Date | number | string): TTimestamp {
        let timestamp;

        switch (typeof dt) {
            case 'number':
            case 'string':
                const date1 = new Date(dt);
                timestamp = firebase.firestore.Timestamp.fromDate(date1);
                break;

            case 'object':
                timestamp = firebase.firestore.Timestamp.fromDate(dt);
                break;
        }
        return timestamp;
    }

    public toDate(timestamp: TTimestamp): Date {
        if (!timestamp) {
            throw new Error('Invalid parameter passed to toDate()');
        }
        // this doesn't belong here.  this is test code.
        // if (!timestamp.toDate) {
        //     // const ts = firebase.firestore.Timestamp.fromDate(timestamp);
        //     // const ts = this.toTimestamp(timestamp);
        //     // const ts = firebase.firestore.Timestamp.fromMillis(timestamp.seconds * 1000);
        //     // timestamp.getSeconds();
        //     const str = firebase.firestore.Timestamp.toString();  // ...fromMillis(timestamp.seconds * 1000);
        //     const dt: Date = timestamp.toDate();
        // }

        
        if (typeof timestamp.toDate === 'undefined') {
            // const dt: Date = timestamp.toDate();
            
            // const seconds = timestamp.seconds;
            const seconds = (timestamp as any)._seconds;
            const nanoseconds = (timestamp as any)._nanoseconds;
            const yyy = this.createTimestamp(seconds, nanoseconds);
            const date = yyy.toDate();

            // const ts = this.toTimestamp(seconds);
            // const date = this.toDate(ts);
            
            // const xxx = new Timestamp(seconds, nanoseconds);
            // const date = xxx.toDate();
            // const date = this.toDate(ts);

            // var date = new Date();      // (1970, 0, 1); // Epoch
            // date.setSeconds(seconds);
            return date;
            // var updated_at = firebase.firestore.Timestamp.fromDate(new Date());
        } else {
            const dt: Date = timestamp.toDate();
            return dt;
        }
    }

    public parse(datetime: string, format: string, setToMidnight: boolean = true): TTimestamp {
        let date;

        if (format === 'iso') {
            date = parseISO(datetime);
        } else {
            date = parse(datetime, format, new Date());
        }
        let timestamp = this.toTimestamp(date);
        if (setToMidnight) {
            timestamp = this.setHoursMinutesSeconds(timestamp, 23, 59, 59);
        }
        return timestamp;        
    }

    public timestampToUTC(timestamp: TTimestamp): string {
        // const str = this.formatTimestamp(timestamp, DateTimeFormats.UTC);
        const date = this.formatTimestamp(timestamp, DateTimeFormats.ShortDate);
        const time = this.formatTimestamp(timestamp, DateTimeFormats.ShortTime1);
        const str  = date + 'T' + time;
        return str;
        // const dateFromIonDatetime = '2021-06-04T14:23:00-04:00';
        // const formattedString = format(parseISO(dateFromIonDatetime), 'MMM d, yyyy');
    }

    // The incoming utcDatetime must be in this format:  DateTimeFormats.UTC = 'yyyy-MM-ddTHH:mm:ss'
    public UTCToTimestamp(utcDatetime: string): TTimestamp {
        // const dateFromIonDatetime = '2021-06-04T14:23:00-04:00';
        const date: Date = parseISO(utcDatetime);
        const timestamp = this.toTimestamp(date);
        return timestamp;
    }

    public isBeforeToday(timestamp: TTimestamp): boolean {
        const yesterdayTs   = this.addToNow(-1, 'days', true);
        const yesterdayFmt  = this.formatTimestamp(yesterdayTs, DateTimeFormats.ShortDate);
        const yesterdayDate = this.toDate(yesterdayTs);
        
        const date    = this.toDate(timestamp);
        const dateFmt = this.formatTimestamp(yesterdayTs, DateTimeFormats.ShortDate);
        const after = isAfter(yesterdayDate, date);
        return after; 
    }

    public isBeforeNow(timestamp: TTimestamp): boolean {
        const date  = this.toDate(timestamp);
        const now   = new Date(); 
        const after = isAfter(now, date);
        return after; 
    }

    public isAfterNow(timestamp: TTimestamp): boolean {
        const date = this.toDate(timestamp);
        const now  = new Date(); 
        const after = isAfter(date, now);
        return after; 
    }

    public formatTimestamp(timestamp: TTimestamp | string, pattern: string = DateTimeFormats.Full): string {
        let date;
        let dateString;

        try {
            if (!timestamp) {
                console.log('formatTimestamp called with invalid timestamp');
                return '';
            }

            switch (typeof timestamp) {
                case 'string':
                    if (timestamp === 'now') {
                        date = new Date();
                    }
                    break;
    
                case 'number':
                    const ts = this.toTimestamp(timestamp);
                    date = this.toDate(ts);
                    break;
    
                case 'object':
                    date = this.toDate(timestamp);
                    break;
    
                default:
                    console.log('formatTimestamp called with invalid timestamp');
                    debugger;
                    break;
            }
    
            if (pattern === 'iso') {
                dateString = formatISO(date);
            } else {
                dateString = format(date, pattern);
            }
            return dateString;    
        } catch (err) {
            console.error('Error in DateService: ', err);
            throw err;
        }
    }

    public addToNow(duration: number, unit: string, setToMidnight: boolean = true): TTimestamp {
        const now = this.now();
        const newTimestamp = this.add(now, duration, unit, setToMidnight);
        return newTimestamp;
    }

    public add(timestamp: TTimestamp, duration: number, unit: string, setToMidnight: boolean = true): TTimestamp {
        let date;
        
        date = this.toDate(timestamp);
        
        let newDate: Date;
        switch (unit) {
            case 'seconds':
                newDate = addSeconds(date, duration);
                break;

            case 'minutes':
                newDate = addMinutes(date, duration);
                break;

            case 'hours':
                newDate = addHours(date, duration);
                break;
            
            case 'days':
                newDate = addDays(date, duration);
                break;
                
            case 'months':
                newDate = addMonths(date, duration);
                break;
                    
            case 'years':
                newDate = addYears(date, duration);
                break;    
        }

        let newTimestamp = this.toTimestamp(newDate);
        if (setToMidnight) {
            newTimestamp = this.setHoursMinutesSeconds(newTimestamp, 23, 59, 59);
        }
        return newTimestamp;
    }

    public setHoursMinutesSeconds(timestamp: TTimestamp, numHours: number, numMinutes: number,
                                    numSeconds: number): TTimestamp {
        let date = this.toDate(timestamp);
        date = setHours(date, numHours);
        date = setMinutes(date, numMinutes);
        date = setSeconds(date, numSeconds);

        const newTimestamp = this.toTimestamp(date);
        return newTimestamp;
    }

    public setTimeSlot(timestamp: TTimestamp | string, slot: 'start' | 'end'): TTimestamp {
        let date: Date;
        let ts: TTimestamp;

        try {
            if (!timestamp) {
                throw new Error('formatTimestamp called with invalid timestamp');
            }

            switch (typeof timestamp) {
                case 'string':
                    if (timestamp === 'now') {
                        ts = this.now();
                    }
                    break;
                    
                case 'object':
                    ts = timestamp;
                    break;
                    
                default:
                    throw new Error('formatTimestamp called with invalid timestamp');
            }
            
            switch (slot) {
                case 'start':
                    ts = this.setHoursMinutesSeconds(ts, 0, 0, 0);
                    break;

                case 'end':
                    ts = this.setHoursMinutesSeconds(ts, 23, 59, 59);
                    break;
            }
            return ts;    
        } catch (err) {
            throw err;
        }

    }
    
    // currentDate
    get currentDate(): string {
        const str = this.formatTimestamp('now', 'PPP'); // 'LL';
        return str;
    }

    // CurrentTime
    get currentTime(): string {
        const str = this.formatTimestamp('now', 'h:mm a');
        return str;
    }


    // was Called by orders.ts and order.ts
    // public reformatDateTime(datetime: string): string {
    //     // // 2021-04-09 14:47:32.000000
    //     // const parsed = mom-ent(datetime, 'YYYY-MM-DD hh:mm:ss').format('MMM DD, YYYY - hh:mm a');
    //     // return parsed;
    //     return '';
    // }    

    public getDateValue(datetime: string): number {
        // // 2021-12-11 14:47:32.000000
        // const value = mom-ent(datetime, 'YYYY-MM-DD hh:mm:ss').valueOf();
        // return value;
        return 0;
    }    

    public formatTimeAndDuration(start: TTimestamp, end: TTimestamp): string {
        const timeStart = this.formatTimestamp(start, DateTimeFormats.LongDate);
        const timeEnd = this.formatTimestamp(end, DateTimeFormats.LongDate);
        const duration = this.formatDuration(start, end);
        const str = timeStart + ' (+' + duration + ')';
        return str;

        // const timeStart = this.formatTime(start);
        // const timeEnd = this.formatTime(end);
        // const duration = this.formatDuration(start, end);
        // const str = timeStart + ' (+' + duration + ')';
        // return str;
    }

    public computeDuration(start: TTimestamp, end: TTimestamp): Duration {
        const startDate = this.toDate(start);
        const endDate = this.toDate(end);
        const interval = { 'start': startDate, 'end': endDate };
        const duration = intervalToDuration(interval);
        return duration;
    }

    // Compare 11 February 1987 and 10 July 1989:
    // const result = compareAsc(new Date(1987, 1, 11), new Date(1989, 6, 10))
    //=> -1
    public compareDuration(duration1: Duration, duration2: Duration): number {
        const iso1 = formatISODuration(duration1);
        const timestamp1 = this.parse(iso1, 'iso', false);
        const date1 = this.toDate(timestamp1);
        
        const iso2 = formatISODuration(duration2);
        const timestamp2 = this.parse(iso2, 'iso', false);
        const date2 = this.toDate(timestamp2);

        const result = compareAsc(date1, date2);
        return result;
    }

    public formatDuration(start: TTimestamp, end: TTimestamp): string {
        if (!start || !end) {
            return '';
        }
        const startDate = this.toDate(start);
        const endDate = this.toDate(end);
        const interval = { 'start': startDate, 'end': endDate };
        const duration = intervalToDuration(interval);
        let str = formatDuration(duration);

        // In practice, we will never encounter a ticket duration with units of years, months, weeks or days.
        // So the replacement of those units are here for completeness sake. 
        str = str.replace(' years', 'y');
        str = str.replace(' months', 'm');
        str = str.replace(' weeks', 'w');
        str = str.replace(' days', 'd');
        str = str.replace(' hours', 'h');
        str = str.replace(' minutes', 'm');
        str = str.replace(' seconds', 's');

        return str;

        // Might be useful.
        // const numMins = Math.floor(seconds / 60);
        // const numHours = Math.floor(numMins / 60);
        // const numSecs = seconds - (numHours * 60 * 60) - (numMins * 60);
        // let str = '';
        // if (numHours > 1) {
        //     str += numHours.toString() + 'h';
        // }
        // if (numMins > 1) {
        //     str += numMins.toString() + 'm';
        // }
        // str += numSecs.toString() + 's';
        // return str
        // const numMinsRounded = Math.round(numMins * 10) / 10;
        // return numMinsRounded.toString();
        // const xxx = Math.round((numSecs * 60.0) / 60.0);
        // const yyy = Math.round(numSecs * 60.0 / 60.0);
        // const numMins1 = Math.round((numSecs * 60.0) / 60);
        // const numMins2 = Math.round(numSecs * 60.0) / 60;
        // var rounded = Math.round( number * 10 ) / 10;
        // const str = mend.diff(mstart, 'minutes').toString();
        // return str;
    }

    public datesEqual(ts1: TTimestamp, ts2: TTimestamp): boolean {
        if (!ts1 || !ts2) {
            return false;
        }
        const date1 = this.formatTimestamp(ts1, DateTimeFormats.ShortDate);
        const date2 = this.formatTimestamp(ts2, DateTimeFormats.ShortDate);
        const datesEqual = (date1 === date2);

        return datesEqual;
    }

    public getReadableDate(ts1: TTimestamp, format: string = DateTimeFormats.All): string {
        const isMinimum = this.isMinimumDate(ts1);

        // Apparently, in the Firebase Console when a Timestamp is set to minimum (Dec 31, 1969 4:00PM),
        // then in the typescript code we can compare this date to undefined!
        if (!ts1 || isMinimum) {
            return 'Unknown';
        }

        const str = this.formatTimestamp(ts1, format);
        return str;
    }

    // When this code is called from a Firebase Function it will return the server's timezone.
    // When this code is called from the client side it will return the local timezone.
    public getTimezone(): string {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('timezone = ', timezone);
        return timezone;
    }

    // Will this work???
    public setLocalTimezone(timezone: string): void {
        DateService.localTimezone = timezone;
    }

    public convertTimestampToToLocalTime(serverTimestamp: TTimestamp, localTimezone: string): void { // TTimestamp {
        console.log('localTimezone = ', localTimezone);
        const dt = zonedTimeToUtc('2020-10-13', 'America/Chicago');
    }

   public convertServerTimeToLocalTime(timestamp: TTimestamp): any { // TTimestamp {
    // public convertTimestampToToServerTime(): void { // TTimestamp {
        const tz = this.getTimezone();
        const date = this.toDate(timestamp);
        
        const dt1 = zonedTimeToUtc(date, 'America/Los_Angeles');
        const dt2 = zonedTimeToUtc(date, 'America/Denver');
        const dt3 = zonedTimeToUtc(date, 'America/Chicago');
        const dt4 = zonedTimeToUtc(date, 'America/New_York');

        const dt5 = utcToZonedTime(date, 'America/Los_Angeles');
        const dt6 = utcToZonedTime(date, 'America/Denver');
        const dt7 = utcToZonedTime(date, 'America/Chicago');
        const dt8 = utcToZonedTime(date, 'America/New_York');

        // const dt = zonedTimeToUtc('2020-10-13', 'America/Chicago');

        // const utcDate = zonedTimeToUtc('2018-09-01 18:01:36.386', 'Europe/Berlin')

        // Obtain a Date instance that will render the equivalent Berlin time for the UTC date
        // const date = new Date('2018-09-01T16:01:36.386Z')
        // const timeZone = 'Europe/Berlin'
        // const zonedDate = utcToZonedTime(date, timeZone)
        // zonedDate could be used to initialize a date picker or display the formatted local date/time

        // Set the output to "1.9.2018 18:01:36.386 GMT+02:00 (CEST)"
        // const pattern = 'd.M.yyyy HH:mm:ss.SSS \'GMT\' XXX (z)'
        // const output = format(zonedDate, pattern, { timeZone: 'Europe/Berlin' })
    }

    // https://stackoverflow.com/questions/58561169/date-fns-how-do-i-format-to-utc
    // public convertGMTToLocalTimezone_ORIGINAL() {
    //     // import { parseISO } from "date-fns";
    //     // import { format, utcToZonedTime } from "date-fns-tz";

    //     const time = "2019-10-25T08:10:00Z";

    //     const parsedTime = parseISO(time);
    //     console.log(parsedTime); // 2019-10-25T08:10:00.000Z

    //     const formatInTimeZone = (date, fmt, tz) =>
    //     format(utcToZonedTime(date, tz), 
    //             fmt, 
    //             { timeZone: tz });

    //     const formattedTime = formatInTimeZone(parsedTime, "yyyy-MM-dd kk:mm:ss xxx", "UTC");
    //     console.log(formattedTime); // 2019-10-25 08:10:00 +00:00
    // }

    public test(): void {
        // new Date() automatically gives a date expressed in the utc/gmt timezone. 
        const utcDatetime = new Date().toISOString();
        
        const timezones = [ 'GMT', 'Pacific/Honolulu', 'America/Los_Angeles', 'America/New_York' ];
        for (const timezone of timezones) {
            const zonedDate = utcToZonedTime(utcDatetime, timezone)
            const str = formatTZ(zonedDate, DateTimeFormats.Full);
            console.log({timezone, str});
        }
        
    }
    
    public convertUtcDatetimeToLocalDatetime(utcDatetime: string, format: string = DateTimeFormats.Full): any {
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = utcToZonedTime(utcDatetime, localTimezone);
        const timestamp = this.toTimestamp(zonedDate);
        const str = formatTZ(zonedDate, format);
        return {timestamp, str};
    }

    /*
    public test(): void {
        // new Date() automatically gives a date expressed in the utc/gmt timezone. 
        const utcDatetime = new Date().toISOString();
        
        const timezones = [ 'GMT', 'Pacific/Honolulu', 'America/Los_Angeles', 'America/New_York' ];
        for (const timezone of timezones) {
            const {timestamp, str} = this.convertUtcDatetimeToLocalDatetime(utcDatetime)
            // const zonedDate = utcToZonedTime(utcDatetime, timezone)
            // const str = formatTZ(zonedDate, DateTimeFormats.Full);
            console.log({timezone, str});
        }
        
    }

    */
    // public convertGMTToLocalTimezone(utcDate: string, timezone: string) {
    //     // import { parseISO } from "date-fns";
    //     // import { format, utcToZonedTime } from "date-fns-tz";

    //     const time = "2019-10-25T08:10:00Z";

    //     const parsedTime = parseISO(time);
    //     console.log(parsedTime); // 2019-10-25T08:10:00.000Z

    //     // const formatInTimeZone = (date, fmt, timezone) =>
    //     //     format(utcToZonedTime(date, timezone), 
    //     //         fmt, 
    //     //         { timeZone: timezone });

    //     // const formatInTimeZone = (date, fmt, timezone) =>
    //     // format(utcToZonedTime(date, timezone), 
    //     //     fmt, 
    //     //     { timeZone: timezone });
    
    //     const formattedTime = formatInTimeZone(parsedTime, "yyyy-MM-dd kk:mm:ss xxx", "UTC");
    //     console.log(formattedTime); // 2019-10-25 08:10:00 +00:00
    // }

}

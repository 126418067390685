<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{title}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list lines="none">
        <ion-item>
            <ion-label class="ion-text-wrap" position="stacked">Name</ion-label>
            <!-- <ion-input type="text" [(ngModel)]="address.locationName"></ion-input> -->
            <ion-input type="text" [(ngModel)]="location.locationName"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label class="ion-text-wrap" position="stacked">Street Address</ion-label>
            <!-- <ion-input type="text" [(ngModel)]="address.streetAddress" (ionChange)="onAddressChange()"> -->
                <ion-input type="text" [(ngModel)]="location.address.streetAddress" (ionChange)="onAddressChange()">
            </ion-input>
        </ion-item>

        <!-- <ion-item>
            <ion-label class="ion-text-wrap" position="stacked">Zipcode</ion-label>
            <ion-input type="text" [(ngModel)]="address.zipcode" data-test="zipcode" 
                    (ionChange)="onZipcodeChange()">
            </ion-input>
        </ion-item> -->

        <ion-item>
            <ion-label class="ion-text-wrap" position="stacked">Zipcode</ion-label>
            <ion-input id="zipcode" color="primary" type="number" name="zipcode"
                [(ngModel)]="address.zipcode" required #zipcode="ngModel"
                data-test="zipcode" (ionChange)="onZipcodeChange()">
            </ion-input>

            <ion-buttons class="itemButtons" slot="end">
                <ion-button class="itemButton" color="primary" expand="full" fill="solid"
                    id="dropoffZipcodeButton" (click)="openZipcodePromptClick()"
                    name="search" data-test="dropoffZipcodePromptButton">
                    <ion-icon name="search"></ion-icon>
                </ion-button>
            </ion-buttons>
            <!-- <div *ngIf="submitted && dropoffZipcode.invalid">
                <div class="validationErrorMsg">Dropoff Zipcode is required.</div>
            </div> -->
        </ion-item>

        <ion-item>
            <ion-label class="ion-text-wrap" position="stacked">City</ion-label>
            <ion-input type="text" [(ngModel)]="address.city" (ionChange)="onAddressChange()"></ion-input>
        </ion-item>
        <!-- <ion-item>
            <ion-label class="ion-text-wrap" position="stacked">State</ion-label>
            <ion-input type="text" [(ngModel)]="address.state"></ion-input>
        </ion-item> -->
    </ion-list>
</ion-content>

<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button color="primary" expand="full" (click)="onCancelClick()">Cancel</ion-button>
            </ion-col>
            <ion-col>
                <ion-button color="primary" expand="full" (click)="onVerifyAddressClick()">
                    Verify Address
                </ion-button>
            </ion-col>
            <ion-col>
                <ion-button color="primary" expand="full" [disabled]="!addressVerified" (click)="onOkayClick()">
                    Okay
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>

import { Component, Injector } from '@angular/core';
import { ViewBasePage } from '../viewBase/viewBase.page';

// import { Constants, TPopoverMenuItem, TSetting, TTimestamp } from '@shared/models/index';

@Component({
    'selector': 'app-addressBase',
    'templateUrl': './addressBase.page.html',
    'styleUrls': ['./addressBase.page.scss'],
})
export class AddressBasePage extends ViewBasePage {

    constructor(injector: Injector) {
        super(injector);
    }

    protected async potentiallyUseFoundInfo(validation: any, addr: any) {
        await this.uxHelperService.showAlert(validation.statusTitle, validation.statusMessage, async (response) => {
            if (validation.streetNamePartialMatch) {
                const title = 'However, a nearby partial match was found. Do you want to use this address?';
                await this.uxHelperService.showConfirmationAlert(title, validation.foundAddress, async (response) => {
                    if (response.selection) {
                        // this.vm.ticket.dropoff.streetAddress = validation.foundAddress;
                        addr.streetAddress = validation.foundAddress;
                    }
                });
            }
            if (validation.zipcodePartialMatch) {
                const title = 'However, the address was found in a different zipcode. Do you want to use this zipcode?';
                await this.uxHelperService.showConfirmationAlert(title, validation.foundZipcode, async (response) => {
                    if (response.selection) {
                        // this.vm.ticket.dropoff.zipcode = validation.foundZipcode;
                        addr.zipcode = validation.foundZipcode;
                    }
                });
            }
        });
    }


}


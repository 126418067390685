import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Network, ConnectionStatus } from '@capacitor/network';

@Injectable({
    'providedIn': 'root'
})
export class ConnectivityService {
    private isConnected = true;

    constructor() {
        this.isConnected = this.isOnline();

        // this.connectionService.monitor().subscribe(isConnected => {
        //     this.isConnected = isConnected;
        // });

        Network.addListener('networkStatusChange', status => {
            this.isConnected = status.connected;
        })
    }

    public monitor(): Observable<boolean> {
        const result = new Subject<boolean>();
        result.next(this.isConnected);
        Network.addListener('networkStatusChange', status => {
            this.isConnected = status.connected;
            result.next(this.isConnected);
        });
        return result.asObservable();
        // return this.connectionService.monitor();
    }

    public isOnline(): boolean {
        this.isConnected = window.navigator.onLine;
        return this.isConnected;
    }

    public isOnlineStr(): string {
        return (this.isConnected) ? 'Connected.' : 'Not Connected';
    }
}
/***
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// import { ConnectionService } from 'ng-connection-service';
import { Network } from '@capacitor/network';
import { resolve } from 'dns';

@Injectable({
    'providedIn': 'root'
})
export class ConnectivityService {
    private isConnected = true;

    constructor() {
        this.isConnected = this.isOnline();

        // Network.addListener('networkStatusChange', status => {
        //     console.log('Network status changed', status);
        //     this.isConnected = status.connected;
        // })
            
        // this.connectionService.monitor().subscribe(isConnected => {
        //     this.isConnected = isConnected;can you hear me? sometimes
        // });
    }

    // public monitor(): Observable<boolean> {
    //     return this.connectionService.monitor();
    // }

    public monitor(): Observable<boolean> {
        const result = new Subject<boolean>();
        result.next(this.isConnected);
        
        // Network.addListener('networkStatusChange', status => {
            
        //     this.isConnected = status.connected;
        //     result.next(this.isConnected);
        // })
        return result.asObservable();
    }


    public isOnline(): boolean {
        this.isConnected = window.navigator.onLine;
        return this.isConnected;
    }

    public isOnlineStr(): string {
        return (this.isConnected) ? 'Connected.' : 'Not Connected';
    }
}
***/

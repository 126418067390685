import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { TRouteLeg, TTicket } from '@shared/models/index';

@Component({
    'selector': 'app-ticketsModal',
    'templateUrl': './ticketsModal.page.html',
    'styleUrls': ['./ticketsModal.page.scss'],
})
export class TicketsModalPage implements OnInit {
    public vm: any = {};
    public assignedTickets = [];
    public enrouteTickets = [];
    public currentTickets = [];
    public completedTickets = [];

    constructor(public modalController: ModalController, public navParams: NavParams) {
    }

    ngOnInit(): void {
        this.vm = this.navParams.get('vm');

        this.assignedTickets = this.vm.assignedTickets || [];
        this.enrouteTickets = this.vm.enrouteTickets || [];
        this.currentTickets = this.vm.currentTickets || [];
        this.completedTickets = this.vm.completedTickets || [];
    }

    public getType(ticket: TTicket) {
        const type = (ticket.fromDealership ? 'Dropoff' : 'Pickup');
        return type;
    }

    public getToAddress(ticket): string {
        const ticketUid = ticket.uid;
        const leg = this.vm.route.routeLegs.find((leg2: TRouteLeg) => {
            return (leg2.ticketUid === ticketUid);
        });
        if (!leg) {
            return 'error: no ticket found.';
        }
        return leg.to.address;
    }

    public onCancelClick() {
        this.modalController.dismiss(null);
    }
}

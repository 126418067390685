import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
// import * as logRocket from 'logrocket';

import { DateService } from '@shared/servicesCommon/date.service';
import { SettingsService } from '@shared/servicesCommon/settings.service';
import { SummaryService } from '@shared/servicesCommon/summary.service';
import { TAccountProfile, TAccountStatus, TAddress, TApp, TCustomer, TDealership, TLocation,
         TSetting, TUser } from '@shared/models/index';

@Injectable({
    'providedIn': 'root'
})
export class SessionService {
    private settingsSubscription: Subscription;
    private setting: TSetting;
    private isDesktopDevice = false;
    private isMobileDevice = false;
    private areSettingsComplete = false;
    private domainInfo: any = null;
    private vm: any;
    private user: TUser = null;
    private dealership: TDealership = null;
    private accountProfile: TAccountProfile = null;
    private environment;
    private appName: TApp;

    constructor(private settingsService: SettingsService, private summaryService: SummaryService,
                private dateService: DateService) {
    }

    // Called by the loader.  
    public reloadSettings() {
        const settingsObservable = this.settingsService.getObservable();
        this.settingsSubscription = settingsObservable.subscribe((settings: TSetting[]) => {
            if (settings && settings.length > 0) {
                this.setting = settings[0];
                // await this.variablesService.initialize(this.setting);
            }
        });
    }

    public setAccountProfile(profile: TAccountProfile) {
        this.accountProfile = profile;

        // Check if this account has expired
        const expirationDate = this.accountProfile.periodInfo.expirationDate;
        const isExpired = this.dateService.isBeforeNow(expirationDate);

        if (isExpired) {
            if (this.accountProfile.packageName === 'Trial Package') {
                this.accountProfile.status = 'Trial Period Expired';
            } else {
                this.accountProfile.status = 'Package Expired';
            }
        }
    }

    public setDealership(dealership: TDealership) {
        this.dealership = dealership;
    }
    public getDealership(): TDealership {
        return this.dealership;
    }

    public setCurrentUser(user: TUser) {
        this.user = user;
    }

    public getIsLoggedIn(): boolean {
        const isLoggedIn: boolean = (this.accountProfile !== null) && (this.user !== null);
        return isLoggedIn; 
    }

    public setSetting(setting: TSetting) {
        this.setting = setting;
    }

    public getAccountNumber(): string {
        const accountNumber = this.accountProfile?.accountNumber;
        return accountNumber;
    }

    public getAccountProfile(): TAccountProfile {
        return this.accountProfile;
    }

    public getCurrentUser(): TUser {
        return this.user;
    }

    public setApp(appName: TApp): void {
        this.appName = appName;
    }
    public getApp(): TApp {
        return this.appName;
    }
    
    public isAccountCancelled(): boolean {
        return (this.accountProfile.status === 'Cancelled By Customer');
    }

    public isAccountActive(): boolean {
        const isActive = ((this.accountProfile.status === 'Active') ||
                          (this.accountProfile.status === 'In Trial Period'));
        return isActive;
    }

    public getExpirationDate(): string {
        return this.accountProfile.periodInfo.expirationDate.toString();
    }

    public get getUsersFullName(): string {
        let fullName: string = '';
        if (this.user) {
            fullName = this.user.firstName + ' ' + this.user.lastName;
        }
        return fullName;
    }

    public getUsersUid(): string {
        return this.user.uid;
    }

    public getDealershipLocation(): TLocation {
        const address: TAddress = {
            'streetAddress':      this.dealership.address.streetAddress,
            'city':               this.dealership.address.city,
            'state':              this.dealership.address.state,
            'zipcode':            this.dealership.address.zipcode,
            'fullAddress':        this.dealership.address.fullAddress,
            'countryName':        this.dealership.address.countryName,
            'countryCode':        this.dealership.address.countryCode,
            'isoCountryCode':     this.dealership.address.countryCode,
            'isoSubdivisionCode': this.dealership.address.statecode,        
            'coordinate':         this.dealership.address.coordinate
        };

        const dealershipLocation: TLocation = {
            'locationName':  this.dealership.dealershipName,
            'address':       address
        };

        return dealershipLocation;
    }

    public getDealershipName(): string {
        return this.dealership.dealershipName;
    }

    // public setAreSettingsComplete(isComplete): void {
    //     this.areSettingsComplete = isComplete;
    // }

    // public getAreSettingsComplete(): boolean {
    //     return this.areSettingsComplete;
    // }

    public getSessionData(): any {
        const session: any = {};
        
        session.email = this.user.email;
        session.role = this.user.role;
        session.firstName = this.user.firstName;
        session.lastName = this.user.lastName;
        session.userUid = this.user.uid;
        
        // session.email = this.loggedIn.user.email;
        // session.role = this.loggedIn.user.role;
        // session.firstName = this.loggedIn.user.firstName;
        // session.lastName = this.loggedIn.user.lastName;
        // session.userUid = this.loggedIn.user.uid;
        return session;
    }

    public getSetting(): TSetting {
        return this.setting;
    }

    public setEnvironment(environment): void {
        this.environment = environment;
        // this.loggedIn.environment = environment;
    }

    public getEnvironment() {
        return this.environment;
        // return this.loggedIn.environment;
    }

    // A customer is defined as a person who has a made a payment for a package.
    public getIsCustomer(): boolean {
        const packageName = this.accountProfile.packageName;
        const isCustomer = (packageName !== 'Trial Package');
        return isCustomer;
    }

    // This is called from many places including app.component. 
    // Do not remove the ?
    public isDebugMode(): boolean {
        return (this.accountProfile?.debug || false);
        // return (this.loggedIn.accountProfile.debug || false);
    }

    public isShowSetUserButton(): boolean {
        return (this.accountProfile?.showSetUserButton || false);
    }

    public setIsDesktopDevice(isDesktop: boolean) {
        this.isDesktopDevice = isDesktop;
    }
    public getIsDesktopDevice(): boolean {
        return this.isDesktopDevice;
    }

    public setIsMobileDevice(isMobile: boolean): void {
        this.isMobileDevice = isMobile;
    }
    public getIsMobileDevice(): boolean {
        return this.isMobileDevice;
    }

    public setDomainInfo(domainInfo: any): void {
        this.domainInfo = domainInfo;
    }
    public getDomain(): string {
        return this.domainInfo.domain;
    }
    public getDomainWithPort(): string {
        return this.domainInfo.domainWithPort;
    }

    public getAccountStatus(): TAccountStatus {
        return this.accountProfile.status;
    }

    public getFakeCustomer(): TCustomer {
        // let fakeCustomer: TCustomer = null;
        // if (this.user) {
        //     fakeCustomer = {
        //         customerId: "0",
        //         customerName: this.user.firstName + ' ' + this.user.lastName,
        //         cellPhone: this.user.cellPhone,
        //         uid: 'fakeCustomer'
        //     };
        // } else {
        const fakeCustomer: TCustomer = {
            customerId: "1000000",
            customerName: 'John Doe',
            // cellPhone: '5555551212',
            cellPhone: this.user?.cellPhone,    // We need a valid phone number for 'CustomerMessagesBase.sendMessageToMe()
            uid: 'fakeCustomer'
        };
        const ts = this.dateService.getMinimumDate();
        fakeCustomer.shuttleDates = [];
        fakeCustomer.shuttleDates[0] = ts;

        return fakeCustomer;
    }

    // Angular doesn't have a way to pass complex objects on the router.
    // So I will use this SessionService as a way to hold the objects that I need to pass around.
    public setViewModel(vm: any): void {
        this.vm = vm;
    }
    public getViewModel(): any {
        return this.vm;
    }

    public setAccountNumber(accountNumber: string) {
        this.accountProfile.accountNumber = accountNumber;
    }

}

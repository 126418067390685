import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

// https://stackoverflow.com/questions/58265379/ionic-events-replace-with-angular-observables
// Publish like this:
// this.eventService.publishFormRefresh();
//
// Subscribe like this:
// this.subscription = this.eventService.formRefreshSource$.subscribe(data => {
//   //do something here
// });
@Injectable({
    'providedIn': 'root'
})
export class EventsService {
    private channels: { [key: string]: Subject<any>; } = {};

    /**
     * Subscribe to a topic and provide a single handler/observer.
     * @param topic      The name of the topic to subscribe to
     * @param observer   The observer or callback function to listen when changes are published
     * @returns Subscription  from which you can unsubscribe to release memory resources
     */
    public subscribe(topic: string, observer: (_: any) => void): Subscription {
        if (!this.channels[topic]) {
            this.channels[topic] = new Subject<any>();
        }
        // const observers = this.channels[topic].observers;
        // const numObservers = observers.length;
        // console .log('numObservers = ', numObservers);

        return this.channels[topic].subscribe(observer);
    }

    /**
     * Publish some data to the subscribers of the given topic
     * @param topic      The name of the topic to emit data to
     * @param data       in any format to pass on
     */
    public publish(topic: string, data?:any): void {
        const subject = this.channels[topic];
        if (!subject) {
            // Or create a new subject for future subscribers
            // this.channels[topic] = new Subject<any>();
            return;
        }
        subject.next(data);
    }

    /**
     * When you are sure you are done with the topic and the subscribers no longer need to
     * listen to this topic, you can destroy the observable associated with this topic.
     * 
     */
    public destroy(topic: string) {
        const subject = this.channels[topic];
        if (!subject) {
            return;
        }
        subject.complete();
        subject.unsubscribe();
        delete this.channels[topic];
    }

    public unsubscribeAll() {
        // this.channels.forEach(topic => {
        //     this.unsubscribe(topic);
        //     this.destroy(topic);
        // });
    }

    public unsubscribe(topic: string) {
        const subject = this.channels[topic];
        if (!subject) {
            return;
        }

        if (this.channels[topic].observers) {
            this.destroy(topic);
        }
    }
}

<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onCancelClick()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
    <ion-list>
        <ion-radio-group [(ngModel)]="selectedItem" (ionChange)="radioGroupChange($event)">
            <ion-item *ngFor="let item of items" [attr.data-test]="getDataTestId(item)">
                <ion-label class="ion-text-wrap">
                    <span class="line1">{{item}}</span><br />
                </ion-label>
                <ion-radio (click)="onItemClick(item)" [value]="item"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button color="primary" expand="block" (click)="onCancelClick()" data-test="cancel">
                        Cancel
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button color="primary" expand="block" (click)="onOkayClick()" data-test="okay">
                        Okay
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>

import { Pipe, PipeTransform } from '@angular/core';
import { TTimestamp } from '@shared/models/index';
import { DateService } from '@shared/servicesCommon/date.service';

@Pipe({
    name: 'myTimestamp',
    // pure: true
})
export class MyTimestampPipe implements PipeTransform {
    private dateService: DateService = new DateService();

    transform(value: unknown, ...args: unknown[]): string {     // unknown {
        const timestamp = value as TTimestamp;
        const format = args[0] as string;

        if (!timestamp || !format) {
            return '';
        }
        const str = this.dateService.formatTimestamp(timestamp, format);
        return str;
    }

}
